// External libraries and frameworks
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { get, post } from 'aws-amplify/api';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
// CSS and styles
import 'bootstrap/dist/css/bootstrap.css';
import "@aws-amplify/ui-react/styles.css";
import "../App.css";

// Local components and pages
import NavigationBar from "../components/NavigationBar";
import StartupFormPage from "./StartupFormPage";
import ListComponent from "../components/ListComponent";
import InvestorFormPage from "./InvestorFormPage";
import ApplicationStatus from "./ApplicationStatus";
import AdminDashboard from "./AdminDashboard";
import DailyReport from "../components/DailyReport";
import MatchPage from "./MatchPage";
import StartupDashboard from "./StartupDashboard";
import InvestorDashboard from "./InvestorDashboard";
import FundiiCoursesPage from "./FundiiCoursesPage";
import FundiiInfoPage from "./FundiiInfoPage"
import MembershipPage from './MembershipPage';
import RaiseCaptialPage from './RaiseCaptialPage';
import DueDiligencePage from './DueDiligencePage';
import HowInvestorWorksPage from './HowInvestorWorksPage';
import OurStoryPage from './OurStoryPage';
import LocationPage from './LocationPage';
import FundiiStartupAwards from './FundiiStartupAwards';
import FundiiTechDay from './FundiiTechDay';
import PremiumPage from './PremiumPage'
import Footer from '../components/Footer';
import FundiiTalk from './FundiiTalk'
import NewsPage from './NewsPage'
import VerticalEventPage from './VerticalEventPage'
import InvestmentOpportunities from './InvestmentOpportunities'
import FAQPage from './FAQPage'
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfUsePage from './TermsOfUsePage';
import UserDetailPage from './UserDetailPage';
// AWS Configuration
import awsconfig from '../aws-exports';

const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
Amplify.configure(awsconfig, {
  API: {
    REST: {
      headers: async () => {
        return { Authorization: authToken };
      }
    }
  }
});
awsconfig["API"] = {
  endpoints: [
    {
      name: "fundiiAPI",
      endpoint: "https://n8tckhqcd7.execute-api.us-east-2.amazonaws.com/staging"
    }
  ]
}

Amplify.configure(awsconfig);

const Return = () => {
  const [status, setStatus] = useState(null);
  const [user, setUser] = useState(null);
  const [session, setSession] = useState({});
  const [subscription, setSubscription] = useState({});

  useEffect(() => {

    async function fetchData() {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const userAttributes = await fetchUserAttributes();
        setUser(userAttributes)
        const restOperation = get({
          apiName: 'fundiiAPI',
          path: `/services/session-status?session_id=${sessionId}`
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.message) {
          setStatus(response.message.status);
          setSession(response.message);
        }

        if (response.message.status === 'complete') {
          const restOperation2 = get({
            apiName: 'fundiiAPI',
            path: `/services/subscription-status?subscription_id=${response.message.subscription}`
          });
          const { body } = await restOperation2.response;
          const response2 = await body.json();
          if (response2.message) {
            setSubscription(response2.message)
          }
        }

      } catch (err) {
        console.log(`Error fetching texts: `, err);
      }
    }

    fetchData();
  }, []);

  const handleUpdateUserAttribute = async () => {
    try {
      await updateUserAttributes({
        userAttributes: {
          'custom:premium': new Date(subscription?.current_period_end * 1000).toISOString(),
          'custom:session': session.id,
          'custom:subscription': subscription.id
        }
      });
      window.location.href = '/';
    } catch (error) {
      console.log(error);
    }
  }
  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }
  if (status === 'complete') {
    if (subscription?.current_period_end && session.customer_details.email === user.email && session.id !== user['custom:session']) {
      handleUpdateUserAttribute()
    }
  }

  return <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    Waiting for payment...
  </div>;
}


function App() {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [type, setType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    firstName: '',
    familyName: '',
    country: '',
    email: '',
    isInvestor: false,
    isStartup: false
  });

  const AuthRoute = ({ children }) => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    if (authStatus !== 'authenticated') {
      return (
        <div style={{ marginTop: '7%' }}>
          <Authenticator initialState="signUp" formFields={formFields}>
            {children}
          </Authenticator>
        </div>
      );
    }

    return children;
  };

  const Sign = () => {
    return (
      <Navigate to="/" />
    )
  }

  const handleSubscribeClick = () => {
    setShowSubscribeModal(true);
  };

  const handleSubscriptionChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSubscriptionData({
      ...subscriptionData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubscribeSubmit = async () => {
    setShowSubscribeModal(false);
    try {
      const myInit = {
        body: subscriptionData,
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${session.idToken.jwtToken}` // Uncomment if needed
        }
      };

      const restOperation = post({
        apiName: "fundiiAPI",
        path: "/services/subscribe",
        options: myInit
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      // Optionally, refetch the course list or update state
    } catch (error) {
      console.error('Error adding sub:', error);
    }
  };

  const handleUpdateUserAttribute = async () => {
    try {
      await updateUserAttributes({
        userAttributes: {
          'custom:pushed': "true",
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function checkAdminStatus() {
      try {
        const auth_user = await fetchAuthSession();
        const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
        const attributes = await fetchUserAttributes();
        setType(attributes['custom:type']);
        setIsAdmin(adminStatus);
        if (attributes) {
          if (!attributes["custom:pushed"]) {
            handleUpdateUserAttribute()
            const UserData = {
              id: attributes.sub,
              username: `${attributes.given_name} ${attributes.family_name}`,
              first_name: attributes.given_name,
              last_name: attributes.family_name,
              email: attributes.email,
              address: attributes.address,
              birthday: attributes.birthdate,
              phone_number: attributes.phone_number,
              create_date: Date.now()
            };
            try {
              const myInit = {
                body: UserData,
                headers: {
                  'Content-Type': 'application/json',
                  // Authorization: `Bearer ${session.idToken.jwtToken}` // Uncomment if needed
                }
              };
        
              const restOperation = post({
                apiName: "fundiiAPI",
                path: "/services/users",
                options: myInit
              });
        
              const { body } = await restOperation.response;
              const response = await body.json();
            } catch (error) {
              console.error('Failed', error);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching auth session:', error);
      }
    }

    checkAdminStatus();
  }, [authStatus]);

  const formFields = {
    signUp: {
      email: {
        order: 1,
        label: 'Email',
        placeholder: 'Enter your email'
      },
      password: {
        order: 2,
        label: 'Password',
        placeholder: 'Enter your password'
      },
      confirm_password: {
        order: 3,
        label: 'Confirm Password',
        placeholder: 'Confirm your password'
      },
      given_name: {
        order: 4,
        label: 'First Name',
        placeholder: 'Enter your first name'
      },
      family_name: {
        order: 5,
        label: 'Last Name',
        placeholder: 'Enter your last name'
      },
      birthdate: {
        order: 6,
        label: 'Birthdate',
        placeholder: 'Enter your birthdate',
        inputProps: {
          type: 'date' // Use date type for birthdate
        }
      },
      phone_number: {
        order: 7,
        label: 'Phone Number',
        placeholder: 'Enter your phone number'
      },
      address: {
        order: 8,
        label: 'Address',
        placeholder: 'Enter your address'
      },
      'custom:linkedin_page': {
        order: 9,
        label: 'LinkedIn Page',
        placeholder: 'Enter your LinkedIn Page URL',
        inputProps: {
          type: 'url'
        }
      },
      'custom:country_residence': {
        order: 10,
        label: 'Country of Residence',
        placeholder: 'Enter your country of residence'
      }
    }
  }

  const handleShowModal = (action) => {
    setConfirmAction(action);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmAction = async () => {
    if (confirmAction === 'startup') {
      window.location.href = '/form/startup';
    } else if (confirmAction === 'investor') {
      window.location.href = '/form/investor';
    }
    setShowModal(false);
  };

  return (
    <Router>
      <NavigationBar status={authStatus} />
      <Routes>
        <Route path="/admin/*" element={<AuthRoute><AdminDashboard /></AuthRoute>}>
          <Route path="reports" element={<AuthRoute><DailyReport /></AuthRoute>} />
          <Route exact path='startup' element={<AuthRoute><ListComponent type="startup" /></AuthRoute>} />
          <Route exact path='investor' element={<AuthRoute><ListComponent type="investor" /></AuthRoute>} />
          <Route exact path='investment' element={<AuthRoute><ListComponent type="investment" /></AuthRoute>} />
          <Route exact path='courses' element={<AuthRoute><ListComponent type="courses" /></AuthRoute>} />
          <Route exact path='subscription' element={<AuthRoute><ListComponent type="subscription" /></AuthRoute>} />
          <Route exact path='users' element={<AuthRoute><ListComponent type="users" /></AuthRoute>} />
        </Route>
        <Route exact path='/form/startup' element={<AuthRoute><StartupFormPage /></AuthRoute>} />
        <Route exact path='/startup' element={<AuthRoute><StartupDashboard /></AuthRoute>} />
        <Route exact path='/form/investor' element={<AuthRoute><InvestorFormPage /></AuthRoute>} />
        <Route path='/status/:status' element={<AuthRoute><ApplicationStatus /></AuthRoute>} />
        <Route exact path='/admin/review/startup/:userId' element={<AuthRoute><StartupFormPage /></AuthRoute>} />
        <Route exact path='/admin/review/investor/:userId' element={<AuthRoute><InvestorFormPage /></AuthRoute>} />
        <Route exact path='/admin/review/user/:userId' element={<AuthRoute><UserDetailPage /></AuthRoute>} />
        <Route path="/admin/reports/daily" element={<AuthRoute><DailyReport /></AuthRoute>} />
        <Route path="/startup/:startupId" element={<AuthRoute><StartupDashboard /></AuthRoute>} />
        <Route path="/investor/:investorId" element={<AuthRoute><InvestorDashboard /></AuthRoute>} />
        <Route path="/match" element={<AuthRoute><MatchPage /></AuthRoute>} />
        <Route path="/match-r" element={<AuthRoute><InvestorFormPage match={true} /></AuthRoute>} />
        <Route path="/checkout" element={<AuthRoute><MembershipPage /></AuthRoute>} />
        <Route path="/premium" element={<AuthRoute><PremiumPage /></AuthRoute>} />
        <Route path="/return" element={<AuthRoute><Return /></AuthRoute>} />
        <Route path="/signup" element={<AuthRoute><Sign /></AuthRoute>} />
      </Routes>
      <Routes>
        <Route path="/" element={<FundiiInfoPage type={type} handleShowModal={handleShowModal} />} />
        <Route path="/raise-capital" element={<RaiseCaptialPage type={type} handleShowModal={handleShowModal} />} />
        <Route path="/due-diligence" element={<DueDiligencePage type={type} handleShowModal={handleShowModal} />} />
        <Route path="/how-it-works" element={<HowInvestorWorksPage type={type} handleShowModal={handleShowModal} />} />
        <Route path="/story" element={<OurStoryPage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/startup-awards" element={<FundiiStartupAwards />} />
        <Route path="/fundii-tech-day" element={<FundiiTechDay />} />
        <Route path="/fundii-talk" element={<FundiiTalk />} />
        <Route path="/vertical" element={<VerticalEventPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/investment-opportunities" element={<InvestmentOpportunities type={type} handleShowModal={handleShowModal} />} />
        <Route path="/inv-FAQ" element={<FAQPage isInvestor={true} />} />
        <Route path="/sta-FAQ" element={<FAQPage isInvestor={false} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/fundiiplus" element={<FundiiCoursesPage />} />
      </Routes>
      {!isAdmin && (
        <>
          <Row>
            <Col>
              <Form className="text-center mt-5 mb-5" onSubmit={(e) => e.preventDefault()}>
                <Button className="orange-button big-button" variant="primary mt-2" onClick={handleSubscribeClick}>Subscribe</Button>
              </Form>
            </Col>
          </Row>
          {/* Subscription Modal */}
          <Modal show={showSubscribeModal} onHide={() => setShowSubscribeModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Subscribe for Updates </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    name="firstName"
                    value={subscriptionData.firstName}
                    onChange={handleSubscriptionChange}
                  />
                </Form.Group>
                <Form.Group controlId="familyName" className="mt-3">
                  <Form.Label>Family Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter family name"
                    name="familyName"
                    value={subscriptionData.familyName}
                    onChange={handleSubscriptionChange}
                  />
                </Form.Group>
                <Form.Group controlId="country" className="mt-3">
                  <Form.Label>Country of Residence</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter country"
                    name="country"
                    value={subscriptionData.country}
                    onChange={handleSubscriptionChange}
                  />
                </Form.Group>
                <Form.Group controlId="email" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    name="email"
                    value={subscriptionData.email}
                    onChange={handleSubscriptionChange}
                  />
                </Form.Group>
                <Form.Group controlId="isInvestor" className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="Are you an investor?"
                    name="isInvestor"
                    checked={subscriptionData.isInvestor}
                    onChange={handleSubscriptionChange}
                  />
                </Form.Group>
                <Form.Group controlId="isStartup" className="mt-3">
                  <Form.Check
                    type="checkbox"
                    label="Are you a tech startup?"
                    name="isStartup"
                    checked={subscriptionData.isStartup}
                    onChange={handleSubscriptionChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowSubscribeModal(false)}>
                Close
              </Button>
              <Button className="orange-button" onClick={handleSubscribeSubmit}>
                Subscribe
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {confirmAction === 'startup' && "Do you want to become a Startup? Note: This means you cannot become an Investor."}
              {confirmAction === 'investor' && "Do you want to become an Investor? Note: This means you cannot become a Startup."}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleConfirmAction}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}
    </Router>
  );
};

export default App;