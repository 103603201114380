import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import topImage from '../images/news.jpg'; // Replace with the path to your news image
import './FundiiInfoPage.css'; // Assuming this CSS file contains necessary styles

const newsEntries = [
  {
    date: "June",
    year: "2024",
    content: `fundii won "Ethical Finance Awards 2024" by Wealth & Finance International , UK as "Best Financial & Educational Services Provider 2024 – Southern Ontario" . Additionally, Dr. Maryam Fouladirad, Founder & CEO of fundii won "Most Pioneering MENA Tech Start-Up Ecosystem Strategist 2024” awards by Wealth & Finance International, UK.`,
    link: "https://www.wealthandfinance-news.com/winners/fundii/",
  },
  {
    date: "May",
    year: "2024",
    content: `fundii considered "Leap by DIFC" as a new delivery channel for fundii+ in MENA region, in particular UAE .`,
    link: "", // No specific link provided
  },
  {
    date: "April",
    year: "2024",
    content: `fundii has been selected as nominee in two categories for “Finance Middle East Awards 2024” which will take place on May 15th at Jumeirah Mina A'Salam , Dubai, UAE:

    1.  Fintech Company of The Year
    2. Venture Capital Firm of The Year`,
    link: "https://www.financemiddleeast.com/finance-middle-east-awards-2024-shortlist-revealed/",
  },
  {
    date: "December",
    year: "2023",
    content: `fundii recognized among “Canada's Most Influential Angel Investment Startups in 2023” by Canada Venture News`,
    link: "https://canadaventure.news/who-are-canadas-most-influential-angel-investment-startups-in-2023/",
  },
  {
    date: "August",
    year: "2023",
    content: `fundii featured among “15 Toronto VC Startups Igniting Entrepreneurial Fire” by Canada Venture News`,
    link: "https://canadaventure.news/capital-catalysts-15-toronto-vc-startups-igniting-entrepreneurial-fire/",
  },
  {
    date: "August",
    year: "2023",
    content: `fundii recognized among Top 15 Venture Capital Startups in Ontario, Canada by Canada Venture News`,
    link: "https://canadaventure.news/unlocking-ontarios-potential-15-venture-capital-startups-you-cant-ignore/",
  },
  {
    date: "July",
    year: "2023",
    content: `fundii Annual Agri & Food tech 2023, Dubai, UAE "fundii Annual Agri & Food tech” vertical event took place in July 2023 at DIFC, Dubai with 2 technical panels and 8 panelists related to the field as well as a demo of 5 agritech and foodtech startups.`,
    link: "", // No specific link provided
  },
  {
    date: "May",
    year: "2023",
    content: `Dr. Maryam Fouladirad nominated for “Women SME Leaders Awards- Investor category- by Mastercard & Entrepreneur Middle East, Dubai, UAE`,
    link: "https://womensmeleaders.com/2023/",
  },
  {
    date: "March",
    year: "2023",
    content: `fundii Tech Day Dubai 2023, The first “fundii Tech Day Dubai 2023” held at DIFC in Dubai by hosting 34 tech startups in various verticals with 6 judges from MENA and beyond and a technical panel with 5 guest speakers from various tech and entrepreneurship backgrounds. The startups presented their companies and 2 winners announced which will compete against the winners of other cities in MENA in due course for fundii Startup Awards.`,
    link: "", // No specific link provided
  },
  {
    date: "January",
    year: "2023",
    content: `fundii selected as “Technology Company of the Year nominee” by Arabian Business - Achievement Awards 2023, Dubai, UAE`,
    link: "https://www.arabianbusiness.com/industries/media/arabian-business-achievement-awards-2023-nomination-list-revealed",
  },
  // Continue for the year 2022
  {
    date: "November",
    year: "2022",
    content: `fundii officially incorporated in UAE and established its regional office at Dubai International Financial Center (DIFC), "Delighted to announce the opening of fundii (software house and technology R&D) new regional office at DIFC Innovation Hub to have a stronger presence in MENA and a bridge for Africa and South Asia." , said founder & CEO of fundii, Dr. Maryam Fouladirad.`,
    link: "", // No specific link provided
  },
  {
    date: "August",
    year: "2022",
    content: `fundii Newsletter Launched, fundii's monthly newsletter launched for better communication with existing and potential customers, entrepreneurs, investors, and media.`,
    link: "", // No specific link provided
  },
  {
    date: "July",
    year: "2022",
    content: `“fundii Talk” Launched, "fundii Talk”, an initiative by fundii launched as a bi-monthly live interview (podcast) with selected entrepreneurs and business people.`,
    link: "", // No specific link provided
  },
  {
    date: "April",
    year: "2022",
    content: `Dr. Maryam Fouladirad, founder & CEO of fundii won The “Investor Award” at Women SME Leaders Awards 2022 by Mastercard & Entrepreneur Middle East: Winners of Inaugural Mastercard Women SME Leaders Awards 2022 announced.`,
    link: "https://www.mastercard.com/news/eemea/en/newsroom/press-releases/press-releases/en/2022/april/winners-of-inaugural-mastercard-women-sme-leaders-awards-2022-announced/",
  },
  {
    date: "March",
    year: "2022",
    content: `fundii selected Carta as one of its tech partners/service providers.`,
    link: "", // No specific link provided
  },
  {
    date: "February",
    year: "2022",
    content: `fundii & Uplinq Partnership: "Uplinq, fundii partner to help SMB lenders" and other related news.`,
    link: "http://www.greensheet.com/newswire.php?newswire_id=55117",
  },
  {
    date: "January",
    year: "2022",
    content: `fundii+ Planned and Introduced: On International Education Day, fundii announces the availability of business workshops and masterclasses under "fundii+" brand in MENA, Africa, and South Asia regions. Additionally, fundii was selected and featured among 50 Leading Canada Fintech Startups Founded in 2020 & 2021 by Daily Finance.`,
    link: "https://df.media/50-leading-canada-fintech-startups-founded-in-2020-2021/",
  },
  {
    date: "August",
    year: "2021",
    content: `fundii, a Founder Institute (FI Toronto) featured in 31 Best Canada Based Fintech Firms Founded in 2020 & 2021 by Daily Finance`,
    link: "https://www.facebook.com/FounderInstitute/posts/10158562439963865",
  },
  {
    date: "August",
    year: "2021",
    content: `fundii and 6'Th Generation for Solutions & Innovation signed a MOU for collaboration empowering startups in the MENA region.`,
    link: "", // No specific link provided
  },
  {
    date: "July",
    year: "2021",
    content: `fundii, a Founder Institute (FI Toronto) featured in 22 Top Angel Investment Startups & Companies in Toronto`,
    link: "https://twitter.com/founding/status/1413867028964851713",
  },
  {
    date: "March",
    year: "2021",
    content: `fundii Signed a MOU with Aird Berlis for Representation in Canada.\nfundii and Aird & Berlis LLP signed a MOU for collaboration. Congratulations to both companies.`,
    link: "",
  },
  {
    date: "January",
    year: "2021",
    content: "fundii selected and featured among 22 Top Angel Investment & Startups in Toronto (2021) by Best Startup Canada",
    link: "https://beststartup.ca/22-top-angel-investment-startups-and-companies-in-toronto-2021/"
  },
  {
    date: "January",
    year: "2021",
    content: "fundii selected and featured among 63 Top Picks For The Best Canada based Banking Companies by Daily Finance",
    link: "https://df.media/63-best-canada-based-angel-investment-startups-and-companies-2021/"
  },
  {
    date: "January",
    year: "2021",
    content: "fundii selected and featured among 37 Top Picks For The Best Ontario based Banking Companies by Daily Finance",
    link: "https://df.media/37-best-ontario-based-angel-investment-startups-and-companies-2021/"
  },
  {
    date: "January",
    year: "2021",
    content: "fundii selected and featured among 30 Top Picks For The Best Toronto Banking Companies by Daily Finance",
    link: "https://df.media/30-best-toronto-based-angel-investment-startups-and-companies-2021/"
  }

];

const NewsItem = ({ year, date, content, link }) => (
  <Row className="mb-4">
    <Col>
      <h4><span className="orange-text">{date} {year}</span></h4>
      <p>{content} {link && <a href={link} target="_blank" rel="noopener noreferrer">Read more</a>}</p>
    </Col>
  </Row>
)

const NewsPage = () => {
  return (
    <>
      <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
        <Carousel.Item>
          <img className="d-block w-100" src={topImage} alt="Top News" />
        </Carousel.Item>
      </Carousel>
      <Container style={{ marginTop: '10%', marginBottom: '10%' }}>
        <h1 className="mb-5 text-center">Latest <span className="orange-text">News</span></h1>
        {newsEntries.map((news, index) => (
          <NewsItem key={index} {...news} />
        ))}
      </Container>
    </>
  );
};

export default NewsPage;
