import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Pagination } from 'react-bootstrap';
import { get } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import StartupCard from '../components/StartupCard'
import 'bootstrap/dist/css/bootstrap.min.css';
import { convertKeysToCamelCase } from '../untils/stringUtils';

function MatchPage() {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState({
        industry: '',
        startupStage: '',
        cityCountryHeadquarters: '',
        revenue: ''
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/startups"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                const camelCaseData = convertKeysToCamelCase(response.message);
                // console.log(camelCaseData);
                if (camelCaseData && camelCaseData.length > 0) {
                    setItems(camelCaseData);
                    setFilteredItems(camelCaseData);
                } else {
                    setItems([]);
                    setFilteredItems([]);
                }
            } catch (err) {
                console.log(`Error fetching startups: `, err);
            }
        }

        fetchData();
    }, []);

    // Handle criteria change
    const handleCriteriaChange = (e) => {
        const { name, value } = e.target;
        setSearchCriteria(prevCriteria => ({
            ...prevCriteria,
            [name]: value
        }));
        // setCurrentPage(1); // Reset to first page when criteria changes
    };

    const handleSearch = () => {
        const filtered = items.filter(item =>
            Object.entries(searchCriteria).every(([key, value]) =>
                item[key]?.toLowerCase().includes(value.toLowerCase())
            )
        );
        setFilteredItems(filtered);
        setCurrentPage(1); // Reset to first page on search
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Calculate the number of pages
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // console.log(filteredItems)
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="container" style={{ marginTop: '10%' }}>
            <header>
                <Form>
                    <Row className="mb-3">
                        <Col>
                            <Form.Control
                                type="text"
                                name="industry"
                                placeholder="Filter by Industry"
                                onChange={handleCriteriaChange}
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                name="startupStage"
                                placeholder="Filter by Startup Stage"
                                onChange={handleCriteriaChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Control
                                type="text"
                                name="cityCountryHeadquarters"
                                placeholder="Filter by City/Country of Headquarters"
                                onChange={handleCriteriaChange}
                            />
                        </Col>
                        <Col>
                            <Form.Control
                                type="text"
                                name="revenue"
                                placeholder="Filter by Revenue"
                                onChange={handleCriteriaChange}
                            />
                        </Col>
                    </Row>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                        className="btn orange-button"
                        onClick={handleSearch}
                        style={{ width: '70vw', color: 'white'}}
                    >
                        Search
                    </button>
                </div>
            </header>
            {/* Card display for current items */}
            <div className="container mt-5">
                {currentItems.length > 0 ? (
                    <>
                        <div className="row">
                            {currentItems.map((item, index) => (
                                <div className="col-md-4 mb-4" key={index}>
                                    <StartupCard startup={item} />
                                </div>
                            ))}
                        </div>

                        {/* Pagination controls */}
                        <Pagination className="justify-content-center">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {[...Array(totalPages).keys()].map(number => (
                                <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handlePageChange(number + 1)}>
                                    {number + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    </>) : (
                    <div style={{ textAlign: 'center' }}>No items to display</div>
                )}
            </div>
        </div>
    );
}

export default MatchPage;
