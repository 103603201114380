import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import topImage from '../images/faq.png';

const FAQPage = ({ isInvestor }) => {
    const [activeKeys, setActiveKeys] = useState([]);

    const toggleAccordion = (key) => {
        if (activeKeys.includes(key)) {
            setActiveKeys(activeKeys.filter(item => item !== key));
        } else {
            setActiveKeys([...activeKeys, key]);
        }
    };

    const investorQuestions = [
        {
            key: "0",
            question: "What kind of startups can submit their application for fundraising (equity) or M&A?",
            answer: "fundii works only with tech startups in MENA, Africa and South Asia - except all the countries under sanction in the regions - for fundraising (equity) or M&A."
        },
        {
            key: "1",
            question: "What regions and countries fundii covers for fundraising (equity) or M&A?",
            answer: "fundii covers all the countries (tech startups with HQ) in MENA (Middle East & North Africa), Africa and South Asia regions - except countries under sanction. The following are the list of countries: [...]"
        },
        {
            key: "2",
            question: "How long would be the fundraising or M&A campaigns?",
            answer: "It usually takes 60 to 90 days (depends on the unique situation and requirements of each startup). M&A campaigns may take a bit longer."
        },
        {
            key: "3",
            question: "Is there any fee applicable for investors?",
            answer: "Viewing public and basic information of the startups is free for investors. However, to view and access complete information of the startups requires a VIP membership which is $399 USD/year."
        },
        {
            key: "4",
            question: "What's the minimum fundraising campaign or M&A amount?",
            answer: "$250,000 USD"
        },
        {
            key: "5",
            question: "What's the maximum fundraising or M&A amount?",
            answer: "There is no limit."
        },
        {
            key: "6",
            question: "What's the minimum ticket size for investors when interested to invest in the startups through equity?",
            answer: "$10,000+ USD"
        },
        {
            key: "7",
            question: "What payment methods investors can use for equity investment or M&A?",
            answer: "Currently, we accept wire transfer and cryptocurrency (stablecoin) through our licensed financial partners. Please contact us if you have any questions info@fund-ii.com."
        },
        {
            key: "8",
            question: "What's the due diligence procedure for startups and what it covers?",
            answer: "fundii collaborates with third-party compliance firms as well as checks internally to assess the legal documents, background check of the startups and their founders along with financial data. We highly recommend investors consider their own due diligence for startups as an additional layer of background check and suitability of the investment for their portfolio."
        },
        {
            key: "9",
            question: "What type of investors does fundii work with?",
            answer: "fundii mainly considers accredited investors to invest over the platform, such as venture capitalists and angel investors from around the world - except countries under sanction."
        },
        {
            key: "10",
            question: "Does fundii perform due diligence (KYC & AML) on investors as well?",
            answer: "Yes. This is to ensure that the investor is legitimate to invest, prevent fraud and money laundering. fundii uses third-party compliance firms to perform this procedure."
        },
        {
            key: "11",
            question: "Is there any risk for investing in tech startups through the fundii platform?",
            answer: `
                <p>Investments of any kind are subject to risk. Investments in any exempt market/private security, including investments through fundii, are considered high-risk for some of the following reasons:</p>
                <h6>Risk of loss</h6>
                <p>There is a possibility that you may lose your entire investment if the company were to fail before providing liquidity or any return to shareholders.</p>
                <h6>Industry Risk</h6>
                <p>Risks faced by the company because of the industry in which it operates.</p>
                <h6>Investment Risk</h6>
                <p>Risks that are specific to the type of securities being offered.</p>
                <h6>Liquidity Risk</h6>
                <p>There is currently no marketplace or exchange to sell shares in private companies. Generally, you cannot sell investments in a private company until a liquidity event occurs. A liquidity event - meaning that your investment is ‘liquid’ and can be sold - is typically a listing on a public exchange or, in some instances, takes the form of an acquisition by another company. A liquidity event may never occur, and there is the possibility that you will never be able to sell your shares.</p>
                <h6>Lack of Information</h6>
                <p>Companies are required to provide shareholders with annual financial statements. However, shareholders of the companies can waive this requirement, and it does not apply to investors who are not shareholders (e.g., noteholders or SAFE holders). Throughout our engagement with a company, we encourage the company to provide shareholders with all material business updates and that they understand the obligation and benefit of doing so.</p>
                <h6>Issuer Risk</h6>
                <p>Risks that are specific to the company.</p>
            `
        },
        {
            key: "12",
            question: "Is there any guarantee for return on my investment?",
            answer: "There is no guarantee for a return on investment when investing in tech startups on the fundii platform. We highly recommend investors consider their own due diligence in addition to what fundii provides."
        }
    ];

    const startupQuestions = [
        {
            key: "0",
            question: "What are the requirements for fundraising (equity) or M&A (merger & acquisition) on fundii?",
            answer: `
            <ul>
                <li>fundii accepts only tech startups with headquarters in either MENA (Middle East & North Africa), Africa or South Asia, except countries under sanction.</li>
                <li>fundii accepts tech startups from idea stage up to IPO.</li>
                <li>Minimum fundraising target is $250,000 USD.</li>
                <li>fundii charges the startup if the fundraising or M&A campaign done successfully. The following is our commission structure:</li>
                <ul>
                    <li>$250,000-$500,000 USD: 10%</li>
                    <li>$500,000-$1,000,000 USD: 7%</li>
                    <li>$1,000,000+ USD: 5%</li>
                </ul>
            </ul>
            If you would like to know more about our application process, along with our requirements, please contact us at info@fund-ii.com . 
        `
        },
        {
            key: "1",
            question: "What is the fundraising or M&A campaign procedure and timeframe?",
            answer: `
            <p>We'll start reviewing your application and start working with you upon receiving your 3 steps application and verification procedure. The following are the procedure timeframe::</p>
            <p>
                <strong>Application submission and review (7-10 days):</strong> We'll start reviewing your application and all the submitted documents including identity verification, evaluation, company registration, etc., to determine your suitability and eligibility for a campaign.
            </p>
            <p>
                <strong>Response (within 72 hours):</strong> We will confirm if the application has been successful or rejected along with the rejection reasons and recommendation for improvement (if applicable) to re-submit the application at a later date.
            </p>
            <p>
                <strong>Live Campaign (60 or 90 days):</strong> This will be either a 60 or 90 days period to raise capital or M&A, based on your requirements, where the campaign is ‘live’ on the platform.
            </p>
            <p>
                <strong>Deal Closing (2-8 weeks):</strong> You will receive your funds at this stage, if your fundraising or M&A campaign was successful, after processing the legal phase such as signing of shareholders agreement, issuance of shares and/or any other legal documents arranged between you and the investor(s), and payment of fees.
            </p>
            <p>
                Please contact us if you need help throughout the process or have any questions about the application: <a href="mailto:info@fund-ii.com">info@fund-ii.com</a>.
            </p>
        `
        },
        {
            key: "2",
            question: "Tech startups in which countries are eligible to apply for fundraising (equity) or M&A on fundii platform?",
            answer: `
                <ul>
                    <li>fundii only accepts tech startups with headquarters in either MENA (Middle East & North Africa), Africa or South Asia (India, Pakistan, Sri Lanka, and Bangladesh), except countries under sanction.</li>
                    <li>fundii accepts tech startups from the idea stage up to IPO.</li>
                </ul>
            `
        },
        {
            key: "3",
            question: "What kind of startups can submit their application for fundraising or M&A?",
            answer: "fundii works only with tech startups in MENA, Africa, and South Asia - except all the countries under sanction in the regions - for fundraising (equity) or M&A."
        },
        {
            key: "4",
            question: "How long would be the fundraising or M&A campaigns?",
            answer: "It usually takes 60 to 90 days (depends on the unique situation and requirements of each startup). M&A campaigns may take a bit longer."
        },
        {
            key: "5",
            question: "How can I reach my fundraising or M&A campaign in 60-90 days?",
            answer: "We are with you during the fundraising or M&A campaign to reach your campaign goals and close the deal. However, you also need to spend time to collect all the necessary documents required for either fundraising or M&A and put effort into your proposal and campaign. We'll arrange one-on-one online meetings with prospective investors as well, when they show interest in your tech startup and are interested to know more to make a decision to invest."
        },
        {
            key: "6",
            question: "Can I close my campaign before the 60-90 days campaign?",
            answer: "Yes, you can close your campaign or opt to raise additional funding by entering the overfunding stage, if you reached your fundraising target."
        },
        {
            key: "7",
            question: "What are the fundraising or M&A campaign fees?",
            answer: `
                <ul>
                    <li>Listing and administration fee (one-time payment) - $150 USD (Free for now)</li>
                    <li>Campaign fee - $100 USD/month (Free for now)</li>
                    <ul>
                    <li>60 Days campaign - $200 USD total</li>
                    <li>90 Days campaign - $300 USD total</li>
                    </ul>
                <li>Valuation report - $245.95 USD (a must document to submit for both fundraising and M&A campaigns, if the city of your headquarters is in the following countries) through our third party (discount applies for startups that apply through our platform). Please note we can't process your application if your startup headquarters are in the following countries and you didn't submit the valuation report through our supplier (the link we've provided you during the application process).
                <br/>List of covered countries:
                <ul>
                    <li>MENA: Bahrain, Jordan, Kuwait, Qatar, Saudi Arabia, United Arab Emirates (UAE), Israel, Egypt, Tunisia</li>
                    <li>Africa: Burkina Faso, Ivory Coast, Kenya, Mauritius, Nigeria, Senegal, South Africa</li>
                    <li>South Asia: India</li>
                </ul>
                </li>
                <li>fundii charges the startup if the fundraising or M&A campaign is done successfully. The following is our commission structure:</li>
                <ul>
                    <li>$250,000-$500,000 USD - 10%</li>
                    <li>$500,000-$1,000,000 USD - 7%</li>
                    <li>$1,000,000+ USD - 5%</li>
                </ul>
                </ul>
                <p>There may be additional third-party legal fees applicable during the closing process for which the startup is solely responsible, such as documents requiring attestation, if necessary.</p>
                <p>If you would like to know more about our application process, along with our requirements, please contact us at <a href="mailto:info@fund-ii.com">info@fund-ii.com</a>.</p>
            `
        },
        {
            key: "8",
            question: "What's the minimum fundraising campaign or M&A amount?",
            answer: "$250,000 USD"
        },
        {
            key: "9",
            question: "What's the maximum fundraising or M&A amount?",
            answer: "There is no limit."
        },
        {
            key: "10",
            question: "Can I adjust or revise my fundraising or M&A target?",
            answer: "You cannot adjust or revise your fundraising target amount after the campaign is approved, launched, and becomes live."
        },
        {
            key: "11",
            question: "How can I make a decision on how much equity to offer or close the deal?",
            answer: "This depends on how much you are looking to raise, at what price, and for what purpose. We can guide you or introduce you to a financial advisor/lawyer, but ultimately the decision is yours and you should seek external advice if you are unsure. If you need help or have further questions, please contact us at info@fund-ii.com."
        }
    ];


    const questions = isInvestor ? investorQuestions : startupQuestions;


    return (
        <div className="container" style={{ marginTop: '10%' }}>
            <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Image src={topImage} fluid />
            </div>
            <div className="accordion">
                {questions.map((item) => (
                    <div key={item.key} className="card0">
                        <div className="card-header0">
                            <button
                                className="accordion-button0 orange-button"
                                onClick={() => toggleAccordion(item.key)}
                            >
                                <p>{item.question}</p>
                            </button>
                        </div>
                        {activeKeys.includes(item.key) && (
                            <div className="card-body0">
                                <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>

    );
};

export default FAQPage;
