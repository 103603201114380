import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <>
            <div className="mt-5 footer-container">
                <Row className="justify-content-md-center">
                    <Col md={4} className="mb-3 text-center">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/story">About Us</Link></li>
                            <li><Link to="/raise-capital">Raise Capital / M&A</Link></li>
                            <li><Link to="/investment-opportunities">Investment opportunities</Link></li>
                        </ul>
                    </Col>
                    <Col md={4} className="mb-3 text-center">
                        <h5>Contact</h5>
                        <ul className="list-unstyled">
                            <li>Email: info@fund-ii.com</li>
                            <li>HQ: Toronto, Canada</li>
                            <li>Regional Office: Dubai, UAE</li>
                        </ul>
                    </Col>
                    <Col md={4} className="mb-3 text-center">
                        <h5>Follow Us</h5>
                        <div className="social-icons">
                            <a href="https://twitter.com/fundiifund" className="social-icon">
                                <FontAwesomeIcon icon={faTwitter} size="2x" />
                            </a>
                            <a href="https://www.facebook.com/fundiifund" className="social-icon">
                                <FontAwesomeIcon icon={faFacebookF} size="2x" />
                            </a>
                            <a href="https://www.instagram.com/fundiifund/" className="social-icon">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                            <a href="https://www.linkedin.com/company/fund-ii/" className="social-icon">
                                <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>

            <footer className="text-center py-3">
                <Container>
                    <span>All rights reserved fundii 2024</span><br/>
                    <Button variant="link" href="/privacy-policy"><span>Privacy Policy</span></Button> <Button variant="link" href="/terms-of-use"><span>Term of Use</span></Button>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
