import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import dubaiImage from '../images/Dubai.jpg';
import torontoImage from '../images/Toronto.jpg';
import './FundiiInfoPage.css';

const LocationPage = () => {
    // Assuming these are the locations you want to display
    const locations = [
        { city: "Toronto, Canada", imageSrc: torontoImage, office: 'HQ' },
        { city: "Dubai, UAE", imageSrc: dubaiImage, office: 'Regional Operation Office' }
    ];

    const LocationSection = ({ city, imageSrc, office }) => (
        <Col xs={12} md={6} className="text-center">
            <Image style={{ height: '42.5vh', width: '40vw' }} src={imageSrc} alt="Location" fluid />
            <h4>{city}</h4>
            <h4>{office}</h4>
        </Col>
    );

    // Render the component
    return (
        <Container style={{ marginTop: '15%', marginBottom: '6%' }}>
            <Row className="justify-content-md-center">
                {locations.map((location, index) => (
                    <LocationSection key={index} city={location.city} imageSrc={location.imageSrc} office={location.office} />
                ))}
            </Row>
        </Container>
    );
};

export default LocationPage;
