import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import './FundiiInfoPage.css';
import { useNavigate, useParams } from 'react-router-dom';

const UserDetailPage = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchUserData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: `/services/users/${userId}`
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    setUserData(response.message);
                } else {
                    setUserData({});
                }
            } catch (err) {
                console.log(`Error fetching user data: `, err);
            }
        }

        fetchUserData();
    }, [userId]);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const authUser = await fetchAuthSession();
                const adminStatus = authUser?.tokens?.accessToken?.payload['cognito:groups'];
                if (!adminStatus) {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, [navigate]);

    const handleNavigateDashboard = (userType, id) => {
        navigate(`/${userType}/${id}`);
    };

    // Render the component
    return (
        <Container style={{ marginTop: '10%' }}>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Header>User Information</Card.Header>
                        <Card.Body>
                            <Card.Text><strong>Username:</strong> {userData.user?.username}</Card.Text>
                            <Card.Text><strong>Email:</strong> {userData.user?.email}</Card.Text>
                            <Card.Text><strong>Phone Number:</strong> {userData.user?.phoneNumber}</Card.Text>
                            <Card.Text><strong>Address:</strong> {userData.user?.address}</Card.Text>
                            <Card.Text><strong>Birthday:</strong> {userData.user?.birthday}</Card.Text>
                            <Card.Text><strong>Create Date:</strong> {new Date(parseInt(userData.user?.createDate)).toLocaleDateString()}</Card.Text>
                        </Card.Body>
                    </Card>
                    {userData.startup && userData.startup.stage === "6" && (
                        <Button className="mb-3" onClick={() => handleNavigateDashboard('startup', userId)}>Go to Startup Dashboard</Button>
                    )}
                    {userData.investor && userData.investor.stage === "2" && (
                        <Button className="mb-3" onClick={() => handleNavigateDashboard('investor', userId)}>Go to Investor Dashboard</Button>
                    )}
                </Col>
            </Row>
            {userData.investor && (
                <Row>
                    <Col>
                        <Card className="mb-3">
                            <Card.Header>Investor Information</Card.Header>
                            <Card.Body>
                                <Card.Text><strong>Company Name:</strong> {userData.investor.company_name}</Card.Text>
                                <Card.Text><strong>Annual Income:</strong> {userData.investor.annual_income}</Card.Text>
                                <Card.Text><strong>Email:</strong> {userData.investor.email}</Card.Text>
                                <Card.Text><strong>Interest Areas:</strong> {userData.investor.interest_areas}</Card.Text>
                                <Card.Text><strong>Investment Stage:</strong> {userData.investor.investment_stage}</Card.Text>
                                <Card.Text><strong>Investor Type:</strong> {userData.investor.investor_type}</Card.Text>
                                <Card.Text><strong>Max Investment:</strong> {userData.investor.max_investment}</Card.Text>
                                <Card.Text><strong>Min Investment:</strong> {userData.investor.min_investment}</Card.Text>
                                <Card.Text><strong>Regions:</strong> {userData.investor.regions}</Card.Text>
                                <Card.Text><strong>Registration Location:</strong> {userData.investor.registration_location}</Card.Text>
                                <Card.Text><strong>Website:</strong> {userData.investor.website}</Card.Text>
                                {/* Add more fields as necessary */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            {userData.startup && (
                <Row>
                    <Col>
                        <Card className="mb-3">
                            <Card.Header>Startup Information</Card.Header>
                            <Card.Body>
                                <Card.Text><strong>Title:</strong> {userData.startup.title}</Card.Text>
                                <Card.Text><strong>Description:</strong> {userData.startup.description}</Card.Text>
                                <Card.Text><strong>Startup Status:</strong> {userData.startup.startupStatus}</Card.Text>
                                <Card.Text><strong>Founded Year:</strong> {userData.startup.foundedYear}</Card.Text>
                                <Card.Text><strong>Team:</strong> {userData.startup.teammates.join(', ')}</Card.Text>
                                <Card.Text><strong>Vertical:</strong> {userData.startup.vertical}</Card.Text>
                                <Card.Text><strong>City/Country Incorporated:</strong> {userData.startup.cityCountryIncorporated}</Card.Text>
                                <Card.Text><strong>City/Country Headquarters:</strong> {userData.startup.cityCountryHeadquarters}</Card.Text>
                                <Card.Text><strong>Website:</strong> <a href={userData.startup.website}>{userData.startup.website}</a></Card.Text>
                                <Card.Text><strong>Purpose Driven:</strong> {userData.startup.purposeDriven ? 'Yes' : 'No'}</Card.Text>
                                <Card.Text><strong>Social Tech:</strong> {userData.startup.socialTech ? 'Yes' : 'No'}</Card.Text>
                                <Card.Text><strong>Women Founders:</strong> {userData.startup.womenFounders ? 'Yes' : 'No'}</Card.Text>
                                <Card.Text><strong>Shariah Compliant:</strong> {userData.startup.shariahCompliant ? 'Yes' : 'No'}</Card.Text>
                                <Card.Text><strong>Project Overview:</strong> {userData.startup.projectOverview}</Card.Text>
                                <Card.Text><strong>Startup Stage:</strong> {userData.startup.startupStage}</Card.Text>
                                <Card.Text><strong>Sales Type:</strong> {userData.startup.salesType}</Card.Text>
                                <Card.Text><strong>Business Model:</strong> {userData.startup.businessModel}</Card.Text>
                                <Card.Text><strong>Revenue:</strong> {userData.startup.revenue}</Card.Text>
                                <Card.Text><strong>Customer Location:</strong> {userData.startup.customerLocation}</Card.Text>
                                <Card.Text><strong>Problem Solved:</strong> {userData.startup.problemSolved}</Card.Text>
                                <Card.Text><strong>Competitors Region:</strong> {userData.startup.competitorsRegion}</Card.Text>
                                <Card.Text><strong>Competitors International:</strong> {userData.startup.competitorsInternational}</Card.Text>
                                <Card.Text><strong>Competitive Advantage:</strong> {userData.startup.competitiveAdvantage}</Card.Text>
                                <Card.Text><strong>Burn Rate:</strong> {userData.startup.burnRate}</Card.Text>
                                <Card.Text><strong>Cash Balance:</strong> {userData.startup.cashBalance}</Card.Text>
                                <Card.Text><strong>Raised Money:</strong> {userData.startup.raisedMoney}</Card.Text>
                                <Card.Text><strong>Full Time Employees:</strong> {userData.startup.fullTimeEmployees}</Card.Text>
                                <Card.Text><strong>Part Time Employees:</strong> {userData.startup.partTimeEmployees}</Card.Text>
                                <Card.Text><strong>Previous Experience:</strong> {userData.startup.previousExperience}</Card.Text>
                                <Card.Text><strong>Investment Type:</strong> {userData.startup.investmentType}</Card.Text>
                                <Card.Text><strong>Pitch Deck Recording:</strong> <a href={userData.startup.pitchDeckRecording}>{userData.startup.pitchDeckRecording}</a></Card.Text>
                                <Card.Text><strong>Agree to Communication:</strong> {userData.startup.agreeCommunication ? 'Yes' : 'No'}</Card.Text>
                                <Card.Text><strong>Agree to Privacy Policy:</strong> {userData.startup.agreePrivacyPolicy ? 'Yes' : 'No'}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default UserDetailPage;

