import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { get, del, post } from 'aws-amplify/api';
import { Modal, Form, Pagination, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import { convertKeysToCamelCase, downloadCSV } from '../untils/stringUtils';
import moment from 'moment';

function SearchInput({ value, onChange, placeholder }) {
  return (
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}

function ListComponent({ type }) {
  const navigate = useNavigate();
  const apiEndpoint = useMemo(() => {
    switch (type) {
      case 'courses':
        return "/services/courses";
      case 'startup':
        return "/services/startups/stages";
      case 'investor':
        return "/services/investors/stages";
      case 'investment':
        return "/services/investments";
      case 'subscription':
        return "/services/subscribe";
      case 'users':
        return "/services/users";
      default:
        return "/";
    }
  }, [type]);

  const detailsPageBaseURL = useMemo(() => {
    switch (type) {
      case 'courses':
        return "/admin/manage/courses/";
      case 'startup':
        return "/admin/review/startup/";
      case 'investor':
        return "/admin/review/investor/";
      case 'investment':
        return "/admin/review/investment/";
      case 'subscription':
        return "/admin/review/subscription/";
      case 'users':
        return "/admin/review/user/";
      default:
        return "/";
    }
  }, [type]);

  const pageTitle = useMemo(() => {
    switch (type) {
      case 'courses':
        return 'Fundii+ List';
      case 'startup':
        return 'Startup List';
      case 'investor':
        return 'Investor List';
      case 'investment':
        return 'Investment List';
      case 'subscription':
        return 'Subscription List';
      case 'users':
        return 'Users List';
      default:
        return '';
    }
  }, [type]);



  const searchFields = useMemo(() => (
    type === 'courses' ? [
      { name: 'name', title: 'Name', placeholder: 'Search name...' },
      { name: 'language', title: 'Language', placeholder: 'Search language...' },
      { name: 'date', title: 'Date', placeholder: 'Search date...' },
      { name: 'capacity', title: 'Capacity', placeholder: 'Search capacity...' },
    ] : type === 'startup' ? [
      { name: 'title', title: 'Title', placeholder: 'Search title...' },
      { name: 'stage', title: 'Stage', placeholder: 'Search stage...' },
      { name: 'description', title: 'Description', placeholder: 'Search description...' },
      { name: 'cityCountryHeadquarters', title: 'City/Country Headquarters', placeholder: 'Search city/country...' }
    ] : type === 'investor' ? [
      { name: 'companyName', title: 'Company Name', placeholder: 'Search company name...' },
      { name: 'registrationLocation', title: 'Registration Location', placeholder: 'Search registration location...' },
      { name: 'investorType', title: 'Investor Type', placeholder: 'Search investor type...' },
      { name: 'impactInvestor', title: 'Is Impact Investor', placeholder: 'Search impact investor...' }
    ] : type === 'investment' ? [
      { name: 'date', title: 'Date', placeholder: 'Search date...' },
      { name: 'investor', title: 'Investor name', placeholder: 'Search investor name...' },
      { name: 'investorCountry', title: "Investor's country", placeholder: 'Search investor country...' },
      { name: 'startup', title: 'Startup name', placeholder: 'Search startup name...' },
      { name: 'startupCountry', title: "Startup's country", placeholder: 'Search startup country...' },
      { name: 'startupVertical', title: 'Startup vertical', placeholder: 'Search startup vertical...' },
      { name: 'startupStage', title: 'Startup stage', placeholder: 'Search startup stage...' },
      { name: 'amount', title: 'Investment amount', placeholder: 'Search investment amount...' },
      { name: 'investmentType', title: 'Investment type', placeholder: 'Search investment type...' },
    ] : type === 'subscription' ? [
      { name: 'firstName', title: 'First Name', placeholder: 'Search first name...' },
      { name: 'familyName', title: 'Family Name', placeholder: 'Search family name...' },
      { name: 'country', title: 'Country', placeholder: 'Search country...' },
      { name: 'email', title: 'Email', placeholder: 'Search email...' },
      { name: 'isInvestor', title: 'Investor Status', placeholder: 'Search investor status...' },
      { name: 'isStartup', title: 'Startup Status', placeholder: 'Search startup status...' }
    ] : type === 'users' ? [
      { name: 'username', title: 'Username', placeholder: 'Search username...' },
      { name: 'createDate', title: 'Create Date', placeholder: 'Search create date...' },
      { name: 'phoneNumber', title: 'Phone Number', placeholder: 'Search phone number...' },
      { name: 'email', title: 'Email', placeholder: 'Search email...' },
    ]: []
  ), [type]);

  const [items, setItems] = useState([]);
  const [searchState, setSearchState] = useState(
    searchFields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredItems, setFilteredItems] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [newCourse, setNewCourse] = useState({
    name: '',
    language: '',
    date: '',
    capacity: '',
    description: '',
    professor: '',
    type: '',
    url: '',
    certificate: '',
  });

  const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
  const [editingCourseId, setEditingCourseId] = useState(null);
  const handleShowModal = () => {
    setModalMode('add');
    setShowModal(true)
  };
  const handleCloseModal = () => setShowModal(false);

  const handleNewCourseChange = (e) => {
    setNewCourse({ ...newCourse, [e.target.name]: e.target.value });
  };

  async function fetchData() {
    try {
      const restOperation = get({
        apiName: 'fundiiAPI', // Replace with your actual API name
        path: apiEndpoint // Use your dynamic endpoint
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      const camelCaseData = convertKeysToCamelCase(response.message);
      if (camelCaseData && camelCaseData.length > 0) {
        const formattedData = camelCaseData.map(item => {
          if (item.createDate) {
            item.createDate = moment(parseInt(item.createDate)).format('YYYY-MM-DD h:mm A');
          }
          return item;
        });
        setItems(formattedData);
        setFilteredItems(formattedData);
      } else {
        setItems([]);
        setFilteredItems([]);
      }
    } catch (err) {
      console.log(`Error fetching ${type}s: `, err);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    const results = items.filter(item =>
      searchFields.every(field =>
        item[field.name]?.toLowerCase().includes(searchState[field.name]?.toLowerCase())
      )
    );
    setFilteredItems(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, searchState]);

  const handleShowModalForEdit = (courseId) => {
    const courseToEdit = items?.find(item => item.id === courseId);
    if (courseToEdit) {
      setNewCourse({
        name: courseToEdit.name,
        language: courseToEdit.language,
        date: courseToEdit.date,
        capacity: courseToEdit.capacity,
        description: courseToEdit.description,
        professor: courseToEdit.professor,
        type: courseToEdit.type,
        url: courseToEdit.url,
        certificate: courseToEdit.certificate,
      });
      setModalMode('edit');
      setEditingCourseId(courseId);
      setShowModal(true);
    }
  };

  const handleAddCourse = async () => {
    try {
      const myInit = {
        body: {
          ...newCourse,
        },
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${session.idToken.jwtToken}` // Uncomment if needed
        }
      };

      const restOperation = post({
        apiName: "fundiiAPI",
        path: "/services/courses",
        options: myInit
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      const updatedCourseList = await fetchData(); // Assuming fetchCourses is your function to fetch courses
      setItems(updatedCourseList);
      handleCloseModal();
      // Optionally, refetch the course list or update state
    } catch (error) {
      console.error('Error adding course:', error);
    }
  };

  const handleRemoveCourse = async (courseId) => {
    try {
      const restOperation = del({
        apiName: 'fundiiAPI',
        path: `/services/courses/${courseId}`
      });
      const { body } = await restOperation.response;
      const response = await body.json();

      // Update the items state to reflect the removal
      const updatedCourseList = await fetchData(); // Assuming fetchCourses is your function to fetch courses
      setItems(updatedCourseList);
    } catch (error) {
      console.error('Error removing course:', error);
    }
  };

  const handleEditCourse = async (courseId) => {
    try {
      const myInit = {
        body: {
          ...newCourse,
        },
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${session.idToken.jwtToken}` // Uncomment if needed
        }
      };

      const restOperation = post({
        apiName: "fundiiAPI",
        path: `/services/courses/${courseId}`,
        options: myInit
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      console.log('Course edition response:', response);
      await fetchData();
      handleCloseModal();
    } catch (error) {
      console.error('Error editing course:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredItems.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleInvestmentAction = (investmentId, action) => {
    // Perform actions based on 'action' (approve or reject)
    // You can implement the logic here
    console.log(`Investment ID: ${investmentId}, Action: ${action}`);
  };

  const handlePrevPage = () => setCurrentPage(prev => (prev === 1 ? prev : prev - 1));
  const handleNextPage = () => setCurrentPage(prev => (prev === pageNumbers.length ? prev : prev + 1));

  const handleRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = (value, field) => {
    setSearchState(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const downloadEmails = (filterCondition) => {
    let filteredEmails = [];
    if (filterCondition === 'all') {
      filteredEmails = items.map(item => [item.email]);
    } else {
      filteredEmails = items
        .filter(item => item[filterCondition] === "True")
        .map(item => [item.email]);
    }

    downloadCSV(filteredEmails, `emails-${filterCondition}.csv`);
  };

  return (
    <div className="container" style={{ marginTop: '10%' }}>
      <div className="card">
        <div className="card-body">
          <div className="row mb-4">
            <div className="col">
              <h2 className="card-title">{pageTitle}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table">
                <thead>
                  <tr>
                    {searchFields.map(field => (
                      <th key={field.name}>{field.title}</th>
                    ))}
                    {(type !== 'subscription') && (
                      <th>Actions</th>
                    )
                    }
                  </tr>
                  <tr>
                    {searchFields.map(field => (
                      <th key={field.name}>
                        <SearchInput
                          value={searchState[field.name]}
                          onChange={(e) => handleSearch(e.target.value, field.name)}
                          placeholder={field.placeholder}
                        />
                      </th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((item, index) => (
                    <tr key={index}>
                      {searchFields.map(field => (
                        <td key={field.name}>{item[field.name]}</td>
                      ))}
                      {(type !== 'subscription') && (
                        <td>
                          {type === 'courses' ? (
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                Actions
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowModalForEdit(item.id)}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleRemoveCourse(item.id)}>Remove</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : type === 'investment' ? (
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle variant="primary">Action </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleInvestmentAction(item.id, 'approve')}>Approve</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleInvestmentAction(item.id, 'reject')}>Reject</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            <Button
                              className="primary"
                              onClick={() => navigate(detailsPageBaseURL + item.id)}
                            >
                              Review
                            </Button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between align-items-center">
                <Pagination>
                  <Pagination.Prev onClick={handlePrevPage} disabled={currentPage === 1} />
                  {pageNumbers.map(number => (
                    <Pagination.Item
                      key={number}
                      active={number === currentPage}
                      onClick={() => setCurrentPage(number)}
                    >
                      {number}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={handleNextPage} disabled={currentPage === pageNumbers.length} />
                </Pagination>
                <Form.Select aria-label="Rows per page" onChange={handleRowsPerPage} value={itemsPerPage} style={{ width: 'auto' }}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </Form.Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {type === 'courses' && (
        <div className="mb-3 text-right">
          <Button variant="primary" onClick={handleShowModal}>
            Add New Course
          </Button>
        </div>
      )}
      {type === 'subscription' && (
        <div className="text-center mt-4">
          <Button variant="primary" onClick={() => downloadEmails('all')}>Download All Emails</Button>
          <Button variant="primary" onClick={() => downloadEmails('isInvestor')} className="mx-2">Download Investor Emails</Button>
          <Button variant="primary" onClick={() => downloadEmails('isStartup')}>Download Startup Emails</Button>
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalMode === 'add' ? 'Add New Course' : 'Edit Course'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Name */}
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={newCourse.name} onChange={handleNewCourseChange} />
            </Form.Group>

            {/* Language */}
            <Form.Group className="mb-3">
              <Form.Label>Language</Form.Label>
              <Form.Control type="text" name="language" value={newCourse.language} onChange={handleNewCourseChange} />
            </Form.Group>

            {/* Date */}
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control type="date" name="date" value={newCourse.date} onChange={handleNewCourseChange} />
            </Form.Group>

            {/* Capacity */}
            <Form.Group className="mb-3">
              <Form.Label>Capacity</Form.Label>
              <Form.Control type="number" name="capacity" value={newCourse.capacity} onChange={handleNewCourseChange} />
            </Form.Group>

            {/* Description */}
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" name="description" value={newCourse.description} onChange={handleNewCourseChange} />
            </Form.Group>

            {/* Professor */}
            <Form.Group className="mb-3">
              <Form.Label>Professor</Form.Label>
              <Form.Control type="text" name="professor" value={newCourse.professor} onChange={handleNewCourseChange} />
            </Form.Group>

            {/* Type */}
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Control type="text" name="type" value={newCourse.type} onChange={handleNewCourseChange} />
            </Form.Group>
            {/* URL */}
            <Form.Group className="mb-3">
              <Form.Label>UrL</Form.Label>
              <Form.Control type="text" name="url" value={newCourse.url} onChange={handleNewCourseChange} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Certificate</Form.Label>
              <Form.Control type="text" name="certificate" value={newCourse.certificate} onChange={handleNewCourseChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={modalMode === 'add' ? handleAddCourse : () => handleEditCourse(editingCourseId)}>
            {modalMode === 'add' ? 'Save Changes' : 'Update Course'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ListComponent;
