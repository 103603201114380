import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Form, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const reportData = {
  daily: {
    investmentsMade: 20,
    newInvestors: 5,
    newStartups: 3,
    newMatches: 10,
    totalFundingAmount: 50000, // Total amount of funding secured
    averageInvestmentSize: 2500, // Average size of investments made
    topSector: 'Technology', // The sector with most investments
  },
  weekly: {
    investmentsMade: 120,
    newInvestors: 30,
    newStartups: 18,
    newMatches: 60,
    totalFundingAmount: 300000,
    averageInvestmentSize: 2500,
    topSector: 'Healthcare',
  },
  monthly: {
    investmentsMade: 480,
    newInvestors: 120,
    newStartups: 72,
    newMatches: 240,
    totalFundingAmount: 1200000,
    averageInvestmentSize: 2500,
    topSector: 'FinTech',
  }
};

const mockData = [
  { city: 'Dubai', country: 'UAE', amountRaised: 450000, date: '2023-11-24', investmentStage: 'seed' },
  { city: 'Dubai', country: 'UAE', amountRaised: 300000, date: '2023-10-25', investmentStage: 'preSeed' },
  { city: 'San Francisco', country: 'USA', amountRaised: 2000000, date: '2023-11-02', investmentStage: 'seriesA' },
  { city: 'New York', country: 'USA', amountRaised: 3500000, date: '2023-10-30', investmentStage: 'seriesB' },
  { city: 'London', country: 'UK', amountRaised: 1200000, date: '2023-11-03', investmentStage: 'seriesA' },
  { city: 'Berlin', country: 'Germany', amountRaised: 250000, date: '2023-10-29', investmentStage: 'preSeed' },
  { city: 'Dubai', country: 'UAE', amountRaised: 700000, date: '2023-11-02', investmentStage: 'seed' },
  { city: 'Tokyo', country: 'Japan', amountRaised: 4000000, date: '2023-10-28', investmentStage: 'seriesC' },
  { city: 'Seoul', country: 'South Korea', amountRaised: 1500000, date: '2023-11-01', investmentStage: 'seriesA' },
  { city: 'Mumbai', country: 'India', amountRaised: 500000, date: '2023-10-26', investmentStage: 'seed' },
  { city: 'Paris', country: 'France', amountRaised: 850000, date: '2023-10-22', investmentStage: 'seed' },
  { city: 'Sydney', country: 'Australia', amountRaised: 2000000, date: '2023-10-27', investmentStage: 'seriesB' },
  { city: 'Toronto', country: 'Canada', amountRaised: 1300000, date: '2023-11-03', investmentStage: 'seriesA' },
  { city: 'São Paulo', country: 'Brazil', amountRaised: 950000, date: '2023-10-24', investmentStage: 'preSeed' },
  { city: 'Shanghai', country: 'China', amountRaised: 3100000, date: '2023-10-30', investmentStage: 'seriesC' },
  { city: 'Johannesburg', country: 'South Africa', amountRaised: 780000, date: '2023-11-01', investmentStage: 'seed' },
  { city: 'Moscow', country: 'Russia', amountRaised: 400000, date: '2023-10-28', investmentStage: 'preSeed' },
  { city: 'Dubai', country: 'UAE', amountRaised: 2200000, date: '2023-10-26', investmentStage: 'seriesB' },
  { city: 'San Francisco', country: 'USA', amountRaised: 5000000, date: '2023-11-02', investmentStage: 'seriesD' },
  { city: 'Berlin', country: 'Germany', amountRaised: 670000, date: '2023-10-29', investmentStage: 'seed' },
  { city: 'London', country: 'UK', amountRaised: 2800000, date: '2023-10-31', investmentStage: 'seriesC' }
];


const DailyReport = () => {
  const { daily, weekly, monthly } = reportData;
  const [selectedCriteria, setSelectedCriteria] = useState({});
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const filteredData = mockData.filter(item => {
      // Filter by Startups - City
      if (selectedCriteria.startupsCity && item.city.toLowerCase() !== selectedCriteria.startupsCity.toLowerCase()) {
        return false;
      }

      // Filter by Startups - Country
      if (selectedCriteria.startupsCountry && item.country.toLowerCase() !== selectedCriteria.startupsCountry.toLowerCase()) {
        return false;
      }

      // Filter by Investment Time Frame
      // For simplicity, assuming the date format is 'YYYY-MM-DD' and time frame is in days
      if (selectedCriteria.investmentTimeFrame) {
        const investmentDate = new Date(item.date);
        const currentDate = new Date();
        const timeFrameDays = {
          perDay: 1,
          perWeek: 7,
          perMonth: 30,
          perQuarter: 90,
          perYear: 365
        };
        const daysDifference = Math.ceil((currentDate - investmentDate) / (1000 * 60 * 60 * 24));
        if (daysDifference > timeFrameDays[selectedCriteria.investmentTimeFrame]) {
          return false;
        }
      }

      // Filter by Investment Stage
      if (selectedCriteria.investmentStage && item.investmentStage.toLowerCase() !== selectedCriteria.investmentStage.toLowerCase()) {
        return false;
      }

      // Filter by Startups Raised Range
      const raisedRange = selectedCriteria.raisedRange ? selectedCriteria.raisedRange.split('To') : [];
      if (raisedRange.length === 2) {
        const lowerBound = raisedRange[0] === 'under500k' ? 0 : parseInt(raisedRange[0]);
        const upperBound = raisedRange[1] ? parseInt(raisedRange[1]) : Infinity;
        if (item.amountRaised < lowerBound || item.amountRaised > upperBound) {
          return false;
        }
      }

      // Filter by Investors Invested Range
      const investedRange = selectedCriteria.investedRange ? selectedCriteria.investedRange.split('To') : [];
      if (investedRange.length === 2) {
        const lowerBound = investedRange[0] === 'under500k' ? 0 : parseInt(investedRange[0]);
        const upperBound = investedRange[1] ? parseInt(investedRange[1]) : Infinity;
        if (item.amountInvested < lowerBound || item.amountInvested > upperBound) {
          return false;
        }
      }

      return true;
    });

    // Map the filtered data to the format expected by the chart
    const dataForChart = filteredData.map(item => ({
      name: item.date, // or any other key you want to use for x-axis
      investments: item.amountRaised
    }));

    setChartData(dataForChart);
  }, [selectedCriteria]);

  const renderReportData = (data, title) => (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          The <strong>{title}</strong> report indicates a total of <strong>{data.investmentsMade} investments</strong> made, with <strong>{data.newInvestors} new investors</strong> and <strong>{data.newStartups} new startups</strong> joining the platform. The top sector for this period was <strong>{data.topSector}</strong>.
        </Card.Text>
        <Card.Text>
          The total funding amount reached <strong>${data.totalFundingAmount}</strong>, with an average investment size of <strong>${data.averageInvestmentSize}</strong>. There were <strong>{data.newMatches} new matches</strong> between investors and startups, indicating active engagement on the platform.
        </Card.Text>
      </Card.Body>
    </Card>
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedCriteria({ ...selectedCriteria, [name]: value });
    // Update chart data based on new criteria
    // This should include logic to handle the new criteria
  };


  return (
    <div className="container mt-5">
      {renderReportData(daily, 'Daily Data')}
      {renderReportData(weekly, 'Weekly Data')}
      {renderReportData(monthly, 'Monthly Data')}
      {/* Criteria selection UI */}
      <Card>
        <Card.Body>
        <Card.Title>Search Criterias</Card.Title>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Control type="text" name="startupsCity" placeholder="Startups - City" onChange={handleInputChange} />
              </Col>
              <Col>
                <Form.Control type="text" name="startupsCountry" placeholder="Startups - Country" onChange={handleInputChange} />
              </Col>
              <Col>
                <Form.Select name="investmentTimeFrame" onChange={handleInputChange}>
                  <option value="">Select Time Frame</option>
                  <option value="perDay">Investment per Day</option>
                  <option value="perWeek">Investment per Week</option>
                  <option value="perMonth">Investment per Month</option>
                  <option value="perQuarter">Investment per Quarter</option>
                  <option value="perYear">Investment per 12 Months (Year)</option>
                </Form.Select>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Select name="investmentStage" onChange={handleInputChange}>
                  <option value="">Select Investment Stage</option>
                  <option value="preSeed">Startups in Pre-Seed Stage</option>
                  <option value="seed">Startups in Seed Stage</option>
                  <option value="seriesA">Startups in Growth Stage - Series A</option>
                  <option value="seriesB">Startups in Growth Stage - Series B</option>
                  <option value="seriesC">Startups in Growth Stage - Series C</option>
                  <option value="seriesD">Startups in Growth Stage - Series D and above</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select name="raisedRange" onChange={handleInputChange}>
                  <option value="">Select Investment Range</option>
                  <option value="under500k">Under $500,000</option>
                  <option value="500kTo1m">$500,000 - $1,000,000</option>
                  <option value="1mTo1_5m">$1,000,000 - $1,500,000</option>
                  <option value="1_5mTo2m">$1,500,000 - $2,000,000</option>
                  <option value="2mTo3m">$2,000,000 - $3,000,000</option>
                  <option value="3mTo4m">$3,000,000 - $4,000,000</option>
                  <option value="4mTo5m">$4,000,000 - $5,000,000</option>
                  <option value="5mTo10m">$5,000,000 - $10,000,000</option>
                  <option value="10mTo15m">$10,000,000 - $15,000,000</option>
                  <option value="15mTo20m">$15,000,000 - $20,000,000</option>
                  <option value="20mTo30m">$20,000,000 - $30,000,000</option>
                  <option value="30mTo40m">$30,000,000 - $40,000,000</option>
                  <option value="40mTo50m">$40,000,000 - $50,000,000</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select name="investedRange" onChange={handleInputChange}>
                  <option value="">Select Investment Range</option>
                  <option value="under500k">Under $500,000</option>
                  <option value="500kTo1m">$500,000 - $1,000,000</option>
                  <option value="1mTo1_5m">$1,000,000 - $1,500,000</option>
                  <option value="1_5mTo2m">$1,500,000 - $2,000,000</option>
                  <option value="2mTo3m">$2,000,000 - $3,000,000</option>
                  <option value="3mTo4m">$3,000,000 - $4,000,000</option>
                  <option value="4mTo5m">$4,000,000 - $5,000,000</option>
                  <option value="5mTo10m">$5,000,000 - $10,000,000</option>
                  <option value="10mTo15m">$10,000,000 - $15,000,000</option>
                  <option value="15mTo20m">$15,000,000 - $20,000,000</option>
                  <option value="20mTo30m">$20,000,000 - $30,000,000</option>
                  <option value="30mTo40m">$30,000,000 - $40,000,000</option>
                  <option value="40mTo50m">$40,000,000 - $50,000,000</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {/* Chart Visualization */}
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Investment Chart</Card.Title>
          <BarChart width={600} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="investments" fill="#8884d8" />
          </BarChart>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DailyReport;
