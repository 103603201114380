import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, post } from 'aws-amplify/api';
import { Card, Button, Col, Row, Tab, Tabs, Modal, Form, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../untils/stringUtils';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import profileImage from '../images/profile.jpg';
import './StartupDashboard.css';
import { getYoutubeEmbedUrl } from '../untils/stringUtils'
import { fetchUserAttributes } from 'aws-amplify/auth';
import { list, getUrl, uploadData, remove } from 'aws-amplify/storage';

function StartupDashboard() {
    const navigate = useNavigate();
    const { startupId } = useParams();
    const [startup, setStartup] = useState(null);
    const [key, setKey] = useState('overview');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updatedUrl, setUpdatedUrl] = useState('');
    const [profileImageUrl, setProfileImageUrl] = useState('');

    const investmentList = [
        {
            id: '5411sqssa15aasc',
            date: '2023-12-15',
            investorName: 'Investor X',
            amount: 25000,
            investmentType: 'Fundraising (equity)',
            status: 'finished'
        },
        {
            id: '5165dqwdqwddasdASDa1451assad',
            date: '2023-12-20',
            investorName: 'Investor Y',
            amount: 30000,
            investmentType: 'Merger',
            status: 'processing'
        },
        {
            id: '15616wqedasd41qw5',
            date: '2023-12-25',
            investorName: 'Investor Z',
            amount: 20000,
            investmentType: 'Acquisition',
            status: 'waiting for cap table'
        }
    ];

    const [isInWaitlist, setIsInWaitlist] = useState(false);

    const checkWaitlist = async () => {
        const userAttributes = await fetchUserAttributes();
        try {
            const restOperation = get({
                apiName: "fundiiAPI",
                path: `/services/investors/waitlist/check/${userAttributes.sub}/${startupId}`,
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
            setIsInWaitlist(response.message.isInWaitlist);
        } catch (err) {
            console.error("Error checking waitlist:", err);
        }
    };

    const fetchProfileImageUrl = async () => {
        try {
            const userAttributes = await fetchUserAttributes();
            const userId = userAttributes.sub;
            const filename = `${userId}/profile.jpg`; 

            const getUrlResult = await getUrl({
                key: filename,
                options: {
                    accessLevel: 'guest',
                },
            });
            if (getUrlResult.url) {
                setProfileImageUrl(getUrlResult.url);
            }
        } catch (error) {
            console.error('Error fetching profile image URL:', error);
        }
    };

    useEffect(() => {
        async function fetchStartup() {
            try {
                // Updated to use new API method
                const userAttributes = await fetchUserAttributes();
                if (startupId) {
                    const membershipEndDate = userAttributes['custom:premium'];
                    if (membershipEndDate) {
                        const endDate = new Date(membershipEndDate);
                        if (new Date() >= endDate) {
                            navigate("/")
                        }
                    }
                }
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: `/services/startups/${startupId ? startupId : userAttributes.sub}`
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                ;
                const camelCaseData = convertKeysToCamelCase(response.message);
                if (camelCaseData && camelCaseData.cable === '') {
                    setShowUpdateModal(true);
                }
                setStartup(camelCaseData);
            } catch (err) {
                console.error("Error fetching startup details: ", err);
            }
        }
        checkWaitlist();
        fetchStartup();
        fetchProfileImageUrl();
    }, [startupId]);


    const [showInvestModal, setShowInvestModal] = useState(false);
    const [investmentAmount, setInvestmentAmount] = useState('');

    const handleInvestNow = () => {
        setShowInvestModal(true);
    };

    const handleConfirmInvestment = async () => {
        const userAttributes = await fetchUserAttributes();
        const restOperation = get({
            apiName: "fundiiAPI",
            path: `/services/investors/${userAttributes.sub}`
        });

        const { body } = await restOperation.response;
        const response = await body.json();

        const myInit = {
            body: {
                amount: investmentAmount,
                investorId: userAttributes.sub,
                investor: response.message.company_name,
                investorCountry: response.message.regions,
                startupCountry: startup.city_country_headquarters.split(',').pop().trim(),
                startupId: startup.id,
                startupStage: startup.stage,
                startupVertical: startup.vertical,
                startup: startup.title,
                status: 0,
                date: new Date().toISOString().split('T')[0],
            },
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            post({
                apiName: "fundiiAPI",
                path: `/services/investments`,
                options: myInit
            });
            navigate('/status/investment-successful');
        } catch (err) {
            console.error("Error during investment: ", err);
            // Handle errors here
        } finally {
            setShowInvestModal(false); // Close the modal
            setInvestmentAmount(''); // Reset investment amount
        }
    };

    const handleClose = () => {
        setShowInvestModal(false);
        setInvestmentAmount(''); // Reset on close
    };

    const handleAmountChange = (e) => {
        setInvestmentAmount(e.target.value);
    };

    const handleFollow = async () => {
        const waitListData = {
            startupId: startupId,
            name: startup.title,
            industry: startup.industry,
            description: startup.description,
        };
        const userAttributes = await fetchUserAttributes();

        try {
            const myInit = {
                body: {
                    ...waitListData,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            var restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/investors/waitlist/${userAttributes['sub']}`,
                options: myInit
            });

            const { body } = await restOperation.response;
            const response = await body.json();
            checkWaitlist();
            // Add logic to handle success response, such as updating UI or state
        } catch (err) {
            console.error("Error adding to waitlist:", err);
            // Handle errors here, such as displaying an error message to the user
        }
    };

    const handleDisFollow = async () => {
        const userAttributes = await fetchUserAttributes();

        try {
            const myInit = {
                body: {
                    startupId,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            var restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/investors/waitlist/disfollow/${userAttributes['sub']}`,
                options: myInit
            });

            const { body } = await restOperation.response;
            const response = await body.json();

            checkWaitlist();
            // Add logic to handle success response, such as updating UI or state
        } catch (err) {
            console.error("Error adding to waitlist:", err);
            // Handle errors here, such as displaying an error message to the user
        }
    };

    const handleLinkClick = async (url) => {
        try {
            const userAttributes = await fetchUserAttributes();
            const myInit = {
                body: {
                    cable: '',
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            post({
                apiName: "fundiiAPI",
                path: `/services/startups/${userAttributes.sub}`,
                options: myInit
            });
            setShowUpdateModal(true);
            window.open(url, '_blank');
        } catch (error) {
            console.error("Error in backend call: ", error);
        }
    };

    const handleUpdateUrl = async () => {
        try {
            const userAttributes = await fetchUserAttributes();
            const myInit = {
                body: {
                    cable: updatedUrl,
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            post({
                apiName: "fundiiAPI",
                path: `/services/startups/${userAttributes.sub}`,
                options: myInit
            });

            setStartup({ ...startup, cable: updatedUrl });

            setShowUpdateModal(false);
        } catch (error) {
            console.error("Error updating cable URL: ", error);
            // Handle error appropriately
        }
    };

    const handleChangeUrl = (e) => {
        setUpdatedUrl(e.target.value);
    };

    if (!startup) {
        return <div className="text-center my-5">Loading...</div>;
    }

    const percentage = startup ? (startup.raised / startup.baseTarget) * 100 : 0;

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip-width" {...props}>
            Click here to access Carta for cap table management and SAFE note preparation. Carta offers a comprehensive solution for managing your startup's equity and investment details. Utilize our partnership with Carta to streamline your fundraising efforts and share your updated cap table with investors on the Fundii platform. Remember to submit the Carta link back to us once you've updated your information to keep your investor relations transparent and up-to-date.
        </Tooltip>
    );

    const handleProfileImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const userAttributes = await fetchUserAttributes();
            const userId = userAttributes.sub;
            const fileExtension = file.name.split('.').pop();
            const key = `${userId}/profile.${fileExtension}`;

            await uploadData({
                key: key,
                data: file
            }).result;

            fetchProfileImageUrl();
            // Optionally, update the UI to reflect the new profile image
            // You might need to fetch the image URL again using getUrl and update the state
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };


    return (
        <Row className="no-gutters">
            <Col style={{ marginTop: '6%' }} md={3} className="sidebar">
                {!startupId && (
                    <div className="text-center mb-3">
                        <Button variant="outline-primary" className="mt-2" onClick={() => document.getElementById('profile-image-upload').click()}>
                            Edit Profile Image
                        </Button>
                        <input type="file" id="profile-image-upload" accept="image/*" onChange={handleProfileImageUpload} style={{ display: 'none' }} />
                    </div>
                )}
                <div className="textCenter">
                    <img src={profileImageUrl || profileImage} alt="Profile" className="profileImage" />
                    <h3 className="bold">{startup.title}</h3>
                </div>
                <div className="progressbarContainer">
                    <CircularProgressbar
                        value={percentage}
                        text={`${percentage.toFixed(0)}%`}
                        styles={buildStyles({
                            pathColor: `#10ac84`,
                            textColor: '#fff',
                            trailColor: 'rgba(255, 255, 255, 0.2)',
                        })}
                    />
                </div>
                <div className="textCenter">
                    <p className="bold">Base Target: ${startup.baseTarget}</p>
                    <p className="bold">Raised: ${startup.raised}</p>
                    <p className="bold">Investors: {startup.crowd}</p>
                </div>
                {startupId && (
                    <div className="flexColumn">
                        <Button variant="success" onClick={handleInvestNow} size="md" block>
                            Invest Now
                        </Button>
                        <Button variant="outline-light" onClick={isInWaitlist ? handleDisFollow : handleFollow} size="md" block>
                            {isInWaitlist ? 'Remove from Waitlist' : 'Add to Waitlist'}
                        </Button>
                    </div>
                )}
            </Col>

            <Col md={9} className="p-3" style={{ marginTop: '6%' }}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    {!startupId && (
                        <Tab eventKey="investmentList" title="Investment List">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Investor Logo</th>
                                        <th>Investor Name</th>
                                        <th>My Investment Come from Investor</th>
                                        <th>Type of Investment</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {investmentList.map((investment, index) => (
                                        <tr key={index}>
                                            <td>{investment.date}</td>
                                            <td><img src={profileImage} alt="Startup Logo" className="startup-logo" width="50" height="50" /></td>
                                            <td>
                                                {investment.investorName}
                                            </td>
                                            <td>${investment.amount.toLocaleString()}</td>
                                            <td>{investment.investmentType}</td>
                                            <td>{investment.status}</td>
                                            <td>
                                                {investment.status === 'waiting for cap table' && (
                                                    <div className="d-flex align-items-center">
                                                        <Button
                                                            variant="link"
                                                            onClick={() => handleLinkClick('https://carta.com/partners/referral/?utm_medium=ChannelReferral&utm_source=fundii&PID=fundii')}
                                                        >
                                                            Complete Cap Table
                                                        </Button>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={renderTooltip}
                                                        >
                                                            <button className="info-circle ms-2">?</button>
                                                        </OverlayTrigger>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Tab>
                    )}
                    <Tab eventKey="overview" title="Overview">
                        <Card className="mb-3">
                            <Card.Header>Basic Information and Overview</Card.Header>
                            <Card.Body>
                                <p><strong>Description:</strong> {startup.description}</p>
                                <p><strong>Vertical:</strong> {startup.vertical}</p>
                                <p><strong>City/Country of Incorporation:</strong> {startup.cityCountryIncorporated}</p>
                                <p><strong>City/Country of Headquarters:</strong> {startup.cityCountryHeadquarters}</p>
                                <p><strong>Website:</strong> <a href={startup.website} target="_blank" rel="noopener noreferrer">{startup.website}</a></p>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="details" title="Details">
                        <Card className="mb-3">
                            <Card.Header>Detailed Project Information</Card.Header>
                            <Card.Body>
                                <p><strong>Project Overview:</strong> {startup.projectOverview}</p>
                                <p><strong>Business Model:</strong> {startup.businessModel}</p>
                                <p><strong>Sales Type:</strong> {startup.salesType}</p>
                                <p><strong>Customer Location:</strong> {startup.customerLocation}</p>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="financials" title="Financials">
                        <Card className="mb-3">
                            <Card.Header>Financial and Operational Metrics</Card.Header>
                            <Card.Body>
                                <p><strong>Revenue:</strong> {startup.revenue}</p>
                                <p><strong>Burn Rate:</strong> {startup.burnRate}</p>
                                <p><strong>Cash Balance:</strong> {startup.cashBalance}</p>
                                <p><strong>Raised Money:</strong> {startup.raisedMoney}</p>
                                <p><strong>Full-Time Employees:</strong> {startup.fullTimeEmployees}</p>
                                <p><strong>Part-Time Employees:</strong> {startup.partTimeEmployees}</p>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab eventKey="market" title="Market">
                        <Card className="mb-3">
                            <Card.Header>Market Positioning and Strategy</Card.Header>
                            <Card.Body>
                                <p><strong>Problem Solved:</strong> {startup.problemSolved}</p>
                                <p><strong>Competitors (Regional and International):</strong> {startup.competitorsRegion}, {startup.competitorsInternational}</p>
                                <p><strong>Competitive Advantage:</strong> {startup.competitiveAdvantage}</p>
                            </Card.Body>
                        </Card>
                    </Tab>

                    <Tab eventKey="team" title="Team">
                        <Card className="mb-3">
                            <Card.Header>Team and Experience</Card.Header>
                            <Card.Body>
                                {startup.teammates && startup.teammates.map((teammate, index) => (
                                    <div key={index} className="mb-2">
                                        <p><strong>Teammate {index + 1}:</strong> {teammate.firstName} {teammate.familyName}</p>
                                        <p><strong>Position:</strong> {teammate.position}</p>
                                        <p><strong>Country of Residence:</strong> {teammate.countryOfResidence}</p>
                                        <p><strong>Education Level:</strong> {teammate.educationLevel}</p>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Tab>


                    <Tab eventKey="pitch" title="Pitch">
                        <Card className="mb-3">
                            <Card.Header>Additional Resources</Card.Header>
                            <Card.Body>
                                <p><strong>Investment Pitch:</strong></p>
                                {startup.investmentPitch &&
                                    <div className="video-preview mb-4">
                                    <iframe
                                        title='pitch_preview'
                                        width="100%"
                                        height="500"
                                        src={startup.investmentPitch}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    </div>
                                }
                                {startup.pitchDeckRecording &&
                                    <div className="video-preview mb-4">
                                        <iframe
                                            title='pitch_preview'
                                            width="100%"
                                            height="500"
                                            src={getYoutubeEmbedUrl(startup.pitchDeckRecording)}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Tab>

                </Tabs>
            </Col>

            <Modal
                show={showInvestModal}
                onHide={handleClose}
                centered // This centers the modal vertically
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invest in Startup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Investment Amount</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter amount"
                            value={investmentAmount}
                            onChange={handleAmountChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleConfirmInvestment}>
                        Confirm Investment
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showUpdateModal}
                onHide={() => setShowUpdateModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update The Cable URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Please enter the URL of cable you created in Carta</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter cable URL"
                            value={updatedUrl}
                            onChange={handleChangeUrl}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdateUrl}>
                        Update URL
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
}

export default StartupDashboard;
