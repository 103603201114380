import React, { useState } from 'react';

function StepsComponent({ steps, formData, isAdmin, handleSubmit, handleDecline, handleAccept, isStartup }) {
    const [showDeclineDialog, setShowDeclineDialog] = useState(false);
    const [declineReason, setDeclineReason] = useState('');
    const stage = formData.stage/2

    const handleDeclineAndCloseDialog = async () => {
        const subject = "Your Submission Has Been Declined";
        const emailBody = `Dear User,\n\nWe regret to inform you that your submission has been declined for the following reason:\n\n${declineReason}\n\nThank you for your understanding.\n\nBest regards,\nThe Team`;

        const mailtoLink = `mailto:${formData.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
        window.location.href = mailtoLink;

        handleDecline();
        setShowDeclineDialog(false);
        setDeclineReason('');
    };

    const handleOpenAcceptEmail = () => {
        const subject = "Your Submission Has Been Accepted";
        const startupEmailTemplates = [
            `Dear techpreneur,\n\nWe are pleased to inform your Step 1 of your application has been carefully reviewed and accepted.\n\nPlease log back to your account, click on Dashboard under "For Startups", and move forward to Step 2 to continue with your application.\n\nWe look forward collaborating with you to land your fundraising/M&A successfully.\n\nCongratulations!\n\nBest regards,\nFundii Admin Team`,
            `Dear techpreneur,\n\nWe are pleased to inform that your Step 2 of your application has been carefully reviewed and accepted.\n\nPlease log back to your account, click on Dashboard under "For Startups", and move forward to Step 3 to continue with your application.\n\nWe look forward collaborating with you to land your fund/M&A successfully.\n\nCongratulations!\n\nBest regards,\nFundii Admin Team`,
            `Dear techpreneur,\n\nWe are pleased to inform that your startup account is now available and your fundraising /M&A campaign will go live shortly.\n\nTo view your account please log in and check from Dashboard under "For Startups" section.\n\nFor any questions please email us at info@fund-ii.com.\n\nThank you for choosing Fundii.\n\nBest regards,\nFundii Admin Team`
        ];
    
        const investorEmailTemplate = `Dear Investor,\n\nWe are pleased to inform you that your submission has been carefully reviewed and accepted.\n\nWe look forward collaborating with you to land your investment successfully.\n\nPlease email us if you have any questions at info@fund-ii.com.\n\nCongratulations and we look forward to your participation.\n\nBest regards,\nFundii Admin Team`;
    
        const emailBody = isStartup ? startupEmailTemplates[stage] : investorEmailTemplate;

        const mailtoLink = `mailto:${formData.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`
        window.location.href = mailtoLink;
        

        handleAccept();
    };

    const handleOpenDeclineDialog = () => {
        setShowDeclineDialog(true);
    };

    const handleCloseDeclineDialog = () => {
        setShowDeclineDialog(false);
    };

    const handleDeclineReasonChange = (event) => {
        setDeclineReason(event.target.value);
    };

    const userEmailAddress = formData.email || 'defaultemail@example.com';

    const allStepsCompleted = steps.every((step) => {
        if (step.field) {
            if (Array.isArray(step.field)) {
                // Check if at least one of the multiple fields is filled
                return step.field.some(field => formData[field] && formData[field].length !== 0);
            } else {
                // Check if the single field is filled
                return formData[step.field] && formData[step.field].length !== 0;
            }
        }
        return false; // If there's no field associated with a step, consider it incomplete
    });

    return (
        <div className="steps-container bg-light mt-5 p-4 border-left">
            <h4>Steps to follow:</h4>
            <ul className="list-group list-group-flush">
                {steps.map((step, index) => (
                    <li
                        key={index}
                        className={`list-group-item ${(step.field && formData[step.field] && formData[step.field].length !== 0) || (step.field.length >= 2 && (formData[step.field[0]] || formData[step.field[1]])) ? 'active' : ''}`}
                    >
                        {step.label}
                        {((step.field && formData[step.field] && formData[step.field].length !== 0) || (step.field.length >= 2 && (formData[step.field[0]] || formData[step.field[1]]))) && (
                            <span className="ml-2">✓</span>
                        )}
                    </li>
                ))}
            </ul>
            {!isAdmin && (
                <div className="form-group mt-4">
                    <button className="btn orange-button" onClick={handleSubmit} disabled={!allStepsCompleted}>
                        Submit
                    </button>
                </div>
            )}
            {isAdmin && (
                <div className="admin-buttons mt-4">
                    <button className="btn btn-success me-2" onClick={handleOpenAcceptEmail}>
                        Accept
                    </button>
                    <button className="btn btn-danger me-2" onClick={handleOpenDeclineDialog}>
                        Decline
                    </button>
                    <a href={`mailto:${userEmailAddress}`} className="btn btn-info mt-2">
                        Contact
                    </a>
                </div>
            )}
            {showDeclineDialog && (
                <div className="decline-dialog my-3">
                    <textarea
                        className="form-control my-2"
                        value={declineReason}
                        onChange={handleDeclineReasonChange}
                        placeholder="Type the decline reason here..."
                    />
                    <div className="d-grid gap-2 d-md-block">
                        <button className="btn btn-danger me-2" onClick={handleDeclineAndCloseDialog}>
                            Send Decline Reason
                        </button>
                        <button className="btn btn-secondary" onClick={handleCloseDeclineDialog}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StepsComponent;