import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import eventImage from '../images/vertical/event ad.jpg';
import firstImage from '../images/vertical/1.jpg';
import secondImage from '../images/vertical/2.jpg';

const EventImage = ({ src, alt, className }) => (
    <div className={`mb-4 text-center ${className}`}>
        <img src={src} alt={alt} className="img-fluid" />
    </div>
);

const VerticalEventPage = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});
    const [newLink, setNewLink] = useState('');

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/12"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/12`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    // This is a handler function to save changes to the state
    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    const ActionButton = ({ part }) => {
        return isAdmin ? (
            <Form.Control
                type="text"
                value={texts[part]}
                onChange={(e) => handleTextChange(`${part}`, e.target.value)}
            />
        ) : (
            <Button
                className="mx-2 orange-button"
                href={texts[`${part}`]}
                target="_blank"
                rel="noopener noreferrer"
            >
                Watch/Listen Part{part}
            </Button>
        )
    };


    return (
        <>
            {isAdmin && (
                <>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        style={{ position: 'fixed', top: '0', marginTop: '7%', width: '100%', zIndex: 1 }}>
                        Save Changes
                    </Button>
                </>
            )}
            <h1 className="mb-5 text-center" style={{ marginTop: '10%' }}>fundii Annual AgriTech & FoodTech Event 2023</h1>

            {/* Event ad image */}
            <EventImage src={eventImage} alt="Event Ad" className="event-ad-image" />

            {/* Image file 1 */}
            <EventImage src={firstImage} alt="Event Part 1" className="regular-image" />

            {/* Buttons for file 1 */}
            <div className="text-center mb-4">
                <ActionButton part={1} />
                <ActionButton part={2} />
            </div>

            {/* Image file 2 */}
            <EventImage src={secondImage} alt="Event Part 2" className="regular-image" />

            {/* Buttons for file 2 */}
            <div className="text-center mb-4">
                <ActionButton part={3} />
                <ActionButton part={4} />
            </div>
        </>
    );
};

export default VerticalEventPage;
