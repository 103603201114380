// ImageCard.js
import React, { useCallback } from 'react';
import { Col, Card, Button, Form } from 'react-bootstrap';

const ImageCard = ({ texts, isAdmin, index, image, group, type, setTexts }) => {
  const handleTextChangeWrapper = useCallback(
    (key) => (event) => {
      const value = event.target.value;
      setTexts((prevTexts) => {
        if (prevTexts[key] === value) return prevTexts;
        return { ...prevTexts, [key]: value };
      });
    },
    []
  );
 return (
  <Col md={4} sm={6} xs={12} className="mb-4">
    <Card>
      <Card.Img variant="top" src={require(`../images/${image}`)} />
      <Card.Body>
        {type !== 'fundiitalk' ? (
          <Card.Text as="div">
            <div className="d-flex justify-content-between align-items-start">
              <div>
                {isAdmin ? (
                  <Form.Control
                    as="textarea"
                    value={texts[`${group}${index}Date`] || ''}
                    onChange={handleTextChangeWrapper(`${group}${index}Date`)}
                    name={`${group}${index}Date`}
                    className="mb-2"
                  />
                ) : (
                  <p className="mb-0">Date: {texts[`${group}${index}Date`]}</p>
                )}
              </div>
              {isAdmin ? (
                <Form.Control
                  as="textarea"
                  value={texts[`${group}${index}Watch`] || ''}
                  onChange={handleTextChangeWrapper(`${group}${index}Watch`)}
                  name={`${group}${index}Watch`}
                  style={{ maxWidth: '30%' }}
                />
              ) : (
                texts[`${group}${index}Watch`] && (
                  <Button
                    variant="info"
                    href={texts[`${group}${index}Watch`]}
                    className="ms-auto"
                    style={{ marginLeft: 'auto', marginRight: '10px', width: '8vw' }}
                  >
                    Watch
                  </Button>
                )
              )}
            </div>
            {isAdmin ? (
              <Form.Control
                as="textarea"
                value={texts[`${group}${index}Startup`] || ''}
                onChange={handleTextChangeWrapper(`${group}${index}Startup`)}
                name={`${group}${index}Startup`}
              />
            ) : (
              <Button variant="primary" href={texts[`${group}${index}Startup`]} className="mb-2 mt-2 w-100">
                Startup Application
              </Button>
            )}
            {isAdmin ? (
              <Form.Control
                as="textarea"
                value={texts[`${group}${index}Investor`] || ''}
                onChange={handleTextChangeWrapper(`${group}${index}Investor`)}
                name={`${group}${index}Investor`}
              />
            ) : (
              <Button variant="secondary" href={texts[`${group}${index}Investor`]} className="mb-2 w-100">
                Investor Application
              </Button>
            )}
            {isAdmin ? (
              <Form.Control
                as="textarea"
                value={texts[`${group}${index}URL`] || ''}
                onChange={handleTextChangeWrapper(`${group}${index}URL`)}
                name={`${group}${index}URL`}
              />
            ) : (
              <Button variant="success" href={texts[`${group}${index}URL`]} className="w-100">
                General Admission
              </Button>
            )}
          </Card.Text>
        ) : <Card.Text>
          {
            isAdmin ? (
              <Form.Control
                as="textarea"
                value={texts[`${group}${index}Date` || '']}
                onChange={handleTextChangeWrapper(`${group}${index}Date`)}
                name={`${group}${index}Date`}
              />
            ) : (
              <p>Date: {texts[`${group}${index}Date`]}</p>
            )
          }
          {
            isAdmin ? (
              <Form.Control
                as="textarea"
                value={texts[`${group}${index}URL` || '']}
                onChange={handleTextChangeWrapper(`${group}${index}URL`)}
                name={`${group}${index}URL`}
              />
            ) : (
              <Button variant="success" href={texts[`${group}${index}URL`]} className="w-100">
                General Admission
              </Button>
            )
          }
        </Card.Text>}
      </Card.Body>
    </Card>
  </Col>
)};

export default ImageCard;
