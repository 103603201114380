import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Carousel, Button, Container } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import topImage from "../images/oppor.jpg"
import map1 from "../images/map1.png";
import map2 from "../images/map2.png";
import map3 from "../images/map3.png";

function InvestmentOpportunities({ type, handleShowModal }) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/10"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/10`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    const handleStartClick = () => {
        if (!type) {
            handleShowModal('investor');
        } else if (type === 'investor') {
            navigate('/form/investor');
        } else if (type === 'startup') {
            alert("You are already registered as a startup.");
        }
    };

    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    const handleFindClick = () => {
        if (!type) {
            handleShowModal('investor');
        } else if (type === 'investor') {
            navigate('/match-r');
        } else if (type === 'startup') {
            alert("You are already registered as a startup.");
        }
    };


    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }
            <Carousel indicators={false} controls={false}>
                <Carousel.Item>
                    <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                        <h1>{texts.topDescription}</h1>
                        <Button className="orange-button" onClick={handleStartClick}>Start Investing</Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container style={{ marginTop: '10%', marginBottom: '10%' }}>

                <Row className="justify-content-md-center">
                    <Col md={12}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.oppoDescription || ''}
                                onChange={(e) => handleTextChange('oppoDescription', e.target.value)}
                            />
                        ) : (
                            <p className="text-center">{texts.oppoDescription}</p>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                    {[map1, map2, map3].map((mapSrc, index) => (
                        <Col key={index} xs={12} md={4} className="text-center">
                            <img src={mapSrc} alt={`map${index + 1}`} className="img-fluid w-75" />
                        </Col>
                    ))}
                </Row>
                <Form className="text-center mt-5 mb-5">
                    <Button className="orange-button big-button" variant="primary mt-2" onClick={handleFindClick}>Find Opportunities</Button>
                </Form>

                <div className="container" style={{ marginTop: '10%', marginLeft:"0%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-5 mb-4">
                            <div className="card h-100 shadow">
                                <div className="card-header text-white" style={{ backgroundColor: 'rgba(0, 37, 118, 1)' }}>
                                    <h3 className="card-title" style={{ textAlign: 'center' }}>Basic Membership</h3>
                                </div>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <p>
                                        Ideal for casual visitors who want a sneak peek into the world of startups.
                                        Get access to:
                                    </p>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">Public startup profiles</li>
                                        <li className="list-group-item">Basic Info</li>
                                        <li className="list-group-item">Limited search</li>
                                    </ul>
                                </div>
                                <div className="card-footer text-muted" style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#007bff', backgroundColor: '#e7f5ff', border: '2px solid #007bff', borderRadius: '5px', boxShadow: '0px 0px 8px #aaa', textAlign: 'center' }}>
                                    Free Access
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mb-4">
                            <div className="card h-100 shadow">
                                <div className="card-header bg-dark text-white">
                                    <h3 className="card-title" style={{ textAlign: 'center' }}>VIP Membership</h3>
                                </div>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <p>
                                        Designed for professionals seeking in-depth insights and full access to startup information.
                                        Benefits include:
                                    </p>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">Exclusive startup reports</li>
                                        <li className="list-group-item">Advanced analytics & trends</li>
                                        <li className="list-group-item">Unlimited search</li>
                                        <li className="list-group-item">Priority support</li>
                                    </ul>
                                </div>
                                <div className="card-footer" style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#28a745', backgroundColor: '#e6f9e6', border: '2px solid #28a745', borderRadius: '5px', boxShadow: '0px 0px 8px #aaa', textAlign: 'center' }}>
                                    <span><s>$399USD/year</s> Free Trial for 6 Months</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default InvestmentOpportunities;
