import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
};

const ApplicationStatus = () => {
  const { status } = useParams(); // Use useParams hook to get the status parameter

  let alertClass = '';
  let message = '';

  switch (status) {
    case 'submitted':
      alertClass = 'alert-success';
      message = 'We have received your application successfully and will begin the verification process as soon as possible.';
      break;
    case 'declined':
      alertClass = 'alert-danger';
      message = 'Your application has been declined. Please check your email for further details.';
      break;
    case 'investment-successful':
      alertClass = 'alert-success';
      message = 'Your investment has been processed successfully. Thank you for investing!';
      break;
    default:
      alertClass = 'alert-primary';
      message = 'Your application status is currently pending.';
      break;
  }

  return (
    <div style={styles.container}>
      <div className="col-md-8">
        <div className={`alert ${alertClass}`} role="alert">
          {message}
        </div>
      </div>
    </div>
  );
};

export default ApplicationStatus;
