import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Col, Card, Carousel, Image, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FundiiInfoPage.css';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession, updateUserAttributes, fetchUserAttributes } from 'aws-amplify/auth';
import { list, getUrl, uploadData, remove } from 'aws-amplify/storage';
import checkMarkImage from '../images/check.png';
import { useNavigate } from 'react-router-dom';


const FundiiInfoPage = ({ type, handleShowModal }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});
    const [images, setImages] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const fetchImageUrls = async () => {
        try {
            const response = await list({
                prefix: 'infoImage/',
                options: {
                    accessLevel: 'guest',
                }
            });

            const filesWithUrls = await Promise.all(response.items.map(async (file) => {
                if (!file.key.endsWith('/')) { // Exclude folders (which end with '/')
                    const getUrlResult = await getUrl({
                        key: file.key,
                        options: {
                            accessLevel: 'guest', // if your images are publicly accessible
                            expiresIn: 60 // 1 minute validity, adjust as needed
                        },
                    });
                    return {
                        url: getUrlResult.url,
                        fileName: file.key.split('/').pop().split('.')[0], // Extracting file name from the key
                        // Extract and parse the number at the end of the fileName
                        order: parseInt(file.key.match(/(\d+)(?!.*\d)/)[0], 10)
                    };
                }
                return null;
            }));

            // Now sort the array of files based on the extracted number 'order'
            const sortedFiles = filesWithUrls.filter(file => file !== null).sort((a, b) => a.order - b.order);
            return sortedFiles;
        } catch (error) {
            console.log('Error listing files from S3:', error);
            return [];
        }
    };
    useEffect(() => {
        fetchImageUrls().then(images => {
            setImages(images);
        });
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
        if (window.innerWidth < 768) {
            alert("For the best experience, please use a computer to access this website.")
        }

    }, []);

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/1"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    // Handle changes to text inputs
    const handleTextChange = (textName, newValue) => {
        setTexts({ ...texts, [textName]: newValue });
    };

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/1`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const filename = `infoImage/${file.name}`; // Adjust the path as needed
            const result = await uploadData({
                key: filename,
                data: file,
                options: {
                    accessLevel: 'guest',
                }
            }).result;
            const updatedImages = await fetchImageUrls();
            setImages(updatedImages);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleRemoveImage = async (fileName) => {
        try {
            await remove({ key: `infoImage/${fileName}.jpg` });
            const updatedImages = await fetchImageUrls();
            setImages(updatedImages);
        } catch (error) {
            console.error('Error removing file:', error);
        }
    };

    const navigate = useNavigate();

    const handleNavigateToStartupForm = async () => {
        if (!type) {
            handleShowModal('startup');
        } else if (type === 'startup') {
            navigate('/form/startup');
        } else {
            // Alert the user if they are already registered as an investor
            alert("You are already registered as an investor.");
        }
    };

    const handleNavigateToInvestorForm = async () => {
        if (!type) {
            handleShowModal('investor');
        } else if (type === 'investor') {
            navigate('/form/investor');
        } else {
            // Alert the user if they are already registered as a startup
            alert("You are already registered as a startup.");
        }
    };

    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }

            <div className="justify-content-center align-items-center" style={{ marginTop: '6%' }}>
                <Carousel className="mb-3">
                    {images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img className="d-block w-100 carousel-image" src={image.url} alt={`Slide ${index}`} />
                            <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '35%', textAlign: 'left' }}>
                                {isAdmin ?
                                    <Form.Control as="textarea" value={texts[image.fileName] || ''} onChange={(e) => handleTextChange(image.fileName, e.target.value)} />
                                    :
                                    <h2 style={{ color: 'white', fontSize: '3rem', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
                                        {texts[image.fileName]}
                                    </h2>
                                }
                                {isAdmin && (
                                    <button onClick={() => handleRemoveImage(image.fileName)} className="btn btn-danger">
                                        Remove
                                    </button>
                                )}
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
                {isAdmin && (
                    <>
                        <input type="file" onChange={handleFileUpload} style={{ display: 'none' }} id="file-upload" />
                        <label htmlFor="file-upload" className="btn btn-primary">
                            Upload New Image
                        </label>
                    </>
                )}
                <Container>
                    <div className="about-fundii text-center">
                        <h2>About fund<span className="orange-text">ii</span></h2>
                        {isAdmin ?
                            <Form.Control as="textarea" value={texts.aboutText || ''} onChange={(e) => handleTextChange('aboutText', e.target.value)} /> :
                            <p style={{ color: 'black', fontSize: '25px' }}>{texts.aboutText}</p>
                        }
                    </div>
                </Container>
                <Row className="justify-content-center light-text">
                    <Col md={4} className="d-flex">
                        <Card className="mb-3 custom-card-width w-100" style={{ backgroundColor: 'rgba(0, 37, 118, 1)' }}>
                            <Card.Body className="d-flex flex-column light-text">
                                <Card.Title className="text-center mb-3">For Startups</Card.Title>
                                {isAdmin ? (
                                    <Form.Control
                                        as="textarea"
                                        value={texts.startupText || ''}
                                        onChange={(e) => handleTextChange('startupText', e.target.value)}
                                    />
                                ) : (
                                    <Card.Text className="text-left flex-grow-1">
                                        {texts.startupText?.split('\n').map((item, key) => (
                                            <div key={key} className="d-flex align-items-start mb-3 text-with-checkmark">
                                                <Image src={checkMarkImage} fluid />
                                                <p className="mb-0 ml-2">{item}</p>
                                            </div>
                                        ))}
                                    </Card.Text>
                                )}
                                <div className="mt-auto text-center">
                                    <Button className="orange-button" variant="primary" onClick={handleNavigateToStartupForm}>
                                        Become Startup
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="d-flex">
                        <Card className="mb-3 custom-card-width w-100" style={{ backgroundColor: 'rgba(0, 37, 118, 1)' }}>
                            <Card.Body className="d-flex flex-column light-text">
                                <Card.Title className="text-center mb-3">For Investors</Card.Title>
                                {isAdmin ? (
                                    <Form.Control
                                        as="textarea"
                                        value={texts.investorText || ''}
                                        onChange={(e) => handleTextChange('investorText', e.target.value)}
                                    />
                                ) : (
                                    <Card.Text className="text-left flex-grow-1">
                                        {texts.investorText?.split('\n').map((item, key) => (
                                            <div key={key} className="d-flex align-items-start mb-3 text-with-checkmark">
                                                <Image src={checkMarkImage} fluid />
                                                <p className="mb-0 ml-2">{item}</p>
                                            </div>
                                        ))}
                                    </Card.Text>
                                )}
                                <div className="mt-auto text-center">
                                    <Button className="orange-button" variant="primary" onClick={handleNavigateToInvestorForm}>
                                        Become Investor
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default FundiiInfoPage;
