import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import StepsComponent from '../components/StepsComponent';
import CameraCapture from '../components/CameraCapture';
import { useParams } from 'react-router-dom';
import { fetchAuthSession, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { uploadData, getUrl, downloadData } from 'aws-amplify/storage';
import { get, post } from 'aws-amplify/api';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function InvestorFormPage({ match }) {
    let { userId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        email: '',
        IDAddress: '',                   // Copy of ID or passport
        annualIncome: '',         // Annual income (range)
        companyName: '',          // Organization/Company name
        registrationLocation: '', // Where the company has been registered (country and city)
        companyRegistration: '',
        website: '',              // Website
        investorType: '',         // Accelerator, Angel investor, Corporate investor, etc.
        investmentStage: [],      // Bootstrapping stage startups, Pre-seed stage startups, etc.
        verticals: [],            // Adtech/Media tech, Advanced manufacturing, etc.
        regions: [],              // MENA, Africa, South Asia
        minInvestment: [],        // Minimum investment
        maxInvestment: [],        // Maximum investment
        impactInvestor: false,
        interestInShariahStartups: false,
        interestInWomenFoundersStartups: false
    });

    const [stage, setStage] = useState(null);
    const [faceMatchResults, setFaceMatchResults] = useState(null);
    const [faceMatched, setFaceMatched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const steps = [
        { label: 'Copy of ID or passport', field: 'IDAddress' },
        { label: 'Annual Income (range)', field: 'annualIncome' },
        { label: 'Organization/Company Name', field: 'companyName' },
        { label: 'Registration Location (Country and City)', field: 'registrationLocation' },
        { label: 'Registration Document', field: 'companyRegistration' },
        { label: 'Website', field: 'website' },
        { label: 'Investor Type', field: 'investorType' },
        { label: 'Investment Stages of Interest', field: 'investmentStage' },
        { label: 'Investment Verticals', field: 'verticals' },
        { label: 'Regions of Interest for Investment', field: 'regions' },
        { label: 'Minimum Investment', field: 'minInvestment' },
        { label: 'Maximum Investment', field: 'maxInvestment' },
    ];

    useEffect(() => {
        setIsLoading(true)
        async function fetchInvestors() {
            try {
                const auth_user = await fetchAuthSession();
                const isAdmin = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                const attributes = await fetchUserAttributes();
                const type = attributes['custom:type'];
                if (!isAdmin) {
                    if (userId || (type !== 'investor')) {
                        if (type === 'startup') {
                            navigate('/');
                        } else {
                            try {
                                await updateUserAttributes({
                                    userAttributes: {
                                        'custom:type': 'investor'
                                    }
                                });
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                }
                const restOperation = get({
                    apiName: "fundiiAPI",
                    path: `/services/investors/${userId ? userId : auth_user?.tokens?.accessToken?.payload['username']}`
                });

                const { body } = await restOperation.response;
                const response = await body.json();

                if (userId) {
                    response.message.stage = response.message.stage ? response.message.stage - 1 : response.message.stage;
                }
                if (response?.message?.stage !== undefined && response?.message?.stage !== null) {
                    if (response.message.stage === 1) {
                        navigate('/status/submitted');
                    } else if (response.message.stage === -1) {
                        navigate('/status/declined');
                    } else if (response.message.stage === 0) {
                        const newFormData = {};
                        Object.keys(formData).forEach(camelCaseKey => {
                            // Convert camelCase to snake_case
                            const snakeCaseKey = camelCaseKey.replace(/([A-Z])/g, '_$1').toLowerCase();
                            if (snakeCaseKey in response.message) {
                                newFormData[camelCaseKey] = response.message[snakeCaseKey];
                            }
                        });
                        setFormData(newFormData);
                        if (userId) {
                            const getUrlResult = await getUrl({
                                key: `${userId}/CRD.pdf`,
                                options: {
                                    accessLevel: 'guest'
                                },
                            });
                            setFormData({
                                ...newFormData,
                                companyRegistrationDocPreview: getUrlResult.url, // New state property for the preview URL
                            });
                        } else {
                            setFormData(newFormData);
                        }
                        setIsLoading(false)
                    }
                    else if (response.message.stage === 2) {
                        if (match) {
                            navigate(`/match`);
                        } else {
                            navigate(`/investor/${auth_user?.tokens?.accessToken?.payload['username']}`);
                        }
                    }
                    setStage(response.message.stage)
                } else {
                    setStage(0)
                    setIsLoading(false)
                }
            } catch (err) {
                console.log("Error fetching investors: ", err);
            }
        }

        fetchInvestors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async () => {

        try {
            const userAttributes = await fetchUserAttributes();
            const myInit = {
                body: {
                    ...formData,
                    stage: stage + 1,
                    ...(stage === 2 && {
                        id: undefined,
                        email: undefined
                    }),
                    ...(stage !== 2 && {
                        id: userAttributes.sub,
                        email: userAttributes.email
                    })
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/investors${stage === 0 ? '' : `/${userAttributes.sub}`}`,
                options: myInit
            });

            const { body } = await restOperation.response;
            const response = await body.json();
            
            navigate('/status/submitted');
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    const handleAccept = async () => {
        console.log('Data to send to Flask API:', formData, stage);

        try {
            const myInit = {
                body: {
                    ...formData,
                    stage: 2,
                    id: undefined,
                    email: undefined
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            var response = post({
                apiName: "fundiiAPI",
                path: `/services/investors/${formData.id}`,
                options: myInit
            });

            navigate('/admin/list');
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const handleDecline = async () => {
        console.log('Data to send to Flask API:', formData);

        try {
            const myInit = {
                body: {
                    ...formData,
                    stage: -1,
                    id: undefined,
                    email: undefined
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            };

            var response = post({
                apiName: "fundiiAPI",
                path: "/services/investor",
                options: myInit
            });

            ;
            navigate('/admin/list');
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const filename = `idPhoto.jpg`;
                uploadData({
                    key: filename,
                    data: file,
                    options: {
                        contentType: file.type,
                        accessLevel: 'private'
                    }
                });

                const getUrlResult = await getUrl({
                    key: filename,
                    options: {
                        accessLevel: 'private', // can be 'private', 'protected', or 'guest' but defaults to `guest`
                    },
                });

                // For previewing, create a temporary URL
                const url = URL.createObjectURL(file);
                const objectKey = extractKeyFromUrl(getUrlResult.url);
                // Store just the key, not the full URL
                setFormData({
                    ...formData,
                    IDAddress: objectKey,
                });
                document.getElementById('imagePreview').src = url;
            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        }
    };

    const extractKeyFromUrl = (url) => {
        // Decode the URL to handle encoded characters
        const decodedUrl = decodeURIComponent(url);

        // This pattern will need to match the structure of your S3 keys
        // eslint-disable-next-line
        const pattern = /private\/[^\/]+\/[^\?]+/;
        const match = decodedUrl.match(pattern);

        if (match) {
            // The first match should be the key
            return match[0];
        }

        return null;
    };

    const handleImageCapture = async (blob) => {
        try {
            const fileName = `user-${Date.now()}.jpg`; // Change the file name accordingly

            // Uploading the image using uploadData
            uploadData({
                key: fileName,
                data: blob,
                options: {
                    contentType: 'image/jpeg',
                    accessLevel: 'private'
                }
            });

            // Getting the URL of the uploaded image
            const getUrlResult = await getUrl({
                key: fileName,
                options: {
                    accessLevel: 'private',
                    expiresIn: 60 // URL valid for 60 seconds, adjust as needed
                }
            });

            const objectKey = extractKeyFromUrl(getUrlResult.url);

            // API call to verify identity
            const restOperation = post({
                apiName: "fundiiAPI",
                path: "/services/verify-identity",
                options: {
                    body: {
                        idImageKey: formData.IDAddress, // The key of the ID photo
                        userImageKey: objectKey // The key of the captured photo
                    },
                    headers: {
                        'Content-Type': 'application/json'
                        // Authorization header is handled by Amplify
                    }
                }
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            if (response.message.FaceMatches && response.message.FaceMatches.length > 0) {
                setFaceMatchResults(`Face Matched`);
                await new Promise(resolve => setTimeout(resolve, 2000));
                setFaceMatched(true)
            } else {
                setFaceMatchResults("Face Not Match");
            }

        } catch (error) {
            console.error('Error in facial recognition: ', error);
            setFaceMatchResults("Error in facial recognition process");
        }

    }

    const handleVerification = () => {
        window.open("https://withpersona.com/verify?inquiry-template-id=itmpl_tuoy6p6PfVnUhevJKB315Hbm&environment-id=env_dDG4aUUzTvLnoN4CAZYeDiwo", "_blank");
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip-width" {...props}>
            For enhanced security and identity verification, we partner with Persona.
            Please ensure you upload a clear copy of your ID or passport for verification purposes.
            Follow the steps to complete your identity verification through Persona:
            <ol>
                <li>Click on the 'Start Verification' button to begin.</li>
                <li>Follow the on-screen instructions to submit your ID and any additional required information.</li>
                <li>Once you've completed all steps, return to our site to proceed.</li>
            </ol>
            Your privacy and security are our top priority. Thank you for taking the time to verify your identity.
        </Tooltip>
    );

    const investmentVerticals = [
        "Adtech / Media tech",
        "Advanced manufacturing",
        "Agtech / Farmtech",
        "Artificial intelligence and machine learning (AI/ML)",
        "Audiotech",
        "Augmented reality (AR)",
        "Autonomous cars",
        "B2B payments",
        "Beauty",
        "Big Data",
        "Biotech",
        "Cannabis",
        "Carsharing",
        "Cleantech",
        "Climate tech",
        "Cloudtech and DevOps",
        "Construction technology",
        "Cryptocurrency and blockchain",
        "Cybersecurity",
        "Deeptech",
        "Digital health",
        "Ecommerce",
        "Edtech",
        "Ephemeral content",
        "eSports / Sport tech",
        "Fashiontech",
        "Femtech",
        "Fintech",
        "Foodtech",
        "Gaming",
        "Healthtech",
        "HRtech",
        "Impact investing",
        "Industrials",
        "Infrastructure",
        "Insurtech",
        "Internet of Things (IoT)",
        "Legal tech",
        "Life sciences",
        "Lifestyles of Health and Sustainability (LOHAS) and wellness",
        "Logitech / Logistics",
        "Manufacturing",
        "Marketing tech",
        "Micro-mobility",
        "Mobile",
        "Mobile commerce",
        "Mobility tech",
        "Mortgage tech",
        "Nanotechnology",
        "Oil and gas",
        "Oncology",
        "Pet tech",
        "Real estate tech / Proptech",
        "Restaurant tech",
        "Ridesharing",
        "Robotics and drones",
        "Software as a service (SaaS)",
        "Space tech",
        "Supply chain technology",
        "Sustainability",
        "Traveltech",
        "Technology, media and telecommunications (TMT)",
        "Virtual reality (VR)",
        "Wearables and quantified self",
        "3D printing"
    ];

    const investmentStages = [
        "Bootstrapping stage startups",
        "Pre-seed stage startups",
        "Seed stage startups",
        "Growth stage startups",
        "Series A stage startups",
        "Series B stage startups",
        "Series C and beyond stage startups",
    ];

    const investmentRegions = [
        "MENA (Middle East and North Africa)",
        "Africa",
        "South Asia (India, Pakistan, Sri Lanka, Bangladesh)",
    ];

    const investmentRanges = ["$250,000-$500,000 USD", "$500,000-$1,000,000 USD", "$1,000,000-$5,000,000 USD", "$5,000,000-$10,000,000 USD", "$10,000,000+ USD"];

    const handleCheckboxChange = (type, value) => {
        setFormData(prevFormData => {
            const currentValues = prevFormData[type];
            const newValueExists = currentValues?.includes(value);
            let newValues = newValueExists
                ? currentValues.filter(v => v !== value)
                : [...currentValues, value];

            // Enforce maximum selections
            if (type === 'verticals' && newValues.length > 10) {
                alert('You can select up to 10 verticals.');
                return prevFormData;
            } else if (type === 'investmentStage' && newValues.length > 4) {
                alert('You can select up to 4 stages.');
                return prevFormData;
            } else if (type === 'minInvestment' && newValues.length > 3) {
                alert('You can select up to 3 ranges.');
                return prevFormData;
            } else if (type === 'maxInvestment' && newValues.length > 3) {
                alert('You can select up to 3 ranges.');
                return prevFormData;
            }

            return { ...prevFormData, [type]: newValues };
        });
    };

    const handleFileChange = async (e) => {
        const auth_user = await fetchAuthSession();
        const file = e.target.files[0]; // Get the uploaded file
        if (file) {
            try {
                const filename = `${auth_user?.tokens?.accessToken?.payload['username']}/CRD.pdf`; // Unique filename using current timestamp
                uploadData({
                    key: filename,
                    data: file,
                    options: {
                        contentType: file.type,
                        accessLevel: 'guest'
                    }
                });

                // Create a temporary URL for the file for preview purposes
                const previewUrl = URL.createObjectURL(file);

                // Update formData with the object key and preview URL
                setFormData({
                    ...formData,
                    companyRegistration: filename, // Store the filename or objectKey as needed
                    companyRegistrationDocPreview: previewUrl, // New state property for the preview URL
                });

            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        }
    };


    return !isLoading ? (
        <div className="main-container container mt-5 d-flex" style={{ marginBottom: '30%' }}>
            <div className="bg-white mt-2 p-4 rounded flex-fill">
                <h2 className="mt-5 mb-4">Investor Form</h2>
                {((!faceMatchResults || !faceMatched) && !userId) && (
                    <>
                        <h5 className="mb-4">Verifications</h5>
                        <div className="form-group mb-4 d-flex align-items-center">
                            <button onClick={handleVerification} className="btn orange-button">
                                Start Verification
                            </button>

                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip}
                            >
                                <button className="info-circle ms-2">?</button>
                            </OverlayTrigger>
                        </div>

                        <label className="mb-4" htmlFor="capture">Please upload image of your id and then click start camera to use your camera and take picture by clicking capture for face recognization</label>
                        <div className="form-group mb-4">
                            <label htmlFor="image">Please attached copy of your ID or passport</label>
                            <input
                                type="file"
                                className="form-control "
                                id="image"
                                name="image"
                                onChange={handleImageChange}
                            />
                            <img
                                id="imagePreview"
                                src={""}
                                alt="ID or passport Preview"
                                className="image-preview"
                            />
                        </div>
                        {faceMatchResults && (
                            <div className="alert alert-info mb-5" role="alert">
                                {faceMatchResults}
                            </div>
                        )}
                        <CameraCapture onCapture={handleImageCapture} />

                    </>)}
                {(userId || faceMatched && faceMatchResults) && (
                    <div>
                        <div className="form-group mb-4">
                            <label htmlFor="annualIncome">Annual income (range)</label>
                            <select className="form-control" id="annualIncome" name="annualIncome" value={formData.annualIncome} onChange={handleChange}>
                                <option value="">Select range of your annual income</option>
                                <option value="Under $100,000 USD">Under $100,000 USD</option>
                                <option value="$100,000-$500,000 USD">$100,000-$500,000 USD</option>
                                <option value="$500,000-$1,000,000 USD">$500,000-$1,000,000 USD</option>
                                <option value="$1,000,000-$5,000,000 USD">$1,000,000-$5,000,000 USD</option>
                                <option value="$5,000,000+ USD">$5,000,000+ USD</option>
                            </select>
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="companyName">Organization/Company name</label>
                            <input type="text" className="form-control" id="companyName" name="companyName" value={formData.companyName}
                                onChange={handleChange} placeholder="Enter your organization/company name" />
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="registrationLocation">Where your organization/company has been registered (incorporated)? (country and city)</label>
                            <input type="text" className="form-control" id="registrationLocation" name="registrationLocation" value={formData.registrationLocation}
                                onChange={handleChange} placeholder="Enter country and city" />
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="companyRegistrationDoc">Company Registration Document (PDF only)</label>
                            <input
                                type="file"
                                className="form-control"
                                id="companyRegistrationDoc"
                                name="companyRegistrationDoc"
                                onChange={handleFileChange} // You'll implement this function next
                                accept="application/pdf"
                            />
                        </div>

                        {formData.companyRegistrationDocPreview && (
                            <div className="form-group mb-4">
                                <label>Preview:</label><br />
                                <embed src={formData.companyRegistrationDocPreview} width="100%" height="400px" />
                            </div>
                        )}

                        <div className="form-group mb-4">
                            <label htmlFor="website">Website (if any)</label>
                            <input type="text" className="form-control" id="website" name="website" value={formData.website}
                                onChange={handleChange} placeholder="Enter website URL" />
                        </div>

                        <div className="form-group mb-4">
                            <label>Investor Type</label>
                            <select className="form-control" name="investorType" value={formData.investorType} onChange={handleChange}>
                                <option value="">Select Type</option>
                                <option>Accelerator</option>
                                <option>Angel investor</option>
                                <option>Corporate investor</option>
                                <option>Family office</option>
                                <option>Foundation</option>
                                <option>Government investor</option>
                                <option>Hedge fund</option>
                                <option>Incubator</option>
                                <option>Private equity firm</option>
                                <option>Pension fund</option>
                                <option>Sovereign wealth fund</option>
                                <option>Venture capitalist</option>
                            </select>
                        </div>

                        <div className="form-group mb-4">
                            <label>Investment Stages of Interest (max 3)</label>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
                                {investmentStages.map(investmentStage => (
                                    <div key={investmentStage}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`stage-${investmentStage}`}
                                            name="investmentStages"
                                            value={investmentStage}
                                            checked={formData.investmentStage.includes(investmentStage)}
                                            onChange={() => handleCheckboxChange('investmentStage', investmentStage)}
                                        />
                                        <label htmlFor={`stage-${investmentStage}`}>{investmentStage}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label>Investment Verticals(max 10)</label>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                {investmentVerticals.map(vertical => (
                                    <div key={vertical}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={vertical}
                                            name="verticals"
                                            value={vertical}
                                            checked={formData.verticals.includes(vertical)}
                                            onChange={() => handleCheckboxChange('verticals', vertical)}
                                        />
                                        <label htmlFor={vertical}>{vertical}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label>Regions of Interest for Investment (max 2)</label>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                {investmentRegions.map(region => (
                                    <div key={region}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`region-${region}`}
                                            name="regions"
                                            value={region}
                                            checked={formData.regions.includes(region)}
                                            onChange={() => handleCheckboxChange('regions', region)}
                                        />
                                        <label htmlFor={`region-${region}`}>{region}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label>Minimum investment (Please select max. 3)</label>
                            {investmentRanges.map(option => (
                                <div key={option}>
                                    <input
                                        type="checkbox"
                                        name="minInvestment"
                                        value={option}
                                        checked={formData.minInvestment.includes(option)}
                                        onChange={() => handleCheckboxChange('minInvestment', option)}
                                    /> {option}
                                </div>
                            ))}
                        </div>

                        <div className="form-group mb-4">
                            <label>Maximum investment (Please select max. 3)</label>
                            {investmentRanges.map(option => (
                                <div key={option}>
                                    <input
                                        type="checkbox"
                                        name="maxInvestment"
                                        value={option}
                                        checked={formData.maxInvestment.includes(option)}
                                        onChange={() => handleCheckboxChange('maxInvestment', option)}
                                    /> {option}
                                </div>
                            ))}
                        </div>

                        <div className="form-group mb-4">
                            <label>Are you an impact investor/purpose driven investor?</label>
                            <select className="form-control" name="impactInvestor" value={formData.impactInvestor} onChange={handleChange}>
                                <option value="">Select Option</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>

                        <div className="form-group mb-4">
                            <label>Are you interested to invest in:</label>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="interestInShariahStartups"
                                    id="interestInShariahStartups"
                                    checked={formData.interestInShariahStartups}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="interestInShariahStartups">
                                    Shariah compliant tech startups?
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="interestInWomenFoundersStartups"
                                    id="interestInWomenFoundersStartups"
                                    checked={formData.interestInWomenFoundersStartups}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="interestInWomenFoundersStartups">
                                    Tech startups with woman founders?
                                </label>
                            </div>
                        </div>

                    </div>
                )}

            </div>
            <StepsComponent isAdmin={userId ? true : false} isStartup = {false} handleSubmit={handleSubmit} handleDecline={handleDecline} handleAccept={handleAccept} formData={{ ...formData, stage }} steps={steps} />
        </div>
    ) : <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Loading...
    </div>;
}

export default InvestorFormPage;
