import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import logo from '../images/logo.png';
import { fetchAuthSession, fetchUserAttributes, updateUserAttributes, signOut } from 'aws-amplify/auth';
import ContactFormModal from './ContactFormModal';
import { useNavigate } from 'react-router-dom';
import { get } from 'aws-amplify/api';

function NavigationBar({ status }) {

  const [isAdmin, setIsAdmin] = useState(null);
  const [username, setUsername] = useState("");
  const [isInvestor, setIsInvestor] = useState(undefined);
  const [showContactModal, setShowContactModal] = useState(false);
  const [membership, setMembership] = useState(false);
  const [membershipEndDate, setMembershipEndDate] = useState();
  const [canceled, setCanceled] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    async function checkAdminStatus() {
      try {
        const auth_user = await fetchAuthSession();
        const attributes = await fetchUserAttributes();
        const membershipEndDate = attributes['custom:premium'];
        setIsInvestor(attributes['custom:type'] === 'investor');
        if (membershipEndDate) {
          const endDate = new Date(membershipEndDate);
          if (attributes['custom:cancel'] && new Date() >= endDate) {
            await updateUserAttributes({
              userAttributes: {
                'custom:cancel': ''
              }
            });
          } else if (attributes['custom:cancel']) {
            setCanceled(attributes['custom:cancel'])
          }
          const humanReadableEndDate = `${endDate.toLocaleDateString(undefined, { dateStyle: 'medium' })} at ${endDate.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
          setMembershipEndDate(humanReadableEndDate);
          setMembership(new Date() < endDate);
        }
        const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
        setUsername(auth_user?.tokens?.accessToken?.payload['username'])
        setIsAdmin(adminStatus);
      } catch (error) {
        console.error('Error fetching auth session:', error);
      }
    }
    if (status === 'authenticated') {
      checkAdminStatus();
    } else {
      setIsAdmin(false)
    }
  }, [status]);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCancelMembership = async () => {
    const attributes = await fetchUserAttributes();
    const restOperation = get({
      apiName: 'fundiiAPI',
      path: `/services/subscription-cancel?subscription_id=${attributes['custom:subscription']}`
    });
    const { body } = await restOperation.response;
    const response = await body.json();
    if (response) {
      await updateUserAttributes({
        userAttributes: {
          'custom:cancel': 'true'
        }
      });
    }
    handleCloseModal();
  };

  const openContactModal = () => {
    setShowContactModal(true);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/')
  };
  return (
    <Navbar className="fixed-top bg-white pt-5" expand="lg" fixed="top" >
      {isAdmin !== null && (
        <Container>
          <Navbar.Brand href="/">
            <img href="/" src={logo} height="50vh" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {isAdmin && (
                <Nav.Link className="nav-button" href={"/admin/dashboard"}>Home</Nav.Link>
              )}
              <>
                <NavDropdown title={<span className="nav-button">For startups</span>} id="basic-nav-dropdown">
                  {(isInvestor === false) && (
                    <NavDropdown.Item className="nav-items" href="/form/startup">
                      Dashboard
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item className="nav-items" href="/raise-capital">
                    Raise Capital/M&A
                  </NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/due-diligence">
                    Due Diligence
                  </NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/sta-FAQ">
                    FAQs
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown className="nav-button" title={<span className="nav-button">For investors</span>} id="basic-nav-dropdown">
                  {isInvestor && (
                    <NavDropdown.Item className="nav-items" href="/form/investor">
                      Dashboard
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item className="nav-items" href="/investment-opportunities">
                    Investment Opportunities
                  </NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/how-it-works">
                    How it Works
                  </NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/inv-FAQ">
                    FAQs
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown className="nav-button" title={<span className="nav-button">Events & Programs</span>} id="collasible-nav-dropdown">
                  <NavDropdown.Item className="nav-items" href="/fundii-tech-day">fundii Tech Day</NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/startup-awards">fundii Startup Award</NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/fundiiplus">fundii+</NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/fundii-talk">fundii Talk</NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/vertical">Vertical Events</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/news"><span className="nav-button">News</span></Nav.Link>
                <NavDropdown className="nav-button" title={<span className="nav-button">About Us</span>} id="collasible-nav-dropdown">
                  <NavDropdown.Item className="nav-items" href="/story">Our Story</NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" href="/location">Locations</NavDropdown.Item>
                  <NavDropdown.Item className="nav-items" onClick={openContactModal}>
                    Contact
                  </NavDropdown.Item>
                </NavDropdown>
              </>
              {/* {isAdmin && (
                <Nav.Link href="/admin/list">Requests</Nav.Link>
              )} */}
              <div style={{ position: 'absolute', right: '10%' }}>
                {status !== 'authenticated' ? (
                  <>
                    <Button
                      variant="outline-primary"
                      href="/signup"  // Adjust the path as needed
                      style={{ marginRight: '10px' }}
                    >
                      Sign In/Sign Up
                    </Button>
                  </>
                ) : (
                  <>
                    {(!membership && isInvestor && !canceled) && (
                      <Button
                        variant="outline-success"
                        href="/premium"
                        style={{ marginRight: '10px' }}
                      >
                        Become a VIP
                      </Button>
                    )}

                    {
                      (membership && isInvestor && !canceled) && (
                        <Button
                          variant="outline-danger"
                          style={{ marginRight: '10px' }}
                          onClick={handleOpenModal}
                        >
                          Stop Renewal
                        </Button>
                      )
                    }
                    <Button
                      variant="outline-primary"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </Button>
                  </>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
          <ContactFormModal
            show={showContactModal}
            onHide={() => setShowContactModal(false)}
            email="info@fund-ii.com"
          />
        </Container>
      )}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your VIP membership is valid until: {membershipEndDate}</p>
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Please note: Cancelling your membership will immediately revoke your access to premium features. Your current subscription will remain active until the end of the billing period, {membershipEndDate}. No further charges will be made.
          </p>
          <Button variant="danger" onClick={handleCancelMembership}>
            Confirm Cancellation
          </Button>
        </Modal.Body>
      </Modal>

    </Navbar>
  );
}

export default NavigationBar;
