import React, { useEffect, useState } from "react";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { post } from 'aws-amplify/api';
import { loadStripe } from '@stripe/stripe-js';
import { fetchUserAttributes } from 'aws-amplify/auth';

const stripePromise = loadStripe('pk_live_51OadxXKOg6fOsQ4r7sJy0jkNIUaR2mtbQkbh0ZD5P9UpPdUVAcyFZTXP1y1N2ekeS9d4ArqJdyvjINQCuDf9c67d00GDAnIr50');

export default function MembershipPage() {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        async function fetchData() {
            const attributes = await fetchUserAttributes();
            const restOperation = post({
                apiName: 'fundiiAPI',
                path: attributes['custom:free']? '/services/create-checkout-session' : '/services/create-checkout-session-free',
                options: {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            });
            const { body } = await restOperation.response;
            const response = await body.json();

            const clientSecret = response.message;
            
            setClientSecret(clientSecret)
        }
        fetchData();
    }, []);

    return (
        <div className="container" style={{ backgroundColor: 'white', padding: '10%' }}>
                {clientSecret && (
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{ clientSecret }}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                )}
        </div>
    );
}