import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Image, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import raiseImage from '../images/raise.png';
import landImage from '../images/land.png';
import consultationImage from '../images/consultation.png';
import stopImage from '../images/stop.png';
import educateImage from '../images/educate.png';
import glassImage from '../images/glass.png';
import launchImage from '../images/launch.png';
import trophyImage from '../images/trophy.png';
import campaignImage from '../images/campaign.png';
import managerImage from '../images/manager.png';
import localImage from '../images/local.png';
import supportImage from '../images/support.png';
import featuresImage from '../images/features.png';
import howStartupImage from '../images/howStartup.png';
import topImage from '../images/raiseCaptial.jpg';
import './FundiiInfoPage.css';
import { useNavigate } from 'react-router-dom';

const calculateResponsiveSize = () => {
    const width = window.innerWidth;

    const size = Math.floor(600000/width);

    return size;
};

const RaiseCaptialPage = ({ type, handleShowModal }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});

    const [responsiveStyles, setResponsiveStyles] = useState({
        pFontSize: '1rem',
        sectionMarginBottom: '1rem',
    });

    useEffect(() => {
        const handleResize = () => {
            // Call the function with appropriate min and max values
            const pFontSize = calculateResponsiveSize() + 'px'; // Convert pixels to rem if needed
            const sectionMarginBottom = calculateResponsiveSize() + 'px'; // Convert pixels to rem if needed

            setResponsiveStyles({
                pFontSize,
                sectionMarginBottom,
            });
        };

        // Set the initial sizes
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize, { passive: true });

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/2"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/2`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    // This is a handler function to save changes to the state
    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    const TextSection = ({ imageSrc, title, textKey, textValue }) => (
        <Row className="text-section align-items-start">
            <Col xs={2} className="text-center">
                <Image src={imageSrc} fluid style={{ maxWidth: '10vw', maxHeight: '10vh', width: '3.5vw', height: '8vh' }} />
            </Col>
            <Col xs={10}>
                <h3>{title}</h3>
                {isAdmin ? (
                    <Form.Control
                        as="textarea"
                        value={textValue}
                        onChange={(e) => handleTextChange(textKey, e.target.value)}
                        name={textKey}
                    />
                ) : (
                    <p>{textValue}</p>
                )}
            </Col>
        </Row>
    );

    const navigate = useNavigate();

    const handleStartClick = () => {
        if (!type) {
            handleShowModal('startup');
        } else if (type === 'startup') {
            navigate('/form/startup');
        } else if (type === 'investor') {
            alert("You are already registered as an investor.");
        }
    };

    // Render the component
    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }
            <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
                <Carousel.Item>
                    <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topTitle}
                                onChange={(e) => handleTextChange('topTitle', e.target.value)}
                                name={'topTitle'}
                            />
                        ) : (
                            <h1>{texts.topTitle}</h1>
                        )}
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topDescription}
                                onChange={(e) => handleTextChange('topDescription', e.target.value)}
                                name={'topDescription'}
                            />
                        ) : (
                            <h3>{texts.topDescription}</h3>
                        )}
                        <Button className="orange-button" onClick={handleStartClick}>Start Raising/M&A</Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container style={{ marginTop: '10%', marginBottom: responsiveStyles.sectionMarginBottom }}>
                <h1 className="mb-5 text-center">Raise <span className="orange-text">Capital</span></h1>
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <TextSection
                            title="Raise Capital or M&A"
                            textKey="raiseCapitalText"
                            textValue={texts.raiseCapitalText}
                            imageSrc={raiseImage}
                        />
                        <TextSection
                            title="One-on-One Consultation"
                            textKey="consultationText"
                            textValue={texts.consultationText}
                            imageSrc={consultationImage}
                        />
                    </Col>
                    <Col md={6}>
                        <TextSection
                            title="Land the Fund"
                            textKey="landFundText"
                            textValue={texts.landFundText}
                            imageSrc={landImage}
                        />
                        <TextSection
                            title="One-stop-shop Fundraising/M&A"
                            textKey="oneStopShopText"
                            textValue={texts.oneStopShopText}
                            imageSrc={stopImage}
                        />
                    </Col>
                </Row>
            </Container>
            <Container style={{ marginTop: '20%', marginBottom: responsiveStyles.sectionMarginBottom }}>
                <h1 className="mb-5 text-center"><span className="orange-text">How </span>it Works</h1>
                <Row className="justify-content-md-center">
                    <Col style={{ marginTop: '16%' }} md={4}>
                        <img src={howStartupImage} alt="how" className="img-fluid" />
                    </Col>
                    <Col md={8}>
                        <Row className="justify-content-md-center">
                            <Col md={6}>
                                <TextSection
                                    imageSrc={educateImage}
                                    title="Educate"
                                    textKey="educateText"
                                    textValue={texts.educateText}
                                />
                                <TextSection
                                    imageSrc={glassImage}
                                    title="Campaign Preparation"
                                    textKey="campaignPreparationText"
                                    textValue={texts.campaignPreparationText}
                                />
                            </Col>
                            <Col md={6}>
                                <TextSection
                                    imageSrc={launchImage}
                                    title="Launch!"
                                    textKey="launchText"
                                    textValue={texts.launchText}
                                />
                                <TextSection
                                    imageSrc={trophyImage}
                                    title="Land Investment / M&A"
                                    textKey="landInvestmentText"
                                    textValue={texts.landInvestmentText}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container style={{ marginTop: '20%', marginBottom: '10%' }}>
                <h1 className="mb-5 text-center"><span className="orange-text">Features</span></h1>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <Row className="justify-content-md-center">
                            <Col md={6}>
                                <TextSection
                                    imageSrc={campaignImage}
                                    title="Dedicated Campaign Dashboard"
                                    textKey="dedicatedDashboardText"
                                    textValue={texts.dedicatedDashboardText}
                                />
                                <TextSection
                                    imageSrc={localImage}
                                    title="Local & Intl. Fundraising and M&A under one roof"
                                    textKey="fundraisingText"
                                    textValue={texts.fundraisingText}
                                />

                            </Col>
                            <Col md={6}>
                                <TextSection
                                    imageSrc={managerImage}
                                    title="Campaign Manager"
                                    textKey="campaignManagerText"
                                    textValue={texts.campaignManagerText}
                                />
                                <TextSection
                                    imageSrc={supportImage}
                                    title="Support Services"
                                    textKey="supportServicesText"
                                    textValue={texts.supportServicesText}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginTop: '10%' }} md={4}>
                        <img src={featuresImage} alt="features" className="img-fluid" />
                    </Col>
                </Row>
                <Container style={{ marginTop: responsiveStyles.sectionMarginBottom, marginLeft: "0%" }}>
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="card h-100 shadow">
                                <div className="card-header orange-button text-white">
                                    <h3 className="card-title">Startups Fees</h3>
                                </div>
                                <div className="card-body">
                                    <p>For a limited time, enjoy our startup services for free:</p>
                                    <ul>
                                        <li>Listing and administration fee: <s>$150 USD</s> Free</li>
                                        <li>Campaign fee: <s>$100 USD/month</s> (Free)</li>
                                        <li className="indented-text">  60 Days campaign: <s>$200 USD total</s> (Free)</li>
                                        <li className="indented-text">   90 Days campaign: <s>$300 USD total</s> (Free)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default RaiseCaptialPage;
