import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Nav, Col } from 'react-bootstrap';

const AdminDashboard = () => {
    return (
        <div className="d-flex">
            <Col md={2} className="bg-light border-right vh-100 position-fixed" style={{ paddingTop: '56px', height: '100vh', overflowY: 'auto', zIndex: 1000 }}> {/* Fixed sidebar */}
                <div className="sidebar-heading text-center font-weight-bold text-uppercase py-3">
                    Admin Dashboard
                </div>
                <Nav className="flex-column">
                    <Nav.Item>
                        <Nav.Link as={Link} to="startup" className="nav-link text-center">Startups</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="investor" className="nav-link text-center">Investors</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="investment" className="nav-link text-center">Investments</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="subscription" className="nav-link text-center">Subscription</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="reports" className="nav-link text-center">Report and Diagrams</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="courses" className="nav-link text-center">Fundii+</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="users" className="nav-link text-center">Users</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Col>
            <Col md={10} className="offset-md-2 ml-sm-auto col-lg-10 pt-3" style={{ paddingTop: '56px' }}>
                <Outlet />
            </Col>
        </div>
    );
};

export default AdminDashboard;

