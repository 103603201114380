import React, { useState, useEffect } from 'react';
import { Card, ProgressBar, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StartupCard.css';
import ReactCountryFlag from "react-country-flag";
import { getCountryCode } from '../untils/stringUtils'
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { get, post } from 'aws-amplify/api';

function StartupCard({ startup }) {
    const navigate = useNavigate();
    // Calculate the progress percentage
    const progress = (startup.raised / startup.baseTarget) * 100;

    // Extract the country from cityCountryHeadquarters, assuming it's the last word
    const countryCode = getCountryCode(startup.cityCountryHeadquarters);
    const [isAdmin, setIsAdmin] = useState(null);
    const [membership, setMembership] = useState(false);

    const [isInWaitlist, setIsInWaitlist] = useState(false);

    const checkWaitlist = async () => {
        // Assume userAttributes.sub is the investor ID
        const userAttributes = await fetchUserAttributes();
        try {
            const restOperation = get({
                apiName: "fundiiAPI",
                path: `/services/investors/waitlist/check/${userAttributes.sub}/${startup.id}`,
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            setIsInWaitlist(response.message.isInWaitlist);
        } catch (err) {
            console.error("Error checking waitlist:", err);
        }
    };

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const attributes = await fetchUserAttributes();
                const membershipEndDate = attributes['custom:premium'];
                if (membershipEndDate) {
                    const endDate = new Date(membershipEndDate);
                    setMembership(new Date() < endDate);
                }
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkWaitlist();
        checkAdminStatus();

    }, []);


    const handleInvestClick = () => {
        if (isAdmin || membership) {
            navigate("/startup/" + startup.id)
        } else {
            alert("Get VIP membership to access the investment")
        }
    };

    const handleFollow = async () => {
        const waitListData = {
            startupId: startup.id,
            name: startup.title,
            industry: startup.industry,
            description: startup.description,
        };
        const userAttributes = await fetchUserAttributes();

        try {
            const myInit = {
                body: {
                    ...waitListData,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            var restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/investors/waitlist/${userAttributes['sub']}`,
                options: myInit
            });

            const { body } = await restOperation.response;
            const response = await body.json();
            checkWaitlist();
            // Add logic to handle success response, such as updating UI or state
        } catch (err) {
            console.error("Error adding to waitlist:", err);
            // Handle errors here, such as displaying an error message to the user
        }
    };

    const handleDisFollow = async () => {
        const userAttributes = await fetchUserAttributes();

        try {
            const myInit = {
                body: {
                    startupId: startup.id,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            var restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/investors/waitlist/disfollow/${userAttributes['sub']}`,
                options: myInit
            });

            const { body } = await restOperation.response;
            const response = await body.json();

            console.log("Waitlist response:", response.message);
            checkWaitlist();
            // Add logic to handle success response, such as updating UI or state
        } catch (err) {
            console.error("Error adding to waitlist:", err);
            // Handle errors here, such as displaying an error message to the user
        }
    };

    return (
        <Card className="custom-card mb-4">
            <Card.Header as="h5">
                <div className="d-flex justify-content-between">
                    <h5>{startup.title}</h5>
                    {membership && (
                        <Button variant="outline-primary" className="btn-follow" onClick={isInWaitlist ? handleDisFollow : handleFollow}>{isInWaitlist ? 'remove' : 'waitlist'}</Button>
                    )}
                </div>
            </Card.Header>
            <Card.Body>
                <div className="country-flag-with-name">
                    <ReactCountryFlag
                        countryCode={countryCode}
                        svg
                        style={{
                            width: '2em',
                            height: '2em'
                        }}
                        title={startup.cityCountryHeadquarters}
                    />
                    <span>{startup.cityCountryHeadquarters}</span>
                </div>
                <Card.Text>{startup.description}</Card.Text>
                <ProgressBar now={progress} label={`${progress.toFixed(0)}% Funded`} />
                <div className="stats mt-3">
                    <div className="stat-item">
                        <span className="stat-title">Team Size: </span>
                        <span className="stat-value">{startup.fullTimeEmployees} Full-time, {startup.partTimeEmployees} Part-time</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-title">Industry: </span>
                        <span className="stat-value">{startup.industry}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-title">Startup Vertical: </span>
                        <span className="stat-value">{startup.vertical}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-title">Country of Incorporation: </span>
                        <span className="stat-value">{startup.cityCountryIncorporated}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-title">Startup Stage: </span>
                        <span className="stat-value">{startup.startupStage}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-title">Type of Sales: </span>
                        <span className="stat-value">{startup.salesType}</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-title">Year Founded: </span>
                        <span className="stat-value">{startup.foundedYear}</span>
                    </div>
                </div>

                <Card.Footer className="d-flex justify-content-center">
                    <Button onClick={handleInvestClick} className='orange-button'>Invest</Button>
                </Card.Footer>
            </Card.Body>
        </Card>
    );
}

export default StartupCard;
