import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import premiumImage from '../images/premium.jpg'
import { fetchUserAttributes } from 'aws-amplify/auth';

const PremiumPage = () => {
  const navigate = useNavigate();
  const [isInvestor, setIsInvestor] = useState(undefined);
  const [membership, setMembership] = useState(false);
  const [freeTrialUsed, setFreeTrialUsed] = useState();

  useEffect(() => {
    async function checkType() {
      try {
        const attributes = await fetchUserAttributes();
        setIsInvestor(attributes['custom:type'] === 'investor');
        const membershipEndDate = attributes['custom:premium'];
        setFreeTrialUsed(attributes['custom:free']);
        const sessionId = attributes['custom:session'];
        if (membershipEndDate) {
          const endDate = new Date(membershipEndDate);
          setMembership(new Date() < endDate);
        }
      } catch (error) {
        console.error('Error fetching auth session:', error);
      }
    }
    checkType();
  }, []);

  return (
    <>
      <Carousel indicators={false} controls={false}>
        <Carousel.Item>
          <img className="d-block w-100 carousel-image" src={premiumImage} alt={``} />
          <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
            <h1>View & access to complete info of the startups</h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {isInvestor && (
        <div className="container" style={{ marginTop: '10%' }}>
          <div className="row justify-content-center">
            <div className="col-md-5 mb-4">
              <div className="card h-100 shadow">
                <div className="card-header text-white" style={{ backgroundColor: 'rgba(0, 37, 118, 1)' }}>
                  <h3 className="card-title" style={{ textAlign: 'center' }}>Basic Membership</h3>
                </div>
                <div className="card-body d-flex flex-column justify-content-between">
                  <p>
                    Ideal for casual visitors who want a sneak peek into the world of startups.
                    Get access to:
                  </p>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Public startup profiles</li>
                    <li className="list-group-item">Basic Info</li>
                    <li className="list-group-item">Limited search</li>
                  </ul>
                </div>
                <div className="card-footer text-muted" style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#007bff', backgroundColor: '#e7f5ff', border: '2px solid #007bff', borderRadius: '5px', boxShadow: '0px 0px 8px #aaa', textAlign: 'center' }}>
                  Free Access
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-4">
              <div className="card h-100 shadow">
                <div className="card-header bg-dark text-white">
                  <h3 className="card-title" style={{ textAlign: 'center' }}>VIP Membership</h3>
                </div>
                <div className="card-body d-flex flex-column justify-content-between">
                  <p>
                    Designed for professionals seeking in-depth insights and full access to startup information.
                    Benefits include:
                  </p>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Exclusive startup reports</li>
                    <li className="list-group-item">Advanced analytics & trends</li>
                    <li className="list-group-item">Unlimited search</li>
                    <li className="list-group-item">Priority support</li>
                  </ul>
                </div>
                <div
                  className="card-footer"
                  style={{
                    fontSize: '1.2em',
                    fontWeight: 'bold',
                    color: '#28a745',
                    backgroundColor: '#e6f9e6',
                    border: '2px solid #28a745',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 8px #aaa',
                    textAlign: 'center',
                    cursor: 'pointer' // Add cursor pointer to indicate it's clickable
                  }}
                  onClick={() => navigate('/checkout')} // Add the navigate function directly here
                >
                  {freeTrialUsed ? '$399USD/year' : <span><s>$399USD/year</s> Free Trial for 6 Months</span>}
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PremiumPage;
