import React from 'react';
import { Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CourseCard.css';

function CourseCard({ course }) {

    const formattedDate = new Date(course.date).toLocaleDateString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric'
    });

    return (
        <Card className="custom-card-course mb-4">
            <Card.Header as="h5">
                <div className="d-flex justify-content-between">
                    <h5>{course.name}</h5>
                    <span className="course-date">{formattedDate}</span>
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Text><strong>Professor</strong>: {course.professor}</Card.Text>
                <Card.Text><strong>Type</strong>: {course.type}</Card.Text>
                <Card.Text><strong>Language</strong>: {course.language}</Card.Text>
                <Card.Text><strong>Certificate</strong>: {course.certificate}</Card.Text>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
                <Button className='orange-button' href={course.url ? course.url : "https://docs.google.com/forms/d/e/1FAIpQLSd56cntwj768_qzXc6_1VLDMtJQ41Jc-CoB3GrNAPXeI0GkVg/alreadyresponded"} target="_blank">
                    {course.url ? 'Register now' : 'Waitlist'}
                </Button>
            </Card.Footer>
        </Card>
    );
}

export default CourseCard;
