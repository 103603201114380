/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:4158b384-c423-4c31-9f15-9a8bac656300",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_uqbh5OWtL",
    "aws_user_pools_web_client_id": "164k4q7pi725r6s4kkqrmvs330",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "BIRTHDATE",
        "PHONE_NUMBER",
        "ADDRESS"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "investors",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "fundiifiles",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "fundiiAPI",
            "endpoint": "https://n8tckhqcd7.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
