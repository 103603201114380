import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const PrivacyPolicyPage = () => {
    const [marginTop, setMarginTop] = useState('8%');
    const [fontSize, setFontSize] = useState('16px');

    useEffect(() => {
        const handleResize = () => {
            console.log(window.innerWidth)
            const newMarginTop = Math.max(2, 8 * (1920 / window.innerWidth)) + '%';
            setMarginTop(newMarginTop);

            const newFontSize = Math.max(6, 16 * (window.innerWidth / 1240)) + 'px';
            setFontSize(newFontSize);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="container"  style={{ marginTop }}>
            <h3>Privacy Policy</h3>
            <p style = {{ fontSize}}>The privacy of Fundii Financial Solutions Inc. (fundii) users and their information is extremely important to fundii and we take this matter very seriously.</p>
            <p style = {{ fontSize}}>All users of fundii are covered by the following Privacy Policy. fundii reserves the right to update this Privacy Policy. If required to do so, or it is in our best interest, fundii may release user information in compliance with law enforcement agencies.</p>
            <p style = {{ fontSize}}>Persons under the age of 18 are prohibited from using fundii and any associated services. These individuals are therefore not authorized to submit any private information to the system and fundii accepts no liability for such actions.</p>
            <h3>Contact Information</h3>
            <p style = {{ fontSize}}>Upon registering as a fundii user, certain contact information such as your name, email address, and mailing address may be collected. This serves as an example and is not intended to be a complete list of information that may be collected at registration.</p>
            <h3 style = {{ fontSize}}>fund-ii.com</h3>
            <p style = {{ fontSize}}>fund-ii.com is an online service platform that allows tech startups to connect with individuals and accredited investors who are interested in helping to support them by pledging money in exchange for equity upon a successful fundraise or apply for merger & acquisition (M&A). Only users who are manually and through our third-party compliance partners approved will be able to launch a campaign on the site or invest in the startups.</p>
            <h3>System Emails</h3>
            <p style = {{ fontSize}}>As part of the normal functions of the fundii platform, we will send you email regarding your transactions on the site. We will keep these emails limited to a minimum. fundii will send you system generated emails and promotional materials. These communications may begin during the registration process and continue while your account remains on the fundii platform. Startups will receive the email addresses of their backers if the project is successfully funded through a fundii campaign in order to help ensure proper delivery of rewards to backers among other communication. Startups that are not successfully funded will not receive their backers' email addresses as no further communication would be warranted.</p>
            <h3>Internet</h3>
            <p style = {{ fontSize}}>As part of our efforts to provide better services to fundii users, fundii collects certain information related to the Internet and your computer. In order for us to analyze trends and provide superior service to our users, fundii retrieves the web address (URL) of both the website that you visited directly before and directly after visiting the fundii site. We also receive certain other information about your personal computer such as the unique IP address. fundii also follows a standard practice of using cookies and logs to analyze website usage. Cookies are extremely small files that are stored on your computer and help us to provide you with a better experience by automatically recalling your preferences each time you visit our site. Cookies will not harm your computer in any way and can be deleted at any time.</p>
            <h3>Profile</h3>
            <p style = {{ fontSize}}>Following first stage registration, fundii users will be prompted to complete a profile. Current users can also make changes to their own user profile. A user profile may contain, but is not limited to, your name, a personal statement, your work experience, goals, etc. You should use good judgment about the information that you post in your profile since this can be viewed by others on the fundii platform. Your personal contact information CANNOT be viewed unless you release this information. Additionally, the information in your profile can be edited at your discretion at any time.</p>
            <h3>Account Preferences</h3>
            <p style = {{ fontSize}}>You may make changes to your fundii account and any associated preferences at any time. To make changes, you must log onto the fundii and make any edits through the Dashboard section. You may also request to terminate your fundii account. To request a removal of your account, please contact us.</p>
            <h3>Startups and Investors</h3>
            <p style = {{ fontSize}}>By entering into our User Agreement, fundii Startups and Investors agree to not abuse other users' personal information. Abuse is defined as using personal information for any purpose other than those explicitly specified in the Startup’s Project, or is not related to fulfilling delivery of a product or service explicitly specified in the Project Creator’s Project.</p>
            <h3>Affiliate Services</h3>
            <p style = {{ fontSize}}>Stripe processes VIP membership for investors and subscription/listing fee for the startups of the transactions on fundii. No one sees your credit card information besides Stripe, not even us.</p>
            <h3>Questions</h3>
            <p style = {{ fontSize}}>If you have a question about this privacy policy, please contact us at: info@fund-ii.com</p>
        </div>
    );
};

export default PrivacyPolicyPage;
