import React, { useState, useEffect } from 'react';
import { Pagination, Carousel, Form, Button } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import CourseCard from '../components/CourseCard' // Assuming CourseCard is similar to StartupCard
import 'bootstrap/dist/css/bootstrap.min.css';
import { convertKeysToCamelCase } from '../untils/stringUtils';
import topImage from '../images/plus.jpg';
import fundiiImage from '../images/fundii+.png';
import underImage from '../images/fundii+under.png';
import { fetchAuthSession } from 'aws-amplify/auth';

function FundiiCoursesPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [coursesPerPage] = useState(3);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/courses"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                const camelCaseData = convertKeysToCamelCase(response.message);
                if (camelCaseData && camelCaseData.length > 0) {
                    setFilteredCourses(camelCaseData);
                } else {
                    setFilteredCourses([]);
                }
            } catch (err) {
                console.log(`Error fetching courses: `, err);
            }
        }

        fetchData();
    }, []);

    async function fetchData() {
        try {
            const restOperation = get({
                apiName: 'fundiiAPI',
                path: "/services/courses"
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            const camelCaseData = convertKeysToCamelCase(response.message);
            if (camelCaseData && camelCaseData.length > 0) {
                setFilteredCourses(camelCaseData);
            } else {
                setFilteredCourses([]);
            }
        } catch (err) {
            console.log(`Error fetching courses: `, err);
        }
    }

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/11"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/11`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    // This is a handler function to save changes to the state
    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    // Pagination logic
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(filteredCourses?.length / coursesPerPage);
    const indexOfLastCourse = currentPage * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);

    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }
            <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
                <Carousel.Item>
                    <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className="container">
                <header className="text-center">
                    <img style={{ maxHeight: '25vh', maxWidth: '100%' }} className="img-fluid" src={fundiiImage} alt="" />
                </header>
                <div className="text-center">
                    {isAdmin ? (
                        <Form.Control
                            as="textarea"
                            value={texts.fundiiplusDescription || ''}
                            onChange={(e) => handleTextChange('fundiiplusDescription', e.target.value)}
                        />
                    ) : (
                        <p>{texts.fundiiplusDescription}</p>
                    )}
                    {isAdmin ? (
                        <Form.Control
                            as="textarea"
                            value={texts.fundiiplusSecondDescription || ''}
                            onChange={(e) => handleTextChange('fundiiplusSecondDescription', e.target.value)}
                        />
                    ) : (
                        <p style={{ marginTop: '5%' }}> {texts.fundiiplusSecondDescription}</p>
                    )}
                     <img style={{ maxHeight: '40vh', maxWidth: '100%', marginTop: '5%' }} className="img-fluid" src={underImage} alt="" />
                </div>
                <div className="container" style={{ marginTop: '15%', marginLeft: 0 }}>
                    <div className="row">
                        {currentCourses.map((course, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <CourseCard course={course} updateCourses={fetchData} />
                            </div>
                        ))}
                    </div>

                    <Pagination className="justify-content-center">
                        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        {[...Array(totalPages).keys()].map(number => (
                            <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handlePageChange(number + 1)}>
                                {number + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
            </div>
        </>
    );
}

export default FundiiCoursesPage;
