import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import './FundiiInfoPage.css';
import fundiiImage from '../images/fundiitalk.png';
import ImageCards from '../components/ImageCards';

// Arrays with the image file paths for each season
const season1Images = [
  'season 1/1.png',
  'season 1/2.png',
  'season 1/3.png',
  'season 1/4.jpg',
  'season 1/5.png',
  'season 1/6.png',
  'season 1/7.png',
  'season 1/8.png',
];

const season2Images = [
  'season 2/1.png',
  'season 2/2.png',
  'season 2/3.png',
  'season 2/4.png',
  'season 2/5.png',
];

const FundiiTalk = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [texts, setTexts] = useState({});

  useEffect(() => {
    async function fetchTextData() {
      try {
        const restOperation = get({
          apiName: 'fundiiAPI',
          path: "/services/texts/7"
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.message) {

          setTexts(response.message);
        } else {
          setTexts([]);
        }
      } catch (err) {
        console.log(`Error fetching texts: `, err);
      }
    }

    fetchTextData();
  }, []);

  useEffect(() => {
    async function checkAdminStatus() {
      try {
        const auth_user = await fetchAuthSession();
        const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
        setIsAdmin(adminStatus.includes('admin'));
      } catch (error) {
        console.error('Error fetching auth session:', error);
      }
    }

    checkAdminStatus();
  }, []);

  // Handle submission of changes
  const handleSubmit = async () => {

    try {
      const myInit = {
        body: {
          ...texts,
          id: undefined,
        },
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${session.idToken.jwtToken}`
        }
      }

      const restOperation = post({
        apiName: "fundiiAPI",
        path: `/services/texts/7`,
        options: myInit
      });
      const { body } = await restOperation.response;
      const response = await body.json();

    } catch (err) {
      console.log(err)
      return false;
    }
  };

  return (
    <>
      {isAdmin &&
        <Button
          style={{
            position: 'fixed',
            top: '0',
            marginTop: '6%',
            width: '100%',
            zIndex: 1
          }}
          variant="primary"
          onClick={handleSubmit}>
          Change
        </Button>
      }
      <Container style={{ marginTop: '10%' }}>
        <header className="text-center">
          <img style={{ maxHeight: '15vh', maxWidth: '100%' }} className="img-fluid" src={fundiiImage} alt="" />
        </header>
        <ImageCards images={season1Images} texts={texts} isAdmin={isAdmin} setTexts={setTexts} title="fundiiTalk Season 1" group='firstSeason' type={"fundiitalk"} />
        <ImageCards images={season2Images} texts={texts} isAdmin={isAdmin} setTexts={setTexts} title="fundiiTalk Season 2" group='secondSeason' type={"fundiitalk"} />
      </Container>
    </>
  )
};

export default FundiiTalk;
