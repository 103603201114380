// RegionalImageCarousels.js
import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import ImageCards from '../components/ImageCards';
import './FundiiInfoPage.css';

// Arrays with the image file paths for each region
const africaImages = [
  'Africa cities/13.jpg',
  'Africa cities/14.jpg',
  'Africa cities/15.jpg',
  'Africa cities/16.jpg',
  'Africa cities/17.jpg',
];

const menaImages = [
  'MENA cities/1.jpg',
  'MENA cities/2.jpg',
  'MENA cities/3.jpg',
  'MENA cities/4.jpg',
  'MENA cities/5.jpg',
  'MENA cities/6.jpg',
  'MENA cities/7.jpg',
  'MENA cities/8.jpg',
  'MENA cities/9.jpg',
  'MENA cities/10.jpg',
  'MENA cities/11.jpg',
  'MENA cities/12.jpg',
];

const southAsiaImages = [
  'South Asia cities/18.jpg',
  'South Asia cities/19.jpg',
  'South Asia cities/20.jpg',
  'South Asia cities/21.jpg',
  'South Asia cities/22.jpg',
  'South Asia cities/23.jpg',
  'South Asia cities/24.jpg',
  'South Asia cities/25.jpg',
];

const FundiiTechDay = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [texts, setTexts] = useState({});

  useEffect(() => {
    async function fetchTextData() {
      try {
        const restOperation = get({
          apiName: 'fundiiAPI',
          path: "/services/texts/13"
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        if (response.message) {
          setTexts(response.message);
        } else {
          setTexts([]);
        }
      } catch (err) {
        console.log(`Error fetching texts: `, err);
      }
    }

    fetchTextData();
  }, []);

  useEffect(() => {
    async function checkAdminStatus() {
      try {
        const auth_user = await fetchAuthSession();
        const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
        setIsAdmin(adminStatus.includes('admin'));
      } catch (error) {
        console.error('Error fetching auth session:', error);
      }
    }

    checkAdminStatus();
  }, []);

  const handleSubmit = async () => {
    try {
      const myInit = {
        body: {
          ...texts,
          id: undefined,
        },
        headers: {
          'Content-Type': 'application/json',
        }
      }
      const restOperation = post({
        apiName: "fundiiAPI",
        path: `/services/texts/13`,
        options: myInit
      });
      const { body } = await restOperation.response;
      const response = await body.json();
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <>
      {isAdmin &&
        <Button
          style={{
            position: 'fixed',
            top: '0',
            marginTop: '6%',
            width: '100%',
            zIndex: 1
          }}
          variant="primary"
          onClick={handleSubmit}
        >
          Change
        </Button>
      }
      <Container style={{ marginTop: '10%' }}>
        <div className="my-4">
          <div className="video-responsive-container">
            <iframe
              className="video-responsive-iframe"
              src="https://www.youtube.com/embed/AIUzwVYpztc"
              title="YouTube video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <ImageCards images={africaImages} texts={texts} isAdmin={isAdmin} title="Africa" setTexts={setTexts}  />
        <ImageCards images={menaImages} texts={texts} isAdmin={isAdmin} title="MENA" setTexts={setTexts} />
        <ImageCards images={southAsiaImages} texts={texts} isAdmin={isAdmin} title="SouthAsia" setTexts={setTexts} />
      </Container>
    </>
  );
};

export default FundiiTechDay;
