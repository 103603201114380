import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Image, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import collectionImage from '../images/document collection.png';
import contractImage from '../images/contract.png';
import reviewImage from '../images/review.png';
import riskImage from '../images/risk analysis.png';
import topImage from '../images/due.jpg';
import { useNavigate } from 'react-router-dom';
import './FundiiInfoPage.css';

const DueDiligencePage = ({type, handleShowModal}) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/3"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/3`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    // This is a handler function to save changes to the state
    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    const TextSection = ({ imageSrc, title, textKey, textValue }) => (
        <Row className="text-section align-items-start mt-3">
            <Col xs={2} className="text-center">
                <Image src={imageSrc} fluid />
            </Col>
            <Col xs={10}>
                <h3>{title}</h3>
                {isAdmin ? (
                    <Form.Control
                        as="textarea"
                        value={textValue}
                        onChange={(e) => handleTextChange(textKey, e.target.value)}
                        name={textKey}
                    />
                ) : (
                    <p>{textValue}</p>
                )}
            </Col>
        </Row>
    );

    const navigate = useNavigate();

    const handleGetStarted = () => {
        if (!type) {
            handleShowModal('startup');
        } else if (type === 'startup') {
            navigate('/form/startup');
        } else if (type === 'investor') {
            alert("You are already registered as an investor.");
        }
    };

    // Render the component
    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }
            <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
                <Carousel.Item>
                <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topDescription || ''}
                                onChange={(e) => handleTextChange('topDescription', e.target.value)}
                            />
                        ) : (
                            <h2>fundii's unique way to help you land investment</h2>
                        )}
                        <Button variant="primary" className="orange-button" onClick={handleGetStarted}>Get Started</Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container style={{ marginTop: '10%', marginBottom : '20%' }}>
                <h1 className="mb-5 text-center"><span className="orange-text">Due Diligence</span> Process</h1>
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.processDescription || ''}
                                onChange={(e) => handleTextChange('processDescription', e.target.value)}
                            />
                        ) : (
                            <p className="text-center" style={{ marginBottom : '10%' }}> {texts.processDescription} </p>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <TextSection
                            imageSrc={collectionImage}
                            title="Document Collection"
                            textKey="documentCollection"
                            textValue={texts.documentCollection}
                        />
                        <TextSection
                            imageSrc={contractImage}
                            title="Documents & Contracts"
                            textKey="documentsAndContracts"
                            textValue={texts.documentsAndContracts}
                        />
                    </Col>
                    <Col md={6}>
                        <TextSection
                            imageSrc={reviewImage}
                            title="Final Review"
                            textKey="finalReview"
                            textValue={texts.finalReview}
                        />
                        <TextSection
                            imageSrc={riskImage}
                            title="Project & Risk Analysis"
                            textKey="projectAndRiskAnalysis"
                            textValue={texts.projectAndRiskAnalysis}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DueDiligencePage;
