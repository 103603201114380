import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const TermsOfUsePage = () => {
    const [marginTop, setMarginTop] = useState('8%');
    const [fontSize, setFontSize] = useState('16px');

    useEffect(() => {
        const handleResize = () => {
            // Calculate new margin top based on the screen width
            const newMarginTop = Math.max(2, 8 * (1920 / window.innerWidth)) + '%';
            setMarginTop(newMarginTop);

            // Adjust font size based on the screen width
            const newFontSize = Math.max(6, 16 * (window.innerWidth / 1240)) + 'px';
            setFontSize(newFontSize);
        };

        // Set initial styles
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="container" style={{ marginTop }}>
            <h1>Terms of Use</h1>
            <p style = {{ fontSize}}>By using this website, you agree to the following terms of use:</p>

            <h3>1. Introduction</h3>
            <p style = {{ fontSize}}>The following terms and conditions (the “Terms of Use”) are the Terms of Use for our website: www.fund-ii.com (the “Site/Website”) made available by Fundii Financial Solutions Inc. (“fundii”, “we”, “us” or “our”).</p>
            <p style = {{ fontSize}}>The contents of the Site include, without limitation, all information, data, products, materials, services, software applications and tools, design elements, text, images, photographs, illustrations, audio and video contents, artwork, graphics contained therein or otherwise made available to you in connection therewith (collectively the “Contents”) and, unless the context clearly requires otherwise, or we explicitly say so in writing, the term “Site” includes all of the Contents.</p>
            <p style = {{ fontSize}}>These Terms of Use constitute a legally binding agreement between you and fundii regarding your use of and access to the Site. You acknowledge and agree that fundii may, in its sole discretion, change, improve and/or correct any functionality or Contents of the Site and to suspend and/or deny access to the Site for any reason, without any obligation and without any notice.</p>
            <p style = {{ fontSize}}>These Terms of Use do not alter in any way the terms and conditions of any other agreement you may have with fundii, unless otherwise agreed to in writing by fundii. If you breach any of these Terms of Use, your authorization to use the Site automatically terminates and you must immediately destroy any Contents in your possession and discontinue all use of the Site.</p>
            <p style = {{ fontSize}}>By using this Site, you represent and warrant that you are at least the age of majority in your country or territory of residence, or in any event, at least eighteen (18) years of age.</p>
            <h3>2. Access to The Website</h3>
            <p style = {{ fontSize}}>Access to the Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the Services we provide without notice. We will not be liable if for any reason the Website is unavailable at any time or for any period. From time to time, we may restrict your access to some or all of the Website. You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of these terms, and that they comply with them. We aim to update the Website regularly and may change the content at any time. If the need arises, we may suspend access to the Website, or close it indefinitely. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>

            <h3>3. Accepting the Terms of Use and Additional Terms</h3>
            <p style = {{ fontSize}}>By accessing and using the Site in any manner, you acknowledge that you have read these Terms of Use and all the terms and conditions contained herein. Before continuing to use the Site, please read these Terms of Use and contact us if you have any questions.</p>
            <p style = {{ fontSize}}>The Additional Privacy Policy (located on the Site) are hereby incorporated by reference into these Terms of Use and form part of the legally binding agreement between you and us. To the extent that there is a conflict between these Terms of Use and any Additional Terms, the Additional Terms shall govern. These Terms of Use will remain in full force and effect if you are a user of the Site and, in the event of termination of any product, service or feature, you will still be bound by your obligations under these Terms of Use, the Privacy Policy, and any Additional Terms.</p>
            <h3>4. Your Information</h3>
            <p style = {{ fontSize}}>You agree that the information you provide during the fundii registration process is accurate, complete, currant and truthful, and you will regularly update/inform us about this information to maintain its completeness and accuracy. You are responsible for maintaining the confidentiality of any account information that you use to access any feature on the Website, and also for logging off of your Account and any protected areas of the Website. Additionally, you are fully responsible for all activities occurring under your Account that result from your failure to use or maintain appropriate security measures. If you become aware of any suspicious or unauthorised conduct concerning your Account, you agree to contact fundii immediately. fundii will not be liable for any loss or damage arising from your failure to promptly notify us of such conduct.</p>

            <h3>5. Verification</h3>
            <p style = {{ fontSize}}>Anti-money laundering (AML) regulations and Know Your Client (KYC) required for both startups and investors, and we obtain, verify and record information that identifies persons, entities or corporations seeking to open Accounts with us through our third-party compliance partners. You agree to provide fundii/ third-party compliance partner with all required information or documentation that permits us or agents acting on our behalf to perform Know Your Client (KYC) checks on you. Any required information you provide to fundii may be subject to verification, including through the sharing of such information with third parties for this purpose. Your Account may be rejected, restricted, or closed if fundii cannot verify required information.</p>

            <h3>6. U.S. and UN Economic Sanctions</h3>
            <p style = {{ fontSize}}>We do not accept any startups or investors to register/use our Website from countries under sanction or related to sanctioned countries/ organizations. You represent that you, or the organisation for which you are acting as an authorised person, have not been designated by the U.S. Department of Treasury's Office of Foreign Assets Control (OFAC) or the United Nations (UN) as a Specially Designated National or Blocked Person, you have no reason to believe that you would be considered a Blocked Person by OFAC or the UN, and you do not reside in a country that is subject to OFAC or UN sanctions. You also represent that, to the best of your knowledge, you are not employed by or acting as agent of any government, government-controlled entity or government corporation restricted under OFAC or the UN sanctions regimes. You understand that if your application violates OFAC or UN sanctions guidelines, your application/account will be closed/declined. </p>

            <h3>7. Restricted Activities</h3>
            <p style = {{ fontSize}}>In connection with your use of the Website, your Account, or the Services, or in the course of your interactions with fundii, you agree that you will not:
                <ul>
                    <li> Breach these Terms of Use or any other agreement that you have entered into with fundii (including any fundii policies)</li>
                    <li> Provide false, inaccurate or misleading Information</li>
                    <li>  Infringe fundii's or any third party's copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy</li>
                    <li>  Post comments that are false, inaccurate, misleading, defamatory, or contain libellous content</li>
                    <li>  Violate any law, statute, ordinance, or regulation</li>
                    <li>   Act in a manner that is defamatory, trade libellous, unlawfully threatening or unlawfully harassing</li>
                    <li>   Post comments containing personal information, including without limitation, telephone numbers, street addresses, and last names</li>
                    <li>   Pay for your transactions with fraudulent funds or with what we reasonably believe to be potentially fraudulent funds</li>
                    <li>   Refuse to cooperate in an investigation or provide confirmation of your identity or any information you provide to us</li>
                    <li>   Use an anonymizing proxy.</li>
                    <li>   Control an Account that is linked to another Account that has engaged in any of these restricted activities. We may use evidence other than the Account information to determine whether you control an Account in someone else's name, including but not limited to IP addresses, common business names, phone numbers and mailing addresses</li>
                    <li>   Use the Services in a manner that results in or may result in complaints, disputes, claims, fees, fines, penalties and other liability to fundii or you.
                    </li>
                </ul>
            </p>

            <h3>8. Actions We May Take</h3>
            <p style = {{ fontSize}}>If you engage in any restricted activities, we may take various actions to protect fundii from claims, fees, fines, penalties and any other liability. The actions we may take include but are not limited to the following:
                <ul>
                    <li>We may close, suspend, or place restrictions on your fundii Account</li>
                    <li>We may update inaccurate information you provided us</li>
                    <li> We may remove user comments that do not comply with these Terms of Use</li>
                    <li> We may refuse to provide the Services to you in the future</li>
                    <li> We may take legal action against you</li>
                </ul>
                Whether we decide to take any of the above steps, remove content, or refuse to provide Services, we do not monitor, and you agree we will not be subject to liability for monitoring, the Website.
            </p>

            <h3>9. Account Suspension or Restriction</h3>
            <p style = {{ fontSize}}>fundii, in its sole discretion, reserves the right to terminate these Terms of Use or access to the Website or the Services. We also reserve the right to suspend or place restrictions on your Account for any reason and at any time upon notice to you. Account restrictions may include a limitation on the dollar amount of transactions we will accept from your Account. If your Account is suspended, you will be unable to list pitches or make commitments and we will not issue any payments to you. If we suspend or place restrictions on your Account, we will provide you with notice and opportunity to request reconsideration, if appropriate.</p>

            <h3>10. Links to Third-Party Sites</h3>
            <p style = {{ fontSize}}>Use of certain links on the Site will direct you away from the Site to third party websites. Such third-party websites are not under the control of fundii, and fundii is not responsible for the contents of any such website or any link contained in such website. The third-party links included on the Site are provided for your convenience, and the inclusion of such links does not imply a recommendation or endorsement by fundii of any such website or the products or services offered therein. If you decide to access any of the third-party websites linked to the Site, you do so entirely at your own risk. You acknowledge and agree that fundii shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by, or in connection with the use of or reliance on any such third-party websites.</p>

            <h3>11. Third-Party Data</h3>
            <p style = {{ fontSize}}>To the fullest extent permitted under applicable law, fundii makes no representation or warranty, express or implied, with respect to any third-party data provided to fundii or its transmission, timeliness, accuracy or completeness, including but not limited to implied warranties or warranties of merchantability or fitness for a particular purpose. fundi will not be liable in any way to you or to any other person for any inaccuracy, error or delay in or omission of any third party data or the transmission or delivery of any such third party data and any loss or damage arising from (i) any such inaccuracy, error, delay or omission, (ii) third-party non-performance, or (iii) interruption in any such third party data due either to any negligent act or omission by fundii or "force majeure" or any other cause beyond reasonable control of the fundii.</p>

            <h3>12. Closing Your Account</h3>
            <p style = {{ fontSize}}>You may ask fundii to close your Account by emailing info@fund-ii.com. Closure of your Account will not affect any commitments to investments that you may have made or any pitches that you may have posted on our Website and the terms and conditions relevant to such commitments or pitches will remain in effect.</p>

            <h3>13. Investor Disclaimer</h3>
            <p style = {{ fontSize}}>Investing in start-ups involves risk, including illiquidity, lack of dividends, loss of investment and dilution and it should be done as part of a diversified portfolio. Each investor on the fundii platform invests on the acceptance that selling such investments at a later date may incur difficulty, including obtaining a reasonable price and, in some cases, any price. Participation in any type of investment activity may expose you to significant risk and you may lose all of your investment. We recommend each investor conducts their own due diligence to assess whether each investment opportunity is suitable for them. Please read FAQ in “For Investors” section and familiarize yourself with the risks involve in investing in startups. </p>

            <h3>14. No Warranty</h3>
            <p style = {{ fontSize}}>You expressly understand and agree that your use of the site is at your sole risk and that the sire and the contents are provided “as is” and “as available. In particular, fundii do not represent or warrant to you that:


                Your use of the site will meet your requirements, your use of the sire will be uninterrupted, timely, secure, or free from error, any information obtained by you as a result of your use of the site will be accurate reliable, and that defects in the operation or functionality of any software provided to you as part of the site will be corrected. Any material downloaded or otherwise obtained through the use of the site is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or other deice or loss of data that results from the download of any such materials. No advice or information, whether oral or written, obtained by you from fundii through or from the site shall create any warranty not expressly stated in these terms of use. fundii further expressly disclaims all warranties and conditions of any kind, whether express or implied, including but not limited to the implied warranties and conditions of merchantability, fitness for a particular purpose and non-infringement.
            </p>

            <h3>15. Our Liability</h3>
            <p style = {{ fontSize}}>The material displayed on the Website is provided without any guarantees, conditions, or warranties as to its accuracy. To the extent permitted by law, we, our affiliates and third parties connected to us hereby expressly exclude:
                <ul>
                    <li>All conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity</li>
                    <li>   Any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with the Website site or in connection with the use, inability to use, or results or the use of the Website, any websites linked to it and any materials posted on it, including, without limitation any liability for:
                    </li>
                    <ul>
                        <li> Loss of income or revenue;</li>
                        <li>Loss of business;</li>
                        <li>Loss of profits or contracts;</li>
                        <li> Loss of anticipated savings;</li>
                        <li> Loss of data;</li>
                        <li> Loss of goodwill;</li>
                        <li> Wasted management or office time;</li>
                        <li> and for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable. This does not affect our liability for death or personal injury arising from our negligence, nor our liability for fraud or fraudulent misrepresentation, nor any other liability which cannot be excluded or limited under applicable law.
                        </li>
                    </ul>
                </ul>
            </p>

            <h3>16. Your Liability</h3>
            <p style = {{ fontSize}}>You are responsible for all claims, fees, fines, penalties and other liability incurred by fundii or any third party caused by or arising out of your breach of these Terms of Use or your use of the Services. You agree to reimburse fundii or any third party for any and all such liabilities.</p>

            <h3>17. Electronic Signatures</h3>
            <p style = {{ fontSize}}>By completing a fundii Account online application, you give your valid consent to these Terms of Use and all other documents governing your relationship with fundii. The use of an electronic version of the Account documents fully satisfies any requirement that they be provided to you and/or signed in writing, and the electronic version of these Terms of Use and any other agreement is considered to be the true, complete and enforceable record of our agreement, admissible in judicial or administrative proceedings to the same extent as if the documents and records were originally generated and maintained in printed form. You are solely responsible for reviewing and understanding all of the terms and conditions of these documents, and you accept as reasonable and proper notice, for the purpose of any laws, rules and regulations, notice by electronic means. You may access and retain a record of the documents you electronically sign through www.fund-ii.com.</p>

            <h3>18. Electronic Delivery of Documents</h3>
            <p style = {{ fontSize}}>You agree that fundii will provide you with an electronic copy of all documents and communications related to your Account. When documents related to your fundii Account are available, we will send a notice to the email address you have provided, and you will be able to view the documents at any time by visiting www.fund-ii.com and signing in to your Account.</p>

            <div>
                <h3>19. Access and Interference</h3>
                <p style = {{ fontSize}}> Much of the information on our Website is updated on a real-time basis and is proprietary or is licensed to fundii by third parties. You agree that you will not:
                <ul>
                    <li>Copy, reproduce, modify, create derivative works from, distribute, or publicly display any content from the Website without the prior expressed written permission of fundii and the appropriate third party, as applicable.</li>
                    <li>Use any robot, spider, scraper or other automated means to access the Website for any purpose without our express written permission.</li>
                    <li>Take any action that may cause us to lose any of the services from our internet service providers, payment processors, or other suppliers.</li>
                    <li>Facilitate any viruses, Trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, or surreptitiously intercept or expropriate any system, data or information.</li>
                    <li>Interfere or attempt to interfere with the proper working of the Website or any activities conducted on the Website.</li>
                    <li>Take any action that may impose (in our sole judgment) an unreasonable or disproportionately large load on our infrastructure.</li>
                    <li>Use any device, software or routine to bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Website.</li>
                    <li>Attempt to obtain unauthorized access to any features of this website, or to any other protected materials or information, through any means not intentionally made available to you by fundii.</li>
                </ul>
                </p>
            </div>


            <h3>20. Processing of Payments</h3>
            <p style = {{ fontSize}}>fundii will make reasonable efforts to ensure that requests for payment processing are processed in a timely manner through our third-party partners (wire and crypto - stablecoin), but fundii makes no representations or warranties regarding the amount of time needed to complete processing because our Services are dependent upon many factors outside of our control, such as delays in the banking system.</p>

            <h3>21. Indemnification</h3>
            <p style = {{ fontSize}}>You agree to defend, indemnify and hold fundii and its officers, directors, agents and employees harmless from any claim or demand (including attorneys' fees) made or incurred by any third party due to or arising out of your breach of these Terms of Use and/or your use of the Services.</p>

            <h3>22. Intellectual Properties Rights</h3>
            <p style = {{ fontSize}}>We the owner or the licensee of all intellectual property rights in the Website, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You must not print off copies or download any extracts from any part of the Website unless expressly authorised by us to do so. If you print off, copy or download any part of our Website in breach of these Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
            <p style = {{ fontSize}}>"fundii" and "fund-ii.com" are trademarks and trade names of Fundii Financial Solutions Inc.. All page headers, logos, graphics and icons are protected to the extent allowed under applicable laws. All other designated trademarks and brands are the property of their respective owners. You may not copy, imitate or use any of fundii's intellectual property without our prior written consent.</p>
            <p style = {{ fontSize}}>You may not allow any third party (whether or not for your benefit) to reproduce, modify, create derivative works from, display, perform, publish, distribute, disseminate, broadcast or circulate to any third party (including, without limitation, on or via a third party website), or otherwise use, any Contents without the express prior written consent of fundii or its owner if fundii is not the owner. Any unauthorized or prohibited use of any Contents may subject you to civil liability, criminal prosecution, or both, under applicable country, federal, provincial, state and local laws.The limited rights granted to you under these Terms of Use may be revoked by fundii at any time for any reason whatsoever.</p>

            <h3>23. Changes To The Terms of Use </h3>
            <p style = {{ fontSize}}>We may revise or amend these Terms of Use at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are binding on you.</p>
            <h3>Contact Us</h3>
            <p style = {{ fontSize}}>If you have any questions or concerns about material which appears on our website or these Terms of Use, please contact us at info@fund-ii.com  </p>
            <p style = {{ fontSize}}>Last updated: 01 February 2024</p>
        </div>
    )
};

export default TermsOfUsePage;
