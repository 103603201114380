import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Carousel } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import topImage from '../images/award.jpg';
import './FundiiInfoPage.css';


const images = [
    { name: 'MENA', filename: 'MENA.png' },
    { name: 'Africa', filename: 'Africa.png' },
    { name: 'SouthAsia', filename: 'SOUTH ASIA.png' },
];

const FundiiStartupAwards = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/9"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/9`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    // This is a handler function to save changes to the state
    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }
            <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
                <Carousel.Item>
                    <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topDescription}
                                onChange={(e) => handleTextChange('topDescription', e.target.value)}
                                name={'topDescription'}
                            />
                        ) : (
                            <h1 style={{ color: 'black' }}>{texts.topDescription}</h1>
                        )}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container className="mt-5">
                <Row>
                    {images.map((image, index) => (
                        <Col key={index} xs={12} className="mt-5">
                            <img
                                src={require(`../images/${image.filename}`)}
                                alt={image.name}
                                className="img-fluid"
                                style={{ width: '85%', height: 'auto', display: 'block', margin: 'auto', marginTop: '5%' }}
                            />
                            {isAdmin ? (
                                <Form.Control
                                    as="textarea"
                                    value={texts[`${image.name}Description`] || ''}
                                    onChange={(e) => handleTextChange(`${image.name}Description`, e.target.value)}
                                    placeholder={`Text box for info about ${image.name}`}
                                />
                            ) : (
                                <p style={{ textAlign: 'center', marginTop: '2%' }}>{texts[`${image.name}Description`] || `Text box for info about ${image.name}`}</p>
                            )}
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
};

export default FundiiStartupAwards;
