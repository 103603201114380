import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'aws-amplify/api';
import { Card, Button, Col, Row, Tab, Table, Tabs, ListGroup } from 'react-bootstrap';
import { convertKeysToCamelCase } from '../untils/stringUtils';
import { useNavigate } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import profileImage from '../images/profile.jpg';
import './StartupDashboard.css';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

function InvestorDashboard() {
    const navigate = useNavigate();
    const { investorId } = useParams();
    const [investor, setInvestor] = useState(null);
    const [investorData, setInvestorData] = useState(null);
    const [investments, setInvestments] = useState(null);
    const [key, setKey] = useState('waitList');
    const [timeFrame, setTimeFrame] = useState('monthly');

    const [aggregatedInvestments, setAggregatedInvestments] = useState([]);
    const [aggregatedEarnings, setAggregatedEarnings] = useState([]);
    const [displayedInsights, setDisplayedInsights] = useState(["Tech industry is growing rapidly in Asia.",
        "Renewable energy startups are attracting more investments.",
        "E-commerce platforms are experiencing a surge in user base post-pandemic.",
        "Telemedicine services are gaining traction due to increased health awareness.",
        "FinTech innovations are disrupting traditional banking services."]);
    const investmentsData = [
        { date: '2023-10-23', investmentCount: 2 },
        { date: '2023-10-24', investmentCount: 3 },
        { date: '2023-10-25', investmentCount: 4 },
        { date: '2023-10-26', investmentCount: 1 },
        { date: '2023-10-27', investmentCount: 3 },
        { date: '2023-10-28', investmentCount: 2 },
        { date: '2023-10-29', investmentCount: 5 },
        { date: '2023-10-30', investmentCount: 3 },
        { date: '2023-10-31', investmentCount: 4 },
        { date: '2023-11-01', investmentCount: 1 },
        { date: '2023-11-02', investmentCount: 2 },
        { date: '2023-11-03', investmentCount: 3 },
        { date: '2023-11-04', investmentCount: 2 },
        { date: '2023-11-05', investmentCount: 4 },
        { date: '2023-11-06', investmentCount: 3 },
        { date: '2023-11-07', investmentCount: 2 },
        { date: '2023-11-08', investmentCount: 1 },
        { date: '2023-11-09', investmentCount: 4 },
        { date: '2023-11-10', investmentCount: 2 },
        { date: '2023-11-11', investmentCount: 3 },
        { date: '2023-11-12', investmentCount: 4 },
        { date: '2023-11-13', investmentCount: 3 },
        { date: '2023-11-14', investmentCount: 2 },
        { date: '2023-11-15', investmentCount: 1 },
        { date: '2023-11-16', investmentCount: 4 },
        { date: '2023-11-17', investmentCount: 3 },
        { date: '2023-11-18', investmentCount: 5 },
        { date: '2023-11-19', investmentCount: 2 },
        { date: '2023-11-20', investmentCount: 3 },
        { date: '2023-11-21', investmentCount: 4 },
        { date: '2023-11-22', investmentCount: 1 },
        { date: '2023-11-23', investmentCount: 3 },
        { date: '2023-11-24', investmentCount: 2 },
        { date: '2023-11-25', investmentCount: 4 },
        { date: '2023-11-26', investmentCount: 3 },
        { date: '2023-11-27', investmentCount: 2 },
        { date: '2023-11-28', investmentCount: 5 },
        { date: '2023-11-29', investmentCount: 4 },
        { date: '2023-11-30', investmentCount: 3 },
        { date: '2023-12-01', investmentCount: 2 },
    ];

    const moneyEarnedData = [
        { date: '2023-10-23', amount: 500 },
        { date: '2023-10-24', amount: 600 },
        { date: '2023-10-25', amount: 550 },
        { date: '2023-10-26', amount: 700 },
        { date: '2023-10-27', amount: 650 },
        { date: '2023-10-28', amount: 750 },
        { date: '2023-10-29', amount: 800 },
        { date: '2023-10-30', amount: 900 },
        { date: '2023-10-31', amount: 850 },
        { date: '2023-11-01', amount: 500 },
        { date: '2023-11-02', amount: 700 },
        { date: '2023-11-03', amount: 650 },
        { date: '2023-11-04', amount: 600 },
        { date: '2023-11-05', amount: 550 },
        { date: '2023-11-06', amount: 750 },
        { date: '2023-11-07', amount: 800 },
        { date: '2023-11-08', amount: 850 },
        { date: '2023-11-09', amount: 900 },
        { date: '2023-11-10', amount: 950 },
        { date: '2023-11-11', amount: 1000 },
        { date: '2023-11-12', amount: 1050 },
        { date: '2023-11-13', amount: 1100 },
        { date: '2023-11-14', amount: 500 },
        { date: '2023-11-15', amount: 600 },
        { date: '2023-11-16', amount: 550 },
        { date: '2023-11-17', amount: 650 },
        { date: '2023-11-18', amount: 700 },
        { date: '2023-11-19', amount: 750 },
        { date: '2023-11-20', amount: 800 },
        { date: '2023-11-21', amount: 850 },
        { date: '2023-11-22', amount: 500 },
        { date: '2023-11-23', amount: 650 },
        { date: '2023-11-24', amount: 700 },
        { date: '2023-11-25', amount: 550 },
        { date: '2023-11-26', amount: 600 },
        { date: '2023-11-27', amount: 650 },
        { date: '2023-11-28', amount: 700 },
        { date: '2023-11-29', amount: 750 },
        { date: '2023-11-30', amount: 800 },
        { date: '2023-12-01', amount: 850 },
    ];

    useEffect(() => {
        const aggregatedInvestmentData = aggregateData(investmentsData, timeFrame);
        const aggregatedEarningData = aggregateData(moneyEarnedData, timeFrame);
        setAggregatedInvestments(aggregatedInvestmentData);
        setAggregatedEarnings(aggregatedEarningData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeFrame]);

    useEffect(() => {
        async function fetchInvestor() {
            try {
                // Updated to use new API method
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: `/services/investors/${investorId}`
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                const camelCaseData = convertKeysToCamelCase(response.message);
                setInvestor(camelCaseData);
                setInvestorData(prevData => ({
                    ...prevData,
                    name: camelCaseData.companyName
                }));
            } catch (err) {
                console.error("Error fetching investor details: ", err);
            }
        }
    
        async function fetchInvestments() {
            try {
                // Updated to use new API method
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: `/services/investments/investor/${investorId}`
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                const camelCaseData = convertKeysToCamelCase(response.message);
                const startupInvestments = {};
    
                // Calculate the totalDealSize for each startup
                camelCaseData.forEach(investment => {
                    const { startupId, amount } = investment;
                    if (!startupInvestments[startupId]) {
                        startupInvestments[startupId] = {
                            totalDealSize: 0,
                            startupName: investment.startup,
                        };
                    }
                    startupInvestments[startupId].totalDealSize += parseInt(amount);
                });
    
                // Generate the investmentList
                const investmentList = camelCaseData.map(investment => {
                    return {
                        id: investment.startupId,
                        date: investment.date,
                        startupName: investment.startup,
                        myInvestment: parseInt(investment.amount),
                        totalDealSize: startupInvestments[investment.startupId].totalDealSize,
                        investmentType: investment.investmentType,
                    };
                });
    
                const totalInvestment = camelCaseData.reduce((sum, investment) => sum + parseInt(investment.amount), 0);
                const startupsInvested = new Set(camelCaseData.map(investment => investment.startupId)).size;
                const averageInvestment = totalInvestment / startupsInvested;
    
                setInvestorData(prevData => ({
                    ...prevData,
                    totalInvestment,
                    startupsInvested,
                    averageInvestment
                }));
                setInvestments(investmentList);
            } catch (err) {
                console.error("Error fetching investment details: ", err);
            }
        }
    
        fetchInvestor();
        fetchInvestments();
    }, [investorId]);

    const getWeek = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const getMonth = (date) => {
        return date.getMonth();
    };

    const aggregateData = (data, timeFrame) => {
        const aggregatedData = data.reduce((acc, curr) => {
            const date = new Date(curr.date);
            let key;

            switch (timeFrame) {
                case 'weekly':
                    key = `${date.getFullYear()}-W${getWeek(date)}`;
                    break;
                case 'monthly':
                    key = `${date.getFullYear()}-M${getMonth(date) + 1}`;
                    break;
                default: // daily
                    key = curr.date;
                    break;
            }

            if (!acc[key]) {
                acc[key] = { date: key, count: 0, amount: 0 };
            }

            if (curr.investmentCount) {
                acc[key].count += curr.investmentCount;
            }
            if (curr.amount) {
                acc[key].amount += curr.amount;
            }

            return acc;
        }, {});

        return Object.values(aggregatedData);
    };

    if (!investor) {
        return <div className="text-center my-5" style={{ marginTop: '10%' }}>Loading...</div>;
    }

    const handleTimeFrameChange = (newTimeFrame) => {
        setTimeFrame(newTimeFrame);
        // Additional logic to adjust data based on the selected time frame
    };


    const investmentList = [
        {
            id: '5411sqssa15aasc',
            date: '2023-12-15',
            startupName: 'Startup X',
            myInvestment: 25000,
            totalDealSize: 100000,
            investmentType: 'Fundraising (equity)',
        },
        {
            id: '5165dqwdqwddasdASDa1451assad',
            date: '2023-12-20',
            startupName: 'Startup Y',
            myInvestment: 30000,
            totalDealSize: 150000,
            investmentType: 'Merger',
        },
        {
            id: '15616wqedasd41qw5',
            date: '2023-12-25',
            startupName: 'Startup Z',
            myInvestment: 20000,
            totalDealSize: 80000,
            investmentType: 'Acquisition',
        }
    ];

    const statistics = {
        moneyEarned: 20000000,
        moneyLost: -500000,
        totalInvestments: 4,
        successfulInvestments: 3
    };

    const marketInsights = [
        "Tech industry is growing rapidly in Asia.",
        "Renewable energy startups are attracting more investments.",
        "E-commerce platforms are experiencing a surge in user base post-pandemic.",
        "Telemedicine services are gaining traction due to increased health awareness.",
        "FinTech innovations are disrupting traditional banking services.",
        "EdTech is expanding as remote learning becomes more prevalent.",
        "Investments in AI and machine learning continue to rise.",
        "Blockchain technology is being adopted more widely beyond cryptocurrencies.",
        "The gig economy is booming, leading to an increase in freelance platforms.",
        "Sustainable practices are becoming a priority for new consumer brands.",
        "Cybersecurity solutions are in high demand amidst growing digital threats.",
        "Autonomous driving technology is advancing, with more pilot programs underway.",
        "5G technology deployment is accelerating, promising faster network speeds.",
        "Smart home devices are becoming mainstream as consumers seek convenience.",
        "Health and wellness apps are seeing an uptick in usage as people focus on fitness.",
        "The food delivery industry is expanding with the introduction of cloud kitchens.",
        "Gaming industry is reaching new heights with the rise of esports and mobile gaming.",
        "The demand for cloud services has increased as companies shift to remote work.",
        "Wearable tech is becoming more sophisticated, offering more health monitoring features.",
        "Biotechnology is progressing rapidly, with significant investments in R&D."
    ];

    const pickRandomInsights = () => {
        const shuffled = [...marketInsights].sort(() => 0.5 - Math.random());
        setDisplayedInsights(shuffled.slice(0, 5));
    };

    const handleGoInvest = () => {
        navigate('/match');
    };

    return (
        <Row className="no-gutters">
            <Col style={{ marginTop: '6%' }} md={3} className="sidebar">
                <div className="textCenter">
                    <img src={profileImage} alt="Profile" className="profileImage" />
                    <h3 className="bold">{investorData.name}</h3>
                </div>
                <div className="textCenter">
                    <p>Total Investment: ${investorData?.totalInvestment?.toLocaleString()}</p>
                    <p>Startups Invested: {investorData?.startupsInvested}</p>
                    <p>Average Investment: ${investorData?.averageInvestment?.toLocaleString()}</p>
                </div>
                <div className="flexColumn">
                    <Button variant="success" onClick={handleGoInvest} size="md" block>
                        Go Invest
                    </Button>
                </div>
            </Col>

            <Col md={9} className="p-3" style={{ marginTop: '6%' }}>
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                    <Tab eventKey="waitList" title="Wait List">
                        <ListGroup>
                            {investor?.waitlist?.map((startup, index) => (
                                <ListGroup.Item key={index}>
                                    <strong>{startup.name}</strong> - {startup.industry}<br />
                                    <small>{startup.description}</small>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Tab>
                    <Tab eventKey="investmentList" title="Investment List">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Startup Logo</th>
                                    <th>Startup Name</th>
                                    <th>My Investment in this Startup</th>
                                    <th>Total Deal Size</th>
                                    <th>Type of Investment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {investments?.map((investment, index) => (
                                    <tr key={index}>
                                        <td>{investment.date}</td>
                                        <td><img src={profileImage} alt="Startup Logo" className="startup-logo" width="50" height="50" /></td>
                                        <td>
                                            <span
                                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                onClick={() => navigate(`/startup/${investment.id}`)}
                                            >
                                                {investment.startupName}
                                            </span>
                                        </td>
                                        <td>${investment.myInvestment.toLocaleString()}</td>
                                        <td>${investment.totalDealSize.toLocaleString()}</td>
                                        <td>{investment.investmentType}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    {/* <Tab eventKey="statistics" title="Statistics">
                        <Card>
                            <Card.Body>
                                <div className="stat-container">
                                    <div className="stat-item">
                                        <span className="stat-label">Money Earned</span>
                                        <span className={`stat-value ${statistics.moneyEarned >= 0 ? 'positive' : 'negative'}`}>
                                            ${statistics.moneyEarned.toLocaleString()}
                                        </span>
                                    </div>
                                    <div className="stat-item">
                                        <span className="stat-label">Money Lost</span>
                                        <span className={`stat-value ${statistics.moneyLost >= 0 ? 'positive' : 'negative'}`}>
                                            ${statistics.moneyLost.toLocaleString()}
                                        </span>
                                    </div>
                                    <div className="stat-item">
                                        <span className="stat-label">Total Investments</span>
                                        <span className="stat-value">{statistics.totalInvestments}</span>
                                    </div>
                                    <div className="stat-item">
                                        <span className="stat-label">Successful Investments</span>
                                        <span className="stat-value">{statistics.successfulInvestments}</span>
                                    </div>
                                </div>
                                <div className="time-frame-selector">
                                    <Button onClick={() => handleTimeFrameChange('daily')}>Daily</Button>
                                    <Button onClick={() => handleTimeFrameChange('weekly')}>Weekly</Button>
                                    <Button onClick={() => handleTimeFrameChange('monthly')}>Monthly</Button>
                                </div>

                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={aggregatedInvestments}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="count" stroke="#82ca9d" />
                                        <text x="50%" y="10%" textAnchor="middle" dominantBaseline="middle" fontSize={20} fill="#000">
                                            Investment Count Over Time
                                        </text>
                                    </LineChart>
                                </ResponsiveContainer>


                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={aggregatedEarnings}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="amount" fill="#8884d8" />

                                        <text x="50%" y="10%" textAnchor="middle" dominantBaseline="middle" fontSize={20} fill="#000">
                                            Earnings Over Time
                                        </text>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Tab> */}
                    <Tab eventKey="marketInsights" title="Market Insights">
                        <div className="insights-container">
                            <ListGroup>
                                {displayedInsights.map((insight, index) => (
                                    <Card className="insight-card" key={index}>
                                        <Card.Body>
                                            <FontAwesomeIcon icon={faLightbulb} className="insight-icon" />
                                            {insight}
                                        </Card.Body>
                                    </Card>
                                ))}
                            </ListGroup>
                            <div className="randomize-button-container">
                                <Button className="randomize-button" onClick={pickRandomInsights}>
                                    Show New Insights
                                </Button>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    );
}

export default InvestorDashboard;
