import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import StepsComponent from '../components/StepsComponent';
import { useParams } from 'react-router-dom';
import { fetchAuthSession, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { get, post } from 'aws-amplify/api';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { getYoutubeEmbedUrl, isValidYouTubeUrl, isValidGoogleDriveUrl } from '../untils/stringUtils'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import CameraCapture from '../components/CameraCapture';
import processImage from '../images/Registration process.png';


function StartupFormPage() {
    let { userId } = useParams();

    const navigate = useNavigate();
    const [isTechStartup, setIsTechStartup] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [isPdfSelected, setIsPdfSelected] = useState(false);
    const [isYoutubeSelected, setIsYoutubeSelected] = useState(false);
    const [url, setUrl] = useState("");
    const sigCanvas = useRef({});
    const [signName, setSignName] = useState('');
    const [signTitle, setSignTitle] = useState('');
    const [signUrl, setSignUrl] = useState("");
    const [faceMatchResults, setFaceMatchResults] = useState(null);
    const [faceMatched, setFaceMatched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const [teammates, setTeammates] = useState([]);
    const [stage, setStage] = useState(null);
    const formDataWithStage = [{
        id: '',
        email: '',
        title: '',
        description: '',
        startupStatus: '',
        foundedYear: '',
        teammates: [],
        vertical: '',
        cityCountryIncorporated: '',
        cityCountryHeadquarters: '',
        website: '',
        purposeDriven: false,
        socialTech: false,
        womenFounders: false,
        shariahCompliant: false
    },
    {
        id: '',
        email: '',
        projectOverview: '',
        investmentPitch: null,
        companyRegistration: '',
        startupStage: '',
        salesType: '',
        businessModel: '',
        revenue: '',
        customerLocation: '',
        problemSolved: '',
        competitorsRegion: '',
        competitorsInternational: '',
        competitiveAdvantage: '',
        burnRate: '',
        cashBalance: '',
        raisedMoney: '',
        baseTarget: 0,
        raiseRange: '',
        fullTimeEmployees: 0,
        partTimeEmployees: 0,
        previousExperience: '',
        investmentType: '',
        report: '',
        pitchDeckRecording: null,
        agreeCommunication: false,
        agreePrivacyPolicy: false
    }]

    const countriesList = [
        "Bahamas", "Barbados", "Bermuda", "Canada", "Cayman Islands", "Costa Rica",
        "Dominican Republic", "Jamaica", "Mexico", "Panama", "United States",
        "Argentina", "Aruba", "Bolivia", "Brazil", "Chile", "Colombia", "Ecuador",
        "Paraguay", "Peru", "Trinidad and Tobago", "Uruguay", "Venezuela",
        "Bahrain", "Israel", "Jordan", "Kuwait", "Qatar", "Saudi Arabia",
        "United Arab Emirates", "China", "Hong Kong", "India", "Japan",
        "Korea, Republic of", "Taiwan", "Indonesia", "Malaysia", "Philippines",
        "Singapore", "India", "Austria", "Belgium", "Cyprus", "Denmark", "Finland",
        "France", "Germany", "Gibraltar", "Greece", "Iceland", "Ireland", "Isle of Man",
        "Italy", "Liechtenstein", "Luxembourg", "Malta", "The Netherlands", "Norway",
        "Portugal", "Spain", "Sweden", "Switzerland", "United Kingdom", "Albania",
        "Bosnia and Herzegovina", "Bulgaria", "Croatia", "Czech Republic", "Estonia",
        "Hungary", "Latvia", "Lithuania", "Poland", "Romania", "Russia", "Serbia",
        "Slovakia", "Slovenia", "Turkey", "Ukraine", "Burkina Faso", "Egypt", "Ivory Coast",
        "Kenya", "Mauritius", "Nigeria", "Senegal", "South Africa", "Tunisia",
        "Australia", "New Zealand"
    ];
    const [formData, setFormData] = useState(formDataWithStage[0]);
    const [showEquidam, setShowEquidam] = useState(true);
    useEffect(() => {
        async function fetchStartups() {
            try {
                const auth_user = await fetchAuthSession();
                const isAdmin = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                const attributes = await fetchUserAttributes();
                const type = attributes['custom:type'];
                if (!isAdmin) {
                    if (userId || (type !== 'startup')) {
                        if (type === 'investor') {
                            navigate('/');
                        } else {
                            try {
                                await updateUserAttributes({
                                    userAttributes: {
                                        'custom:type': 'startup'
                                    }
                                });
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    }
                }
                const restOperation = get({
                    apiName: "fundiiAPI",
                    path: `/services/startups/${userId ? userId : auth_user?.tokens?.accessToken?.payload['username']}`
                });

                const { body } = await restOperation.response;
                const response = await body.json();
                // const response = JSON.parse(response_json)
                if (userId) {
                    response.message.stage = response.message.stage ? response.message.stage - 1 : response.message.stage;
                }
                if (response?.message?.stage !== undefined && response?.message?.stage !== null) {
                    if (response.message.stage === 6) {
                        navigate('/startup');
                    }
                    else if (response.message.stage === 4) {
                        const getUrlResult = await getUrl({
                            key: 'PolicyInfo/NCNDA.pdf',
                            options: {
                                accessLevel: 'guest'
                            },
                        });
                        setUrl(getUrlResult.url)
                        setSignName(response.message.sign_name)
                        setSignTitle(response.message.sign_title)
                        setSignUrl(response.message.sign_url)
                        setFormData({ email: response.message.email, id: response.message.id })
                        setIsLoading(false)
                    }
                    else if (response.message.stage === 1 || response.message.stage === 3 || response.message.stage === 5) {
                        navigate('/status/submitted');
                    } else if (response.message.stage === -1) {
                        navigate('/status/declined');
                    }
                    else if (response.message.stage === 0) {
                        // Convert teammates to the appropriate structure
                        const newFormData = {};
                        Object.keys(formDataWithStage[0]).forEach(camelCaseKey => {
                            // Convert camelCase to snake_case
                            const snakeCaseKey = camelCaseKey.replace(/([A-Z])/g, '_$1').toLowerCase();
                            if (snakeCaseKey in response.message) {
                                newFormData[camelCaseKey] = response.message[snakeCaseKey];
                            }
                        });
                        setFormData(newFormData);
                        const teammates = response.message.teammates.map(t => ({
                            age: t.age,
                            countryOfResidence: t.country_of_residence,
                            educationLevel: t.education_level,
                            email: t.email,
                            familyName: t.family_name,
                            firstName: t.first_name,
                            linkedin: t.linkedin,
                            nationality: t.nationality,
                            phoneNumber: t.phone_number,
                            position: t.position
                        }));
                        setTeammates(teammates)
                        setIsLoading(false)
                    } else if (response.message.stage === 2) {
                        const newFormData = {};
                        // Assuming response.message has keys in snake_case that match formDataWithStage[1] in camelCase
                        Object.keys(formDataWithStage[1]).forEach(camelCaseKey => {
                            // Convert camelCase to snake_case
                            const snakeCaseKey = camelCaseKey.replace(/([A-Z])/g, '_$1').toLowerCase();
                            if (snakeCaseKey in response.message) {
                                newFormData[camelCaseKey] = response.message[snakeCaseKey];
                            }
                        });
                        console.log(newFormData)
                        const country = formData.cityCountryHeadquarters.split(', ')[1]; // Extract country
                        if (country && !countriesList.includes(country)) {
                            setShowEquidam(false);
                        }
                        setIsYoutubeSelected((newFormData.pitchDeckRecording) ? true : false)
                        setIsPdfSelected((newFormData.investmentPitch) ? true : false)
                        if (userId) {
                            const getUrlResult = await getUrl({
                                key: `${userId}/CRD.pdf`,
                                options: {
                                    accessLevel: 'guest'
                                },
                            });
                            setFormData({
                                ...newFormData,
                                companyRegistrationDocPreview: getUrlResult.url, // New state property for the preview URL
                            });
                        } else {
                            setFormData(newFormData);
                        }
                        setIsLoading(false)
                    }
                    setStage(response.message.stage)
                    setIsValidUrl(false)
                    setIsTechStartup(true);
                } else {
                    setIsLoading(false)
                    setStage(0)
                }
            } catch (err) {
                console.log("Error fetching startups: ", err);
            }
        }

        fetchStartups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]); // Added dependency on userId

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip-width" {...props}>
            Click to generate your detailed valuation report using Equidam. By using the provided umbrella link, you'll receive a 10% discount on Equidam's pricing. Once you've completed the valuation on Equidam, download the PDF report and upload it to our platform to facilitate your capital raise. They support all industries and are available in various countries. Let's make your fundraising journey smoother with Equidam!
        </Tooltip>
    );

    const handleGenerateReport = () => {
        window.location.href = "https://www.equidam.com/?r=tQZa2KkdejL75PrfkVo0ZkzwOmBpECUdjTbIoIle"; // Replace with the actual link
    };

    const handleFileChange = async (e, fileType) => {
        const auth_user = await fetchAuthSession();
        const file = e.target.files[0];
        if (!file) return;

        try {
            // Use fileType in the filename to distinguish between different uploads
            const filename = `${auth_user?.tokens?.accessToken?.payload['username']}/${fileType}.pdf`;
            const uploadResult = await uploadData({
                key: filename,
                data: file,
                options: {
                    contentType: file.type,
                    accessLevel: "guest"
                }
            }).result;

            const getUrlResult = await getUrl({
                key: filename,
                options: { accessLevel: "guest", expiresIn: 900 }
            });

            // Update the form data based on the file type
            setFormData({
                ...formData,
                [fileType]: getUrlResult.url // Dynamically setting the key based on fileType
            });

        } catch (err) {
            console.error('Error uploading the file:', err);
        }
    };

    const handleCRDFileChange = async (e) => {
        const auth_user = await fetchAuthSession();
        const file = e.target.files[0]; // Get the uploaded file
        if (file) {
            try {
                const filename = `${auth_user?.tokens?.accessToken?.payload['username']}/CRD.pdf`; // Unique filename using current timestamp
                uploadData({
                    key: filename,
                    data: file,
                    options: {
                        contentType: file.type,
                        accessLevel: 'guest'
                    }
                });

                // Create a temporary URL for the file for preview purposes
                const previewUrl = URL.createObjectURL(file);

                // Update formData with the object key and preview URL
                setFormData({
                    ...formData,
                    companyRegistration: filename, // Store the filename or objectKey as needed
                    companyRegistrationDocPreview: previewUrl, // New state property for the preview URL
                });

            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        }
    };


    const downloadFile = () => {
        if (formData.investmentPitch) {
            window.open(formData.investmentPitch);
        }
    };

    const steps = [[
        { label: 'Project Title', field: 'title' },
        { label: 'Business Description', field: 'description' },
        { label: 'Launch Status', field: 'startupStatus' },
        { label: 'Founded Year', field: 'foundedYear' },
        { label: 'Team', field: 'teammates' },
        { label: 'Vertical', field: 'vertical' },
        { label: 'Location', field: 'cityCountryIncorporated' },
        { label: 'Headquarters', field: 'cityCountryHeadquarters' },
        { label: 'Website', field: 'website' },
    ],
    [
        { label: 'Project Overview', field: 'projectOverview' },
        { label: 'Pitch Deck', field: ['investmentPitch', 'pitchDeckRecording'] },
        { label: 'Registration Document', field: 'companyRegistration' },
        { label: 'Startup Stage', field: 'startupStage' },
        { label: 'Type of Sales', field: 'salesType' },
        { label: 'Business Model', field: 'businessModel' },
        { label: 'Revenue in the Past 6 Months', field: 'revenue' },
        { label: 'Target Customers Location', field: 'customerLocation' },
        { label: 'Problem Solved by the Company', field: 'problemSolved' },
        { label: 'Main Competitors in Region', field: 'competitorsRegion' },
        { label: 'Main Competitors Internationally', field: 'competitorsInternational' },
        { label: 'Competitive Advantage', field: 'competitiveAdvantage' },
        { label: 'Monthly Burn Rate', field: 'burnRate' },
        { label: 'Current Cash Balance', field: 'cashBalance' },
        { label: 'Previous Fundraising', field: 'raisedMoney' },
        { label: 'Range of Fundraising', field: 'raiseRange' },
        { label: 'Amount of Fundraising', field: 'baseTarget' },
        { label: 'Full-time Employees', field: 'fullTimeEmployees' },
        { label: 'Part-time Employees', field: 'partTimeEmployees' },
        { label: 'Previous Entrepreneurial Experience', field: 'previousExperience' },
        { label: 'Risk Analyse Report', field: 'report' },
        { label: 'Investment Type', field: 'investmentType' },
        { label: 'Agree to Communication', field: 'agreeCommunication' },
        { label: 'Agree to Privacy Policy', field: 'agreePrivacyPolicy' },
        { label: 'Face Recognization', field: 'faceMatched' }
    ]];

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        if (formData.investmentPitch) {
            const regexp = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
            setIsValidUrl(regexp.test(formData.investmentPitch));
        }
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleTechStartupChange = (e) => {
        if (e.target.value === 'No') {
            alert('fundii is a fundraising platform for tech startups only. Sorry for the inconvenience');
            setIsTechStartup(false);
        } else {
            setIsTechStartup(true);
        }
    };

    const handleAddTeammate = () => {
        setTeammates([...teammates, {}]);
        setFormData({
            ...formData,
            teammates: [...teammates, {}],
        });
    };

    const handleRemoveTeammate = (index) => {
        const newTeammates = [...teammates];
        newTeammates.splice(index, 1);
        setTeammates(newTeammates);
        setFormData({
            ...formData,
            teammates: newTeammates,
        });
    };

    const handleTeammatePropertyChange = (index, property, value) => {
        const newTeammates = [...teammates];
        newTeammates[index][property] = value;
        setTeammates(newTeammates);
        setFormData({
            ...formData,
            teammates: newTeammates,
        });
    };

    const handleSubmit = async () => {
        const userAttributes = await fetchUserAttributes();
        try {
            const isInvestmentPitchValid = !formData.investmentPitch || isValidGoogleDriveUrl(formData.investmentPitch);
            const isPitchDeckRecordingValid = !formData.pitchDeckRecording || isValidYouTubeUrl(formData.pitchDeckRecording);

            if (!isInvestmentPitchValid) {
                alert('Please enter a valid Google Drive link for the Investment Pitch.');
                return false; // Prevent form submission
            }

            if (!isPitchDeckRecordingValid) {
                alert('Please enter a valid YouTube URL for the Pitch Deck Recording.');
                return false; // Prevent form submission
            }
            const myInit = {
                body: {
                    ...formData,
                    stage: stage + 1,
                    ...(stage === 2 && {
                        id: undefined,
                        email: undefined
                    }),
                    ...(stage !== 2 && {
                        id: userAttributes.sub,
                        email: userAttributes.email
                    })
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await post({
                apiName: "fundiiAPI",
                path: `/services/startups${stage === 0 ? '' : `/${userAttributes.sub}`}`,
                options: myInit
            })
            navigate('/status/submitted');


        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const handleAccept = async () => {
        try {
            const myInit = {
                body: {
                    ...formData,
                    stage: stage + 2,
                    ...{
                        id: undefined,
                        email: undefined
                    }
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            post({
                apiName: "fundiiAPI",
                path: `/services/startups/${formData.id}`,
                options: myInit
            });
            navigate('/admin/startup');
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const handleResign = async () => {
        try {
            const subject = "Resign Your NCND Agreement";
            const emailBody =
                `Dear User,
        
            We kindly request you to resign your Non-Circumvention and Non-Disclosure (NCND) Agreement.
            
            Thank you for your cooperation.
            
            Best regards,
            fundii Team`;

            const mailtoLink = `mailto:${formData.email}?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(emailBody)}`;
            window.location.href = mailtoLink;
            const myInit = {
                body: {
                    ...formData,
                    stage: stage - 1,
                    ...{
                        id: undefined,
                        email: undefined
                    }
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            post({
                apiName: "fundiiAPI",
                path: `/services/startups/${formData.id}`,
                options: myInit
            });
            navigate('/admin/startup');
        } catch (err) {
            console.log(err);
            return false;
        }
    };
    const handleVerify = async () => {
        try {
            const subject = "Your Account is Now Available";
            const emailBody = `Dear User,
      
            We are pleased to inform you that your startup account is now available.
            
            Thank you for choosing Fundii.
            
            Best regards,
            fundii Team`;

            const mailtoLink = `mailto:${formData.email}?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(emailBody)}`;
            window.location.href = mailtoLink;

            const myInit = {
                body: {
                    ...formData,
                    stage: stage + 2,
                    ...{
                        id: undefined,
                        email: undefined
                    }
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };


            post({
                apiName: "fundiiAPI",
                path: `/services/startups/${formData.id}`,
                options: myInit
            });

            navigate('/admin/startup');
        } catch (err) {
            console.log(err);
            return false;
        }
    };


    const handleDecline = async () => {
        try {
            const myInit = {
                body: {
                    ...formData,
                    stage: -1,
                    ...{
                        id: undefined,
                        email: undefined
                    }
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            post({
                apiName: "fundiiAPI",
                path: "/services/startup",
                options: myInit
            });
            navigate('/admin/startup');
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    function handlePdfCheckboxChange(e) {
        setIsPdfSelected(e.target.checked);
        if (e.target.checked) {
            setIsYoutubeSelected(false);
            setFormData({ ...formData, pitchDeckRecording: '' });
        }
    }

    function handleYoutubeCheckboxChange(e) {
        setIsYoutubeSelected(e.target.checked);
        if (e.target.checked) {
            setIsPdfSelected(false);
            setFormData({ ...formData, investmentPitch: '' });
        }
    }

    const handleSaveSignature = async () => {
        const signatureImage = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const userAttributes = await fetchUserAttributes();
        try {
            const myInit = {
                body: {
                    signName,
                    signTitle,
                    signUrl: signatureImage,
                    stage: stage + 1,
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            post({
                apiName: "fundiiAPI",
                path: `/services/startups/${userAttributes.sub}`,
                options: myInit
            });
            navigate('/status/submitted');
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const filename = `idPhoto.jpg`;
                uploadData({
                    key: filename,
                    data: file,
                    options: {
                        contentType: file.type,
                        accessLevel: 'private'
                    }
                });

                const getUrlResult = await getUrl({
                    key: filename,
                    options: {
                        accessLevel: 'private',
                    },
                });

                // For previewing, create a temporary URL
                const url = URL.createObjectURL(file);
                const objectKey = extractKeyFromUrl(getUrlResult.url);
                // Store just the key, not the full URL
                setFormData({
                    ...formData,
                    IDAddress: objectKey,
                });
                document.getElementById('imagePreview').src = url;
            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        }
    };

    const extractKeyFromUrl = (url) => {
        // Decode the URL to handle encoded characters
        const decodedUrl = decodeURIComponent(url);

        // This pattern will need to match the structure of your S3 keys
        // eslint-disable-next-line
        const pattern = /private\/[^\/]+\/[^\?]+/;
        const match = decodedUrl.match(pattern);

        if (match) {
            // The first match should be the key
            return match[0];
        }

        return null;
    };

    const handleImageCapture = async (blob) => {
        try {
            const fileName = `user-${Date.now()}.jpg`; // Change the file name accordingly

            // Uploading the image using uploadData
            uploadData({
                key: fileName,
                data: blob,
                options: {
                    contentType: 'image/jpeg',
                    accessLevel: 'private'
                }
            });

            // Getting the URL of the uploaded image
            const getUrlResult = await getUrl({
                key: fileName,
                options: {
                    accessLevel: 'private',
                    expiresIn: 60 // URL valid for 60 seconds, adjust as needed
                }
            });

            const objectKey = extractKeyFromUrl(getUrlResult.url);

            const restOperation = post({
                apiName: "fundiiAPI",
                path: "/services/verify-identity",
                options: {
                    body: {
                        idImageKey: formData.IDAddress, // The key of the ID photo
                        userImageKey: objectKey // The key of the captured photo
                    },
                    headers: {
                        'Content-Type': 'application/json'
                        // Authorization header is handled by Amplify
                    }
                }
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            if (response.message.FaceMatches && response.message.FaceMatches.length > 0) {
                setFaceMatchResults(`Face Matched`);
                await new Promise(resolve => setTimeout(resolve, 2000));
                setFaceMatched(true)
            } else {
                setFaceMatchResults("Face Not Match");
            }

        } catch (error) {
            console.error('Error in facial recognition: ', error);
            setFaceMatchResults("Error in facial recognition process");
        }

    }

    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip-width" {...props}>
            For enhanced security and identity verification, we partner with Persona.
            Please ensure you upload a clear copy of your ID or passport for verification purposes.
            Follow the steps to complete your identity verification through Persona:
            <ol>
                <li>Click on the 'Start Verification' button to begin.</li>
                <li>Follow the on-screen instructions to submit your ID and any additional required information.</li>
                <li>Once you've completed all steps, return to our site to proceed.</li>
            </ol>
            Your privacy and security are our top priority. Thank you for taking the time to verify your identity.
        </Tooltip>
    );

    const handleVerification = () => {
        window.open("https://withpersona.com/verify?inquiry-template-id=itmpl_tuoy6p6PfVnUhevJKB315Hbm&environment-id=env_dDG4aUUzTvLnoN4CAZYeDiwo", "_blank");
    };

    return !isLoading ? (
        <div style={{ marginBottom: '20%' }} className="main-container container mt-5 d-flex">
            {stage === 0 && (
                <div className="bg-white p-4 rounded flex-fill">
                    <img src={processImage} alt={'Process'} className="img-fluid" />
                    <h2 className="mt-5 mb-4">Startup Form (Step 1)</h2>

                    <div className="form-group mb-4">
                        <label>Are you a tech startup?</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="techStartup" checked={isTechStartup} id="yes" value="Yes" onChange={handleTechStartupChange} />
                                <label className="form-check-label" htmlFor="yes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="techStartup" checked={!isTechStartup} id="no" value="No" onChange={handleTechStartupChange} />
                                <label className="form-check-label" htmlFor="no">No</label>
                            </div>
                        </div>
                    </div>

                    {isTechStartup && (
                        <>
                            <div className="form-group mb-4">
                                <label htmlFor="title">Title of the project (company name if available)</label>
                                <input type="text" className="form-control" id="title" name="title" value={formData.title}
                                    onChange={handleChange} placeholder="Enter title of the project" />
                            </div>

                            <div className="form-group mb-4">
                                <label htmlFor="description">Idea/business description (max. 100 words)</label>
                                <textarea className="form-control" id="description" rows="3" maxLength="100" name="description" value={formData.description}
                                    onChange={handleChange}></textarea>
                                <small className="form-text text-muted">{formData.description}/100</small>
                            </div>

                            <div className="form-group mb-4">
                                <label>Has your product/service launched?</label>
                                <select className="form-control" name="startupStatus" value={formData.startupStatus} onChange={handleChange}>
                                    <option value="">Select Status</option>
                                    <option>Yes, it's public</option>
                                    <option>Yes, it's in public Beta</option>
                                    <option>Yes, it's in private Beta</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="foundedYear">What is your company's founding year?</label>
                                <input type="text" className="form-control" id="foundedYear" name="foundedYear" value={formData.foundedYear}
                                    onChange={handleChange} placeholder="Enter the founding year of the company" />
                            </div>


                            <div className="form-group mb-4">
                                <label>Teammates</label>
                                <div className="mb-3">
                                    <button
                                        type="button"
                                        className="btn btn-outline-info"
                                        onClick={handleAddTeammate}
                                    >
                                        Add Teammate
                                    </button>
                                </div>
                                {teammates.map((teammate, index) => (
                                    <div key={index} className="border p-3 mb-3">
                                        <h5>Teammate #{index + 1}</h5>
                                        <input type="text" className="form-control mb-3" placeholder="First Name" value={teammate.firstName} onChange={(e) => handleTeammatePropertyChange(index, 'firstName', e.target.value)} />
                                        <input type="text" className="form-control mb-3" placeholder="Family Name" value={teammate.familyName} onChange={(e) => handleTeammatePropertyChange(index, 'familyName', e.target.value)} />
                                        <select className="form-control mb-3" value={teammate.age} onChange={(e) => handleTeammatePropertyChange(index, 'age', e.target.value)}>
                                            <option value="">Select Age Range</option>
                                            <option>18-25</option>
                                            <option>26-35</option>
                                            <option>36-45</option>
                                            <option>46-55</option>
                                            <option>56-65</option>
                                            <option>66+</option>
                                        </select>
                                        <select className="form-control mb-3" value={teammate.educationLevel} onChange={(e) => handleTeammatePropertyChange(index, 'educationLevel', e.target.value)}>
                                            <option value="">Select Education Level</option>
                                            <option>High School</option>
                                            <option>Bachelor's Degree</option>
                                            <option>Master's Degree</option>
                                            <option>Doctorate Degree</option>
                                            <option>Associate's Degree</option>
                                        </select>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Country of Residence"
                                            value={teammate.countryOfResidence}
                                            onChange={(e) => handleTeammatePropertyChange(index, 'countryOfResidence', e.target.value)}
                                        />

                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Nationality"
                                            value={teammate.nationality}
                                            onChange={(e) => handleTeammatePropertyChange(index, 'nationality', e.target.value)}
                                        />

                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Email"
                                            value={teammate.email}
                                            onChange={(e) => handleTeammatePropertyChange(index, 'email', e.target.value)}
                                        />

                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Phone Number"
                                            value={teammate.phoneNumber}
                                            onChange={(e) => handleTeammatePropertyChange(index, 'phoneNumber', e.target.value)}
                                        />

                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="LinkedIn Page (if available)"
                                            value={teammate.linkedin}
                                            onChange={(e) => handleTeammatePropertyChange(index, 'linkedin', e.target.value)}
                                        />

                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Position at the Company"
                                            value={teammate.position}
                                            onChange={(e) => handleTeammatePropertyChange(index, 'position', e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger mt-3"
                                            onClick={() => handleRemoveTeammate(index)}
                                        >
                                            Remove Teammate
                                        </button>
                                    </div>
                                ))}

                                <div className="form-group mb-4">
                                    <label>What industry/vertical your startup belongs to</label>
                                    <select
                                        className="form-control" name="vertical" value={formData.vertical} onChange={handleChange}
                                    >
                                        <option value="">Select Vertical</option>
                                        <option>Adtech / Media tech</option>
                                        <option>Advanced manufacturing</option>
                                        <option>Agtech / Farmtech</option>
                                        <option>Artificial intelligence and machine learning (AI/ML)</option>
                                        <option>Audiotech</option>
                                        <option>Augmented reality (AR)</option>
                                        <option>Autonomous cars</option>
                                        <option>B2B payments</option>
                                        <option>Beauty</option>
                                        <option>Big Data</option>
                                        <option>Biotech</option>
                                        <option>Cannabis</option>
                                        <option>Carsharing</option>
                                        <option>Cleantech</option>
                                        <option>Climate tech</option>
                                        <option>Cloudtech and DevOps</option>
                                        <option>Construction technology</option>
                                        <option>Cryptocurrency and blockchain</option>
                                        <option>Cybersecurity</option>
                                        <option>Deeptech</option>
                                        <option>Digital health</option>
                                        <option>Ecommerce</option>
                                        <option>Edtech</option>
                                        <option>Ephemeral content</option>
                                        <option>eSports / Sport tech</option>
                                        <option>Fashiontech</option>
                                        <option>Femtech</option>
                                        <option>Fintech</option>
                                        <option>Foodtech</option>
                                        <option>Gaming</option>
                                        <option>Healthtech</option>
                                        <option>HRtech</option>
                                        <option>Impact investing</option>
                                        <option>Industrials</option>
                                        <option>Infrastructure</option>
                                        <option>Insurtech</option>
                                        <option>Internet of Things (IoT)</option>
                                        <option>Legal tech</option>
                                        <option>Life sciences</option>
                                        <option>Lifestyles of Health and Sustainability (LOHAS) and wellness</option>
                                        <option>Logitech / Logistics</option>
                                        <option>Manufacturing</option>
                                        <option>Marketing tech</option>
                                        <option>Micro-mobility</option>
                                        <option>Mobile</option>
                                        <option>Mobile commerce</option>
                                        <option>Mobility tech</option>
                                        <option>Mortgage tech</option>
                                        <option>Nanotechnology</option>
                                        <option>Oil and gas</option>
                                        <option>Oncology</option>
                                        <option>Pet tech</option>
                                        <option>Real estate tech / Proptech</option>
                                        <option>Restaurant tech</option>
                                        <option>Ridesharing</option>
                                        <option>Robotics and drones</option>
                                        <option>Software as a service (SaaS)</option>
                                        <option>Space tech</option>
                                        <option>Supply chain technology</option>
                                        <option>Sustainability</option>
                                        <option>Traveltech</option>
                                        <option>Technology, media and telecommunications (TMT)</option>
                                        <option>Virtual reality (VR)</option>
                                        <option>Wearables and quantified self</option>
                                        <option>3D printing</option>
                                    </select>
                                </div>

                                <div className="form-group mb-4">
                                    <label>City and country of the startup (location the startup incorporated eg. Tokyo, Japan)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter City and Country"
                                        name="cityCountryIncorporated"
                                        value={formData.cityCountryIncorporated}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group mb-4">
                                    <label>City and country of the startup headquarters(eg. Tokyo, Japan)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter City and Country"
                                        name="cityCountryHeadquarters"
                                        value={formData.cityCountryHeadquarters}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group mb-4">
                                    <label>Which of the following categories apply to your startup?</label>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="purposeDriven"
                                            id="purposeDriven"
                                            checked={formData.purposeDriven}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="purposeDriven">
                                            Purpose-Driven Startup
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="socialTech"
                                            id="socialTech"
                                            checked={formData.socialTech}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="socialTech">
                                            Social Tech Company
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="womenFounders"
                                            id="womenFounders"
                                            checked={formData.womenFounders}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="womenFounders">
                                            Tech startups with woman founders
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="shariahCompliant"
                                            id="shariahCompliant"
                                            checked={formData.shariahCompliant}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="shariahCompliant">
                                            Shariah compliant tech startups
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label>Website (if available or link to MVP, demo, etc)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Website URL"
                                        name="website"
                                        value={formData.website}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            {stage === 2 && (
                <div className="bg-white p-4 rounded flex-fill">
                    <img src={processImage} alt={'Process'} className="img-fluid" />
                    <h2 className="mt-5 mb-4">Startup Form (Stage 2)</h2>

                    <div className="form-group mb-4">
                        <label htmlFor="projectOverview">Overview of the project (max 1000 words)</label>
                        <textarea className="form-control" id="projectOverview" rows="6" maxLength="1000" name="projectOverview"
                            value={formData.projectOverview} onChange={handleChange}></textarea>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="pitchDeck">Your Pitch Deck</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isPdfSelected}
                                onChange={handlePdfCheckboxChange}
                                id="pdfCheckbox"
                            />
                            <label className="form-check-label" htmlFor="pdfCheckbox">
                                Upload PDF (Google Drive link)
                            </label>
                        </div>
                        {(isPdfSelected || userId) && (
                            <input
                                type="text"
                                className="form-control"
                                id="investmentPitch"
                                name="investmentPitch"
                                placeholder="Enter Google Drive link here"
                                onChange={handleChange}
                            />
                        )}
                    </div>

                    <div className="form-group mb-4">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isYoutubeSelected}
                                onChange={handleYoutubeCheckboxChange}
                                id="youtubeCheckbox"
                            />
                            <label className="form-check-label" htmlFor="youtubeCheckbox">
                                Upload YouTube link(Max 3 mins)
                            </label>
                        </div>
                        {(isYoutubeSelected || userId) && (
                            <input
                                type="text"
                                className="form-control"
                                id="pitchDeckRecording"
                                name="pitchDeckRecording"
                                placeholder="Enter YouTube link here"
                                value={formData.pitchDeckRecording}
                                onChange={handleChange}
                            />
                        )}
                    </div>

                    {isYoutubeSelected && isValidUrl && (
                        <div className="video-preview mb-4">
                            <iframe
                                title="Youtube preview"
                                width="100%"
                                height="500"
                                src={getYoutubeEmbedUrl(formData.pitchDeckRecording)}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}

                    <div className="form-group mb-4">
                        <label htmlFor="companyRegistrationDoc">Company Registration Document (PDF only)</label>
                        <input
                            type="file"
                            className="form-control"
                            id="companyRegistrationDoc"
                            name="companyRegistrationDoc"
                            onChange={handleCRDFileChange} // You'll implement this function next
                            accept="application/pdf"
                        />
                    </div>

                    {formData.companyRegistrationDocPreview && (
                        <div className="form-group mb-4">
                            <label>Preview:</label><br />
                            <embed src={formData.companyRegistrationDocPreview} width="100%" height="400px" />
                        </div>
                    )}

                    <div className="form-group mb-4">
                        <label>Startup stage</label>
                        <select className="form-control" name="startupStage" value={formData.startupStage} onChange={handleChange}>
                            <option value="">Select Stage</option>
                            <option value="Pre-seed">Pre-seed</option>
                            <option value="Seed">Seed</option>
                            <option value="Growth- series A">Growth- series A</option>
                            <option value="Growth- series B">Growth- series B</option>
                            <option value="Growth- series C and C+">Growth- series C and C+</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label>Type of sales</label>
                        <select className="form-control" name="salesType" value={formData.salesType} onChange={handleChange}>
                            <option value="">Select Sales Type</option>
                            <option value="B2B">B2B</option>
                            <option value="B2C">B2C</option>
                            <option value="B2G">B2G</option>
                            <option value="C2C">C2C</option>
                            <option value="B2B2C">B2B2C</option>
                            <option value="Other">Other/Not sure</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="businessModel">Please describe your business model (max 200 words)</label>
                        <textarea className="form-control" id="businessModel" rows="4" maxLength="200" name="businessModel"
                            value={formData.businessModel} onChange={handleChange}></textarea>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="revenue">How much revenue did your company make during the past 6 months?</label>
                        <input type="text" className="form-control" id="revenue" name="revenue"
                            value={formData.revenue} onChange={handleChange} />
                    </div>

                    <div className="form-group mb-4">
                        <label>Where are your target customers located?</label>
                        <select className="form-control" name="customerLocation" value={formData.customerLocation} onChange={handleChange}>
                            <option value="">Select Location</option>
                            <option value="Africa">Africa (excluding north Africa)</option>
                            <option value="Australia_NewZealand">Australia and New Zealand</option>
                            <option value="EastAsia">East Asia</option>
                            <option value="Europe">Europe</option>
                            <option value="LatinAmerica">Latin America</option>
                            <option value="MENA">MENA (Middle East and North Africa)</option>
                            <option value="NorthAmerica">North America (Canada, USA, Mexico)</option>
                            <option value="SouthAsia">South Asia</option>
                            <option value="SouthEastAsia">South East Asia</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="problemSolved">What problem your company solves through your product/service? (max 200 words)</label>
                        <textarea className="form-control" id="problemSolved" rows="4" maxLength="200" name="problemSolved"
                            value={formData.problemSolved} onChange={handleChange}></textarea>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="competitorsRegion">Who are your main (direct) and indirect competitors in your region? (max 100 words)</label>
                        <textarea className="form-control" id="competitorsRegion" rows="3" maxLength="100" name="competitorsRegion"
                            value={formData.competitorsRegion} onChange={handleChange}></textarea>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="competitorsInternational">Who are your main (direct) and indirect competitors internationally? (max 100 words)</label>
                        <textarea className="form-control" id="competitorsInternational" rows="3" maxLength="100" name="competitorsInternational"
                            value={formData.competitorsInternational} onChange={handleChange}></textarea>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="competitiveAdvantage">What's your competitive advantage? (max 150 words)</label>
                        <textarea className="form-control" id="competitiveAdvantage" rows="4" maxLength="150" name="competitiveAdvantage"
                            value={formData.competitiveAdvantage} onChange={handleChange}></textarea>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="burnRate">What's your current monthly burn rate?</label>
                        <input type="text" className="form-control" id="burnRate" name="burnRate"
                            value={formData.burnRate} onChange={handleChange} />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="cashBalance">What's your current cash balance?</label>
                        <input type="text" className="form-control" id="cashBalance" name="cashBalance"
                            value={formData.cashBalance} onChange={handleChange} />
                    </div>

                    <div className="form-group mb-4">
                        <label>Have you already raised money?</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="raisedMoney" id="yesRaised" checked={formData.raisedMoney === 'Yes'} value="Yes" onChange={handleChange} />
                                <label className="form-check-label" htmlFor="yesRaised">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="raisedMoney" id="noRaised" checked={formData.raisedMoney === 'No'} value="No" onChange={handleChange} />
                                <label className="form-check-label" htmlFor="noRaised">No</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <label>How much would you like to raise or sell your business through M&A?</label>
                        <select
                            className="form-control"
                            name="raiseRange"
                            value={formData.raiseRange}
                            onChange={handleChange}
                        >
                            <option value="">Select Amount</option>
                            <option value="$250,000-$500,000 USD">$250,000-$500,000 USD</option>
                            <option value="$500,000-$1,000,000 USD">$500,000-$1,000,000 USD</option>
                            <option value="$1,000,000-$5,000,000 USD">$1,000,000-$5,000,000 USD</option>
                            <option value="$5,000,000-$10,000,000 USD">$5,000,000-$10,000,000 USD</option>
                            <option value="$10,000,000+ USD">$10,000,000+ USD</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="fullTimeEmployees">How much would you like to raise or sell your business through M&A- Exact amount in USD:</label>
                        <input type="number" className="form-control" id="baseTarget" name="baseTarget"
                            value={formData.baseTarget} onChange={handleChange} />
                    </div>


                    <div className="form-group mb-4">
                        <label htmlFor="fullTimeEmployees">How many full-time employees do you have?</label>
                        <input type="number" className="form-control" id="fullTimeEmployees" name="fullTimeEmployees"
                            value={formData.fullTimeEmployees} onChange={handleChange} />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="partTimeEmployees">How many part-time employees do you have?</label>
                        <input type="number" className="form-control" id="partTimeEmployees" name="partTimeEmployees"
                            value={formData.partTimeEmployees} onChange={handleChange} />
                    </div>

                    <div className="form-group mb-4">
                        <label>Have you or any of your co-founders/executives launched a company before?</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="previousExperience" checked={formData.previousExperience === 'Yes'} id="yesExperience" value="Yes" onChange={handleChange} />
                                <label className="form-check-label" htmlFor="yesExperience">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="previousExperience" checked={formData.previousExperience === 'No'} id="noExperience" value="No" onChange={handleChange} />
                                <label className="form-check-label" htmlFor="noExperience">No</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="report">Risk Analyse Report(PDF only)</label>
                        <input
                            type="file"
                            className="form-control"
                            id="report"
                            name="report"
                            accept=".pdf"
                            onChange={(e) => handleFileChange(e, 'report')}
                        />
                    </div>
                    {formData.report && (
                        <div className="pdf-viewer mb-4">
                            <iframe title="Risk Analyse Report" src={formData.report} width="100%" height="600px" />
                            <button className="btn btn-primary" onClick={downloadFile}>Download PDF</button>
                        </div>
                    )}
                    {showEquidam && (
                        <div className="form-group mb-4 d-flex align-items-center">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleGenerateReport}
                            >
                                Go generate your report
                            </button>

                            <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip}
                            >
                                <button className="info-circle ms-2">?</button>
                            </OverlayTrigger>
                        </div>
                    )}
                    <div className="form-group mb-4">
                        <label>What type of investment would you prefer?</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="investmentType"
                                    checked={formData.investmentType === 'fundraising'}
                                    id="fundraising"
                                    value="fundraising"
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="fundraising">Fundraising (equity)</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="investmentType"
                                    checked={formData.investmentType === 'Merger'}
                                    id="Merger"
                                    value="Merger"
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="Merger">Merger</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="investmentType"
                                    checked={formData.investmentType === 'Acquisition'}
                                    id="Acquisition"
                                    value="Acquisition"
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="Acquisition">Acquisition</label>
                            </div>
                        </div>
                    </div>


                    <div className="form-group mb-4 form-check">
                        <input type="checkbox" className="form-check-input" id="agreeCommunication" name="agreeCommunication" checked={formData.agreeCommunication} onChange={handleChange} />
                        <label className="form-check-label" htmlFor="agreeCommunication">I agree to receive communications from fundii</label>
                    </div>

                    <div className="form-group mb-4 form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="agreePrivacyPolicy"
                            name="agreePrivacyPolicy"
                            checked={formData.agreePrivacyPolicy}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="agreePrivacyPolicy">
                            {"I have read and understand "}
                            <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">fundii's privacy policy</Link>{", and "}
                            <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">terms of use</Link>
                        </label>
                    </div>
                    {((!faceMatchResults || !faceMatched) && !userId) && (
                        <>
                            <h5 className="mb-4">Verifications</h5>
                            <div className="form-group mb-4 d-flex align-items-center">
                                <button onClick={handleVerification} className="btn orange-button">
                                    Start Verification
                                </button>

                                <OverlayTrigger
                                    placement="top"
                                    overlay={renderTooltip2}
                                >
                                    <button className="info-circle ms-2">?</button>
                                </OverlayTrigger>
                            </div>

                            <label className="mb-4" htmlFor="capture">Please upload image of your id and then click start camera to use your camera and take picture by clicking capture for face recognization</label>
                            <div className="form-group mb-4">
                                <label htmlFor="image">Please attached copy of your ID or passport</label>
                                <input
                                    type="file"
                                    className="form-control "
                                    id="image"
                                    name="image"
                                    onChange={handleImageChange}
                                />
                                <img
                                    id="imagePreview"
                                    src={""}
                                    alt="ID or passport Preview"
                                    className="image-preview"
                                />
                            </div>
                            {faceMatchResults && (
                                <div className="alert alert-info mb-5" role="alert">
                                    {faceMatchResults}
                                </div>
                            )}
                            <CameraCapture onCapture={handleImageCapture} />

                        </>)}
                </div>
            )}
            {stage === 4 && (
                <div className="bg-white p-4 rounded flex-fill">
                    <img src={processImage} alt={'Process'} className="img-fluid" />
                    <h2 className="mt-5 mb-4">Startup Form (Step 3)</h2>
                    <div className="mb-4">
                        <h3>Sign the NCND Agreement</h3>
                    </div>
                    <object
                        width="100%"
                        height="800vh"
                        data={url}
                        type="application/pdf"
                        aria-labelledby="please refresh the page"
                    ></object>

                    {/* Signature Pad Section */}
                    {!userId && (
                        <div className="mb-4">
                            <h4 className="signature-pad-title">Signature</h4>
                            <SignaturePad
                                ref={sigCanvas}
                                canvasProps={{
                                    className: 'signatureCanvas border',
                                    style: { width: '100%', height: '200px' }
                                }}
                            />
                        </div>
                    )}
                    {userId && (
                        <img src={signUrl} height="50vh" alt="sign" />
                    )}

                    {/* Signature Information Inputs */}
                    <div className="mb-4">
                        <div className="form-group">
                            <label htmlFor="signName">Your Name</label>
                            <input
                                type="text"
                                id="signName"
                                className="form-control"
                                value={signName}
                                onChange={(e) => setSignName(e.target.value)}
                                placeholder="Your Name"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="signTitle">Company/Title</label>
                            <input
                                type="text"
                                id="signTitle"
                                className="form-control"
                                value={signTitle}
                                onChange={(e) => setSignTitle(e.target.value)}
                                placeholder="Company/Title"
                            />
                        </div>
                    </div>

                    {userId && (
                        <div>
                            <label htmlFor="userEmail">User's Email: {formData.email}</label>
                        </div>
                    )}

                    {/* Save and Reset Buttons */}
                    {!userId && (
                        <div>
                            <button className="btn btn-primary mr-2" onClick={handleSaveSignature}>
                                Save Signature and Submit
                            </button>
                            <button className="btn btn-secondary" onClick={() => sigCanvas.current.clear()}>
                                Reset Signature
                            </button>
                        </div>
                    )}

                    {userId && (
                        <>
                            <button className="btn btn-primary mr-2" onClick={handleVerify}>
                                Verifiy
                            </button>
                            <button className="btn btn-primary mr-2" onClick={handleResign}>
                                Resign
                            </button>
                        </>
                    )}
                </div>
            )}
            {(isTechStartup && stage !== 4) && (<StepsComponent isAdmin={userId ? true : false} isStartup={true} handleSubmit={handleSubmit} handleDecline={handleDecline} handleAccept={handleAccept} formData={{ ...formData, faceMatched: faceMatched, stage }} steps={steps[stage ? stage / 2 : 0]} />)}
        </div>
    ) : <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Loading...
    </div>;
}

export default StartupFormPage;
