import React, { useRef, useState } from 'react';

function CameraCapture({ onCapture }) {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [stream, setStream] = useState(null);

    const startVideo = async () => {
        try {
            const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = videoStream;
            setStream(videoStream);
            videoRef.current.play();
        } catch (err) {
            console.error("Error accessing the camera: ", err);
        }
    };

    const captureImage = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(onCapture);
    };

    const stopVideo = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    return (
        <div>
            <div className="row justify-content-center">
                <button className="btn btn-primary m-2" onClick={startVideo}>Start Camera</button>
                <button className="btn btn-secondary m-2" onClick={captureImage}>Capture</button>
                <button className="btn btn-danger mb-5" onClick={stopVideo}>Stop Camera</button>
            </div>
            <div className="row justify-content-center">
                <video ref={videoRef} width="640" height="480" autoPlay className="col-md-8 mb-3"></video>
            </div>
            <div className="row justify-content-center">
                <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
            </div>
        </div>
    );
}

export default CameraCapture;
