import React from 'react';
import { Row } from 'react-bootstrap';
import ImageCard from './ImageCard';

const ImageCards = ({ images, texts, isAdmin, title, group, type, setTexts }) => (
  <>
    <h2 className="mb-4">{title}</h2>
    <Row>
      {images.map((image, index) => (
        <ImageCard
          key={index}
          index={index}
          texts={texts}
          isAdmin={isAdmin}
          image={image}
          group={type !== 'fundiitalk'? title : group}
          setTexts={setTexts}
          type={type}
        />
      ))}
    </Row>
  </>
);

export default ImageCards;
