import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Image, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import checkMarkImage from '../images/check.png';
import dueImage from '../images/due.png';
import fundImage from '../images/fund.png';
import reviewImage from '../images/review.png';
import signImage from '../images/sign.png';
import submitImage from '../images/submit.png';
import howInvestorImage from '../images/howInvestor.png';
import topImage from "../images/howInvTop.jpg"
import './FundiiInfoPage.css';

const calculateResponsiveSize = () => {
    const width = window.innerWidth;

    const size = Math.floor(400000/width);

    return size;
};

const HowInvestorWorksPage = ({ type, handleShowModal }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});

    const [responsiveStyles, setResponsiveStyles] = useState({
        pFontSize: '1rem',
        sectionMarginBottom: '1rem',
    });

    useEffect(() => {
        const handleResize = () => {
            // Call the function with appropriate min and max values
            const pFontSize = calculateResponsiveSize() + 'px'; // Convert pixels to rem if needed
            const sectionMarginBottom = calculateResponsiveSize() + 'px'; // Convert pixels to rem if needed

            setResponsiveStyles({
                pFontSize,
                sectionMarginBottom,
            });
        };

        // Set the initial sizes
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize, { passive: true });

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/6"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus.includes('admin'));
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/6`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    const TextSectionInvestor = ({ imageSrc, title, textKey, textValue }) => (
        <Row className="text-section align-items-start">
            <Col xs={2} className="text-center">
                <Image src={imageSrc} fluid />
            </Col>
            <Col xs={10}>
                <h3>{title}</h3>
                {isAdmin ? (
                    <Form.Control
                        as="textarea"
                        value={textValue}
                        onChange={(e) => handleTextChange(textKey, e.target.value)}
                        name={textKey}
                    />
                ) : (
                    <p>{textValue}</p>
                )}
            </Col>
        </Row>
    );

    const TextSection = ({ title, textKey, textValue }) => (
        <div className="text-section">
            <Row className="align-items-center">
                <Col xs={12} className="text-center">
                    <Image src={checkMarkImage} fluid />
                </Col>
            </Row>
            <Row className="align-items-center mt-3">
                <Col xs={12} className="text-center">
                    <h3>{title}</h3>
                </Col>
            </Row>
            <Row className="align-items-center mt-3">
                <Col xs={12}>
                    {isAdmin ? (
                        <Form.Control
                            as="textarea"
                            value={textValue}
                            onChange={(e) => handleTextChange(textKey, e.target.value)}
                            name={textKey}
                        />
                    ) : (
                        <p>{textValue}</p>
                    )}
                </Col>
            </Row>
        </div>
    );

    const navigate = useNavigate();

    const handleBrowseClick = () => {
        if (!type) {
            handleShowModal('investor');
        } else if (type === 'investor') {
            navigate('/form/investor');
        } else if (type === 'startup') {
            alert("You are already registered as a startup.");
        }
    };

    return (
        <>
            {isAdmin && (
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}
                >
                    Save Changes
                </Button>
            )}
            <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
                <Carousel.Item>
                    <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topDescription}
                                onChange={(e) => handleTextChange('topDescription', e.target.value)}
                                name={'topDescription'}
                            />
                        ) : (
                            <h1>{texts.topDescription}</h1>
                        )}
                        <Button variant="primary" className="orange-button" onClick={handleBrowseClick}>Browse Companies</Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container style={{ marginTop: '10%', marginBottom: responsiveStyles.sectionMarginBottom }}>
                <h1 className="mb-5 text-center"><span className="orange-text">How It</span> Works</h1>
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <TextSectionInvestor
                            imageSrc={reviewImage}
                            title="Review Startups"
                            textKey="reviewStartupsText"
                            textValue={texts.reviewStartupsText}
                        />
                        <TextSectionInvestor
                            imageSrc={submitImage}
                            title="Submit your investment interest"
                            textKey="submitInterestText"
                            textValue={texts.submitInterestText}
                        />
                    </Col>
                    <Col md={6}>
                        <TextSectionInvestor
                            imageSrc={dueImage}
                            title="Due Diligence"
                            textKey="dueDiligenceText"
                            textValue={texts.dueDiligenceText}
                        />
                        <TextSectionInvestor
                            imageSrc={signImage}
                            title="Sign Term Sheet/ Legal Documents"
                            textKey="signTermSheetText"
                            textValue={texts.signTermSheetText}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: responsiveStyles.sectionMarginBottom }} className="justify-content-md-center">
                <Col md={6} className="d-flex flex-column justify-content-center">
                        <TextSectionInvestor
                            imageSrc={fundImage}
                            title="Finalize the deal and transfer the fund to the startup"
                            textKey="finalizeDealText"
                            textValue={texts.finalizeDealText}
                        />
                    </Col>
                </Row>
                <h1 style={{ marginTop: '20%' }} className="mb-5 text-center">fund<span className="orange-text">ii</span> Makes it <span className="orange-text">Easier</span></h1>
                <Row className="justify-content-md-center">
                    <Col md={3}>
                        <TextSection
                            title="Dedicated Support Team for Investors"
                            textKey="dedicatedSupportText"
                            textValue={texts.dedicatedSupportText}
                        />
                        <TextSection
                            title="Zero Investment Cost"
                            textKey="zeroInvestmentCostText"
                            textValue={texts.zeroInvestmentCostText}
                        />
                    </Col>
                    <Col md={3}>
                        <TextSection
                            title="Investment Analysis & Reports"
                            textKey="investmentAnalysisText"
                            textValue={texts.investmentAnalysisText}
                        />
                        <TextSection
                            title="New Investment Landscape"
                            textKey="newInvestmentLandscapeText"
                            textValue={texts.newInvestmentLandscapeText}
                        />
                    </Col>
                    <Col md={6}>
                        <img style={{ height: '65vh', width: '85vw' }} src={howInvestorImage} alt="how" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default HowInvestorWorksPage;
