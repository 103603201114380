import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import './FundiiInfoPage.css';
import topImage from '../images/3.jpg';
import captialImage from '../images/captial.png';
import { useNavigate } from 'react-router-dom';

const RaiseCaptialPage = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [texts, setTexts] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchTextData() {
            try {
                const restOperation = get({
                    apiName: 'fundiiAPI',
                    path: "/services/texts/8"
                });
                const { body } = await restOperation.response;
                const response = await body.json();
                if (response.message) {
                    
                    setTexts(response.message);
                } else {
                    setTexts([]);
                }
            } catch (err) {
                console.log(`Error fetching texts: `, err);
            }
        }

        fetchTextData();
    }, []);

    useEffect(() => {
        async function checkAdminStatus() {
            try {
                const auth_user = await fetchAuthSession();
                const adminStatus = auth_user?.tokens?.accessToken?.payload['cognito:groups'];
                setIsAdmin(adminStatus);
            } catch (error) {
                console.error('Error fetching auth session:', error);
            }
        }

        checkAdminStatus();
    }, []);

    // Handle submission of changes
    const handleSubmit = async () => {

        try {
            const myInit = {
                body: {
                    ...texts,
                    id: undefined,
                },
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${session.idToken.jwtToken}`
                }
            }
            
            const restOperation = post({
                apiName: "fundiiAPI",
                path: `/services/texts/8`,
                options: myInit
            });
            const { body } = await restOperation.response;
            const response = await body.json();
            
        } catch (err) {
            console.log(err)
            return false;
        }
    };

    // This is a handler function to save changes to the state
    const handleTextChange = (key, value) => {
        setTexts({ ...texts, [key]: value });
    };

    const handleBrowseClick = () => {
        navigate('/investment-opportunities');
    };

    // Render the component
    return (
        <>
            {isAdmin &&
                <Button
                    style={{
                        position: 'fixed',
                        top: '0',
                        marginTop: '7%',
                        width: '100%',
                        zIndex: 1
                    }}
                    variant="primary"
                    onClick={handleSubmit}>
                    Change
                </Button>
            }
            <Carousel indicators={false} controls={false} style={{ marginTop: '6%' }}>
                <Carousel.Item>
                    <img className="d-block w-100 carousel-image" src={topImage} alt={``} />
                    <Carousel.Caption style={{ position: 'absolute', bottom: '35%', left: '35%', transform: 'translateX(-50%)', width: '50%', textAlign: 'left' }}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topTitle}
                                onChange={(e) => handleTextChange('topTitle', e.target.value)}
                                name={'topTitle'}
                            />
                        ) : (
                            <h1>{texts.topTitle}</h1>
                        )}
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.topDescription}
                                onChange={(e) => handleTextChange('topDescription', e.target.value)}
                                name={'topDescription'}
                            />
                        ) : (
                            <h3>{texts.topDescription}</h3>
                        )}
                        <Button className="orange-button" onClick={handleBrowseClick}>Browse Companies</Button>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container style={{ marginTop: '10%' }}>
                <h1 className="mb-5 text-center">Our <span className="orange-text">Story</span></h1>
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.storyText || ''}
                                onChange={(e) => handleTextChange('storyText', e.target.value)}
                            />
                        ) : (
                            <p className="text-center">{texts.storyText}</p>
                        )}
                    </Col>
                </Row>
                <Row className="justify-content-md-center mb-5">
                    <Col md={12}>
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.experienceText || ''}
                                onChange={(e) => handleTextChange('experienceText', e.target.value)}
                            />
                        ) : (
                            <p className="text-center">{texts.experienceText}</p>
                        )}
                    </Col>
                </Row>
                <Row style={{ marginTop: '15%', marginBottom: '10%' }} className="justify-content-md-center">
                    <Col md={8}>
                        <h1 className="mb-5 text-center">The new<span className="orange-text"> capital</span> market</h1>

                        {/* Capital Market Vision Text */}
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.capitalMarketVisionText || ''}
                                onChange={(e) => handleTextChange('capitalMarketVisionText', e.target.value)}
                            />
                        ) : (
                            <p className="text-center">{texts.capitalMarketVisionText}</p>
                        )}

                        {/* Investment Opportunities Text */}
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.investmentOpportunitiesText || ''}
                                onChange={(e) => handleTextChange('investmentOpportunitiesText', e.target.value)}
                            />
                        ) : (
                            <p className="text-center">{texts.investmentOpportunitiesText}</p>
                        )}

                        {/* Mission Statement Text */}
                        {isAdmin ? (
                            <Form.Control
                                as="textarea"
                                value={texts.missionStatementText || ''}
                                onChange={(e) => handleTextChange('missionStatementText', e.target.value)}
                            />
                        ) : (
                            <p className="text-center">{texts.missionStatementText}</p>
                        )}
                    </Col>
                    <Col md={4}>
                        <img src={captialImage} alt="Capital Market" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default RaiseCaptialPage;
