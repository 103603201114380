import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ContactFormModal({ show, onHide, email }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        reason: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Construct the email body
        const emailBody = `Name: ${formData.name}\nEmail: ${formData.email}\nReason: ${formData.reason}\nMessage: ${formData.message}`;

        // Create mailto link
        const mailtoLink = `mailto:${email}?subject=Contact Form Submission&body=${encodeURIComponent(emailBody)}`;

        // Open the default mail client
        window.location.href = mailtoLink;

        onHide(); // Close modal
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>First and Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Reason to Contact</Form.Label>
                        <Form.Select name="reason" value={formData.reason} onChange={handleChange}>
                            <option>General inquiries</option>
                            <option>Startup inquiries</option>
                            <option>Investment inquiries</option>
                            <option>Become a partner</option>
                            <option>Sponsorship</option>
                            <option>Technical Support</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Type your message</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button className='orange-button' type="submit">
                        Send
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ContactFormModal;
